import { useReactiveVar } from '@apollo/client'
import { useCallback, useMemo, useState } from 'react'

import { GetWorkerQuery } from '@/types/graphql'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import Autocomplete from '@/components/Autocomplete'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormElement from '@/components/FormElement'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import TagList from '@/components/TagList'

import {
  useAddWorkerSkillMutation,
  useListAgencySkillsQuery,
  useRemoveWorkerSkillMutation,
} from '@/graphql'

import { currentAgencyVar } from '@/util/apollo/cache'
import { sortBy } from '@/util/array'

type Item = GetWorkerQuery['worker']['skills'][0]

type Props = {
  hideModal: () => void
  worker: GetWorkerQuery['worker']
}

const ChangeSkillsModal = ({ hideModal, worker }: Props) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query)

  const currentAgency = useReactiveVar(currentAgencyVar)
  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  })

  const [addSkill] = useAddWorkerSkillMutation({})
  const [removeSkill] = useRemoveWorkerSkillMutation({})

  const items: Item[] = useMemo(() => {
    if (data) {
      const skills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (
            worker.skills.some((workerSkill) => workerSkill.id === skill.id)
          ) {
            return false
          }

          return skill.name.toLowerCase().includes(debouncedQuery.toLowerCase())
        })

      return sortBy(skills, 'name').slice(0, 4)
    }

    return []
  }, [data, debouncedQuery, worker.skills])

  const sortedWorkerSkills = useMemo(() => sortBy(worker.skills, 'name'), [
    worker.skills,
  ])

  const handleRemove = useCallback(
    (index: number) => {
      removeSkill({
        variables: {
          skillId: sortedWorkerSkills[index].id,
          workerId: worker.id,
        },
      })
    },
    [sortedWorkerSkills]
  )

  const handleSelect = useCallback(
    (item) => {
      addSkill({
        variables: { skillId: item.selectedItem.id, workerId: worker.id },
      })
    },
    [worker.id]
  )

  return (
    <Modal size="sm" title="Change worker's skills" onRequestClose={hideModal}>
      <Card.Section>
        <FormElement>
          <TagList
            tags={sortedWorkerSkills.map((skill) => skill.name)}
            onRemove={handleRemove}
          />
        </FormElement>
        <FormElement>
          <Autocomplete
            autoFocus
            id="tag"
            itemToKey={(item) => item.id}
            items={items}
            itemToString={(item) => (item ? item.name : '')}
            placeholder="Search for skills..."
            selectedItem={null}
            onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
            onSelectedItemChange={handleSelect}
            fixedSelectHeight="lg"
          />
        </FormElement>
        <Stack justify="end">
          <Button a11yLabel="Close modal" label="Finish" onClick={hideModal} />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default ChangeSkillsModal
