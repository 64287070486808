import { SubmitHelpers } from '@area2k/use-form'
import { useCallback } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'

import Form from '@/form'

import AccountSelectField from './AccountSelectField'
import CustomerAutocompleteField from './CustomerAutocompleteField'

import { OrderBilling, useOrderActions } from './context'

type FormValues = {
  account: OrderBilling['account'] | null
  customer: OrderBilling['customer'] | null
}

const initialValues: FormValues = { account: null, customer: null }

const Billing = () => {
  const { setBilling } = useOrderActions()

  const handleSubmit = useCallback(async (values: FormValues, { setFormError }: SubmitHelpers) => {
    if (values.account && values.customer) {
      setBilling({ account: values.account, customer: values.customer })
    } else {
      setFormError('incomplete', {
        title: 'Please complete all fields',
        message: 'All fields are required to continue.'
      })
    }
  }, [])

  return (
    <Page title='New order'>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Card sectioned title='Customer selection'>
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <CustomerAutocompleteField fieldId='customer' />
              <AccountSelectField
                customerFieldId='customer'
                fieldId='account'
                label='Billing account'
              />
              <Stack justify='end'>
                <Button
                  a11yLabel='Submit'
                  label='Start order'
                  type='submit'
                />
              </Stack>
            </Form>
          </Card>
        </PrimarySecondaryLayout.Primary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Billing
