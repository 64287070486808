import qs from 'query-string'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useGetCurrentTenantAdminQuery } from '@/graphql'

import LoadingState from '@/components/LoadingState'

import { currentTenantAdminVar } from '@/util/apollo/cache'
import ls, { DEVICE_TOKEN_KEY } from '@/util/localstorage'

const LoginRedirect = ({ didFailAuth }: { didFailAuth: boolean}) => {
  const location = useLocation()

  ls.clearAuth()

  return (
    <Navigate
      replace
      state={{ didFailAuth, didReroute: true }}
      to={{
        pathname: '/login',
        search: `?${qs.stringify({ to: location.pathname })}`
      }}
    />
  )
}

const Authenticated = () => {
  const deviceToken = ls.get(DEVICE_TOKEN_KEY)
  const query = useGetCurrentTenantAdminQuery({ skip: !deviceToken })

  useEffect(() => {
    if (query.data) {
      currentTenantAdminVar(query.data.currentTenantAdmin)
    }

    return () => { currentTenantAdminVar(null) }
  }, [query.data])

  if (!deviceToken || query.error) {
    return <LoginRedirect didFailAuth={!!query.error} />
  }

  if (query.loading) {
    return <LoadingState text='Loading your account information...' />
  }

  return <Outlet />
}

export default Authenticated
