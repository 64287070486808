import { OrderFilterSetInput } from '@/types/graphql'

import { AllFiltersProps } from '@/filters'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import NumericCompareFilter from '@/filters/NumericCompareFilter'

import OrderStatusFilter from './OrderStatusFilter'

type Props = AllFiltersProps<OrderFilterSetInput> & {
  hideModal: () => void
}

const OrderFiltersModal = ({
  filters,
  hideModal,
  onChangeFilter,
  onClearAll,
  onClearFilter,
}: Props) => {
  console.log('modal filters', filters)

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change order filters"
      onRequestClose={hideModal}
    >
      <Card.Section title="Order status">
        <OrderStatusFilter
          filters={filters}
          idPrefix="modal"
          onChangeFilter={onChangeFilter}
          onClearFilter={onClearFilter}
        />
      </Card.Section>
      <Card.Section title="Jobs count">
        <NumericCompareFilter
          filterKey="jobsCount"
          filters={filters}
          onChangeFilter={onChangeFilter}
          onClearFilter={onClearFilter}
        />
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Clear all order filters"
            appearance="outline"
            label="Clear all"
            onClick={() => {
              onClearAll()
              hideModal()
            }}
          />
          <Button a11yLabel="Close modal" label="Done" onClick={hideModal} />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default OrderFiltersModal
