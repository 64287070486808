import { useCallback } from 'react'

import Card from '@/components/Card'
import Page from '@/components/Page'
import QueryEmptyState from '@/components/QueryEmptyState'
import ResourceList from '@/components/ResourceList'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import { useListAgenciesQuery } from '@/graphql'

type Item = NonNullable<
  ReturnType<typeof useListAgenciesQuery>['data']
>['agencies'][0]

const Agencies = () => {
  const query = useListAgenciesQuery()

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item key={item.id} to={`/a/${item.key}`}>
        <TextStack>
          <Body weight="medium">{item.name}</Body>
          <Small>Washington, DC</Small>
        </TextStack>
      </ResourceList.Item>
    ),
    []
  )

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a new agency',
        label: 'New agency',
        onAction: () => {},
      }}
      size="md"
      title="Agencies"
    >
      <Card>
        <ResourceList
          emptyState={
            <QueryEmptyState
              query={query}
              title="No agencies"
              text="No agencies were found, create on above."
            />
          }
          isLoading={query.loading}
          items={query.data ? query.data.agencies : []}
          renderItem={renderItem}
          resourceName={{ singular: 'agency', plural: 'agencies' }}
        />
      </Card>
    </Page>
  )
}

export default Agencies
