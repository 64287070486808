import { useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'

import { ListWorkersQuery, WorkerFilterSetInput } from '@/types/graphql'

import useFilters from '@/hooks/useFilters'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Page from '@/components/Page'
import Paginator from '@/components/Paginator/Paginator'
import QueryEmptyState from '@/components/QueryEmptyState'
import ResourceList from '@/components/ResourceList'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import { useListWorkersQuery } from '@/graphql'

import { currentAgencyVar } from '@/util/apollo/cache'
import WorkerFilters from './WorkerFilters'

type Item = ListWorkersQuery['agency']['workers']['items'][0]

const showCreateModal = () => {}

const initialFilters: WorkerFilterSetInput = {}

const Workers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<WorkerFilterSetInput>(initialFilters)

  const finalFilters = useMemo<WorkerFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const currentAgency = useReactiveVar(currentAgencyVar)
  const query = useListWorkersQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    console.log(`changed to ${currentPage}`)
  }

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item
        key={item.id}
        media={<Avatar size="sm" src={item.avatarUrl} />}
        to={item.id}
      >
        <Stack justify="apart">
          <TextStack>
            <Body weight="medium">
              {item.user.firstName} {item.user.lastName}
            </Body>
            <Small>{item.user.email}</Small>
          </TextStack>
          <div style={{ whiteSpace: 'nowrap' }}>
            <Body>
              {item.city}, {item.state}
            </Body>
          </div>
        </Stack>
      </ResourceList.Item>
    ),
    []
  )

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a worker',
        label: 'New worker',
        onAction: showCreateModal,
      }}
      size="md"
      title="Workers"
    >
      <Card>
        <Card.Section>
          <WorkerFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <ResourceList
          emptyState={
            <QueryEmptyState
              query={query}
              title="No workers"
              text="No workers were found, create on above."
            />
          }
          isLoading={query.loading}
          items={query.data ? query.data.agency.workers.items : []}
          renderItem={renderItem}
          resourceName={{ singular: 'worker', plural: 'workers' }}
        />
      </Card>
      {query.data && query.data.agency.workers.items.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={query.data.agency.workers.pageInfo.totalItems}
        />
      )}
    </Page>
  )
}

export default Workers
