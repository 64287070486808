import { faTimes } from '@fortawesome/free-solid-svg-icons'

import styled from '@/styles'

import IconicButton from '@/components/IconicButton'
import Stack from '@/components/Stack'

import Progress, { Props as ProgressProps } from './Progress'

const Wrapper = styled('nav', {
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  gridTemplateRows: '64px',
  padding: '0 32px',

  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,

  backgroundColor: 'white',
  boxShadow: '$2',

  zIndex: 1000
})

type Props = ProgressProps & {
  onClose: () => void
}

const TopBar = ({ completedStep, currentStep, setStep, onClose }: Props) => {
  return (
    <Wrapper>
      <Stack>
        Adding a new job
      </Stack>
      <Progress
        completedStep={completedStep}
        currentStep={currentStep}
        setStep={setStep}
      />
      <Stack justify='end'>
        <IconicButton
          a11yLabel='Close job editor'
          appearance='clear'
          icon={faTimes}
          onClick={onClose}
        />
      </Stack>
    </Wrapper>
  )
}

export default TopBar
