import { useField } from '@area2k/use-form'
import { ComponentPropsWithoutRef } from 'react'

import FormElement from '@/components/FormElement'
import TextArea from '@/components/TextArea'

type InputProps = Omit<ComponentPropsWithoutRef<typeof TextArea>, 'id' | 'value' | 'onChange'>

type Props = InputProps & {
  fieldId: string
  label?: string
}

const TextAreaField = ({ fieldId, label, ...props }: Props) => {
  const { value, setValue } = useField<string>(fieldId)

  return (
    <FormElement htmlFor={fieldId} label={label}>
      <TextArea
        {...props}
        id={fieldId}
        name={fieldId}
        value={value}
        onChange={(ev) => setValue(ev.currentTarget.value)}
      />
    </FormElement>
  )
}

export default TextAreaField
