import { PropsWithChildren } from 'react'

import styled from '@/styles'

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateAreas: '"left right"',
  gridTemplateRows: 'auto',
  gridTemplateColumns: '4fr 3fr',
  minHeight: '100%',
  overflowY: 'auto'
})

const Left = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  gridArea: 'left',
  padding: '112px 32px',

  backgroundColor: 'white',
  boxShadow: '$1',

  zIndex: 10
})

const Right = styled('div', {
  gridArea: 'right',
  padding: '112px 32px',

  backgroundColor: '$neutralGhost'
})

const SplitLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

SplitLayout.Wrapper = Wrapper
Wrapper.displayName = 'stitches(SplitLayout.Wrapper)'

SplitLayout.Left = Left
Left.displayName = 'stitches(SplitLayout.Left)'

SplitLayout.Right = Right
Right.displayName = 'stitches(SplitLayout.Right)'

export default SplitLayout
