import { useParams } from 'react-router'
import { format } from 'date-fns'

import { useGetOrderQuery } from '@/graphql'

import Link from '@/elements/Link'
import Table, { TableRow, TableCell } from '@/elements/Table'

import Card from '@/components/Card'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import QueryEmptyState from '@/components/QueryEmptyState'

import CustomerCard from './CustomerCard'
import PageSkeleton from './PageSkeleton'
import Tags from './Tags'
import { formatISO } from '@/util/date'
import Avatar from '@/components/Avatar'
import TableHeader from '@/components/TableHeader'

const Order = () => {
  const { orderId } = useParams()

  const query = useGetOrderQuery({
    variables: { orderId },
  })

  if (!query.data) {
    return <PageSkeleton />
  }

  const headerFields = ['Skill', 'Timeframe', 'Workers']

  const TableBody = ({ jobs }) => (
    <tbody>
      {jobs.map((item) => (
        <TableRow key={item.id}>
          <TableCell>
            <Stack gap={12}>
              <Avatar size="xs" src={item.skill.imageUrl} />
              <Link to={`jobs/${item.id}`}>{item.skill.name}</Link>
            </Stack>
          </TableCell>
          <TableCell>
            {format(new Date(item.firstShiftStartAt), 'PP')} &mdash;{' '}
            {format(new Date(item.lastShiftEndAt), 'PP')}
          </TableCell>
          <TableCell>
            {`${item.hiredWorkersCount} / ${item.quantity}`} hired
          </TableCell>
        </TableRow>
      ))}
    </tbody>
  )

  const order = query.data.order
  return (
    <Page
      title={`Order #${10000 + Number(order.id)}`}
      subtitle={`${order.jobsCount} ${
        order.jobsCount !== 1 ? 'jobs' : 'job'
      } from ${formatISO(order.startAt, 'PP')} — ${formatISO(
        order.endAt,
        'PP'
      )}`}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Card title="Jobs">
            {order.jobs.length ? (
              <Table>
                <TableHeader fields={headerFields} />
                <TableBody jobs={order.jobs} />
              </Table>
            ) : (
              <QueryEmptyState
                query={query}
                title="No jobs available"
                text="If there were any, they would be here."
              />
            )}
          </Card>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <CustomerCard order={order} />
            <Tags order={order} />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Order
