import useModal from '@area2k/use-modal'

import { GetWorkerQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body } from '@/components/Typography'

import AddressSection from './AddressSection'
import SkillsSection from './SkillsSection'
import UpdateProfileModal from './UpdateProfileModal'

type Props = {
  worker: GetWorkerQuery['worker']
}

const ProfileCard = ({ worker }: Props) => {
  const [showUpdateModal, hideUpdateModal] = useModal(
    () => <UpdateProfileModal worker={worker} hideModal={hideUpdateModal} />,
    [worker]
  )

  return (
    <Card
      actions={[
        {
          a11yLabel: "Edit this worker's profile",
          label: 'Edit',
          onAction: showUpdateModal,
        },
      ]}
      title="Profile"
    >
      <Card.Section>
        <Stack gap={20}>
          <div>
            <Avatar
              a11yLabel={`Image of ${worker.user.firstName} ${worker.user.lastName}`}
              src={worker.avatarUrl}
              size="lg"
            />
          </div>
          <TextStack>
            <Body>
              {worker.user.firstName} {worker.user.lastName}
            </Body>
            <Body color="light">{worker.user.email}</Body>
            <Body color="light">{worker.user.phoneNumber}</Body>
          </TextStack>
        </Stack>
      </Card.Section>
      <SkillsSection worker={worker} />
      <AddressSection worker={worker} />
    </Card>
  )
}

export default ProfileCard
