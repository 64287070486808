import { useCallback } from 'react'

import { GetCustomerQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'
import useModal from '@area2k/use-modal'
import InviteAdminModal from './InviteAdminModal'

type Item = GetCustomerQuery['customer']['admins'][0]

type Props = {
  customer: GetCustomerQuery['customer']
}

const AdminList = ({ customer }: Props) => {
  const [showInviteModal, hideInviteModal] = useModal(
    () => <InviteAdminModal customer={customer} hideModal={hideInviteModal} />,
    [customer]
  )

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item
        key={item.id}
        media={<Avatar size="sm" src={item.avatarUrl} />}
        to={`admin/${item.id}`}
      >
        <TextStack>
          <Body>
            {item.user.firstName} {item.user.lastName}
          </Body>
          <Small>{item.user.email}</Small>
        </TextStack>
      </ResourceList.Item>
    ),
    []
  )

  return (
    <Card
      actions={[
        {
          a11yLabel: 'Invite a new administrator',
          label: 'Invite',
          onAction: showInviteModal,
        },
      ]}
      title="Administrators"
    >
      <ResourceList
        emptyState={
          <EmptyState
            title="No customer admins"
            text="If there were any, they would be here"
          />
        }
        items={customer.admins}
        renderItem={renderItem}
      />
    </Card>
  )
}

export default AdminList
