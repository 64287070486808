import { GetOrderQuery } from '@/types/graphql'

import Link from '@/elements/Link'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

type Props = {
  order: GetOrderQuery['order']
}

const CustomerCard = ({ order: { account } }: Props) => {
  return (
    <Card sectioned title="Customer">
      <Stack gap={16}>
        <div>
          <Avatar size="sm" src={account.customer.logoUrl} />
        </div>
        <TextStack>
          <Link to={`../../customers/${account.customer.id}`}>
            <Body color="inherit" weight="medium">
              {account.customer.name}
            </Body>
          </Link>
          <Link
            appearance="subtle"
            to={`../../customers/${account.customer.id}/accounts/${account.id}`}
          >
            <Small>{account.name}</Small>
          </Link>
        </TextStack>
      </Stack>
    </Card>
  )
}

export default CustomerCard
