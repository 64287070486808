import { GetCustomerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

type Props = {
  customer: GetCustomerQuery['customer']
}

const PaymentSection = ({ customer }: Props) => {
  return (
    <Card.Section
      actions={[{
        a11yLabel: "Change this customer's payment settings",
        label: 'Change',
        onAction: () => undefined
      }]}
      title='Payment'
    >
    </Card.Section>
  )
}

export default PaymentSection
