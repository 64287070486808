import { useField } from '@area2k/use-form'

import FormElement from '@/components/FormElement'
import ItemSelect, { Props as ItemSelectProps } from '@/components/ItemSelect'

type InputProps<T> = Omit<ItemSelectProps<T>, 'id' | 'selectedItem' | 'onSelectedItemChange'>

type Props<T> = InputProps<T> & {
  fieldId: string
  label?: string
}

const ItemSelectField = <T extends object>({ fieldId, label, ...props }: Props<T>) => {
  const { value, setValue } = useField<T | null>(fieldId)

  return (
    <FormElement htmlFor={`${fieldId}-toggle-button`} label={label}>
      <ItemSelect
        {...props}
        id={fieldId}
        selectedItem={value}
        onSelectedItemChange={({ selectedItem }) => setValue(selectedItem || null)}
      />
    </FormElement>
  )
}

export default ItemSelectField
