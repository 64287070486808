import { useCallback, useMemo, useReducer, useState } from 'react'

import useDisableBodyScroll from '@/hooks/useDisableBodyScroll'

import Overlay from '@/elements/Overlay'

import Steps from './Steps'
import TopBar from './TopBar'

import { initialState, JobDraftActions, JobDraftActionsContext, JobDraftActionType, JobDraftStateContext, stateReducer, Step } from './context'

type Props = {
  onClose: () => void
}

const JobEditor = ({ onClose }: Props) => {
  useDisableBodyScroll()

  const [state, dispatch] = useReducer(stateReducer, initialState)

  const actions = useMemo<JobDraftActions>(() => ({
    resetState: () => dispatch({ type: JobDraftActionType.RESET_STATE }),
    updateDetails: (changes) => dispatch({ type: JobDraftActionType.UPDATE_DETAILS, changes }),
    updatePublishing: (changes) => dispatch({ type: JobDraftActionType.UPDATE_PUBLISHING, changes }),
    updateSchedules: (changes) => dispatch({ type: JobDraftActionType.UPDATE_SCHEDULES, changes }),
    updateSkill: (changes) => dispatch({ type: JobDraftActionType.UPDATE_SKILL, changes }),
  }), [])

  const [currentStep, setCurrentStep] = useState(Step.SKILL)

  const handleClose = useCallback(() => {
    if (state.completedStep) {
      if (window.confirm('Are you sure you want to exit?')) {
        onClose()
        actions.resetState()
      }
    } else {
      onClose()
      actions.resetState()
    }
  }, [state.completedStep])

  return (
    <JobDraftActionsContext.Provider value={actions}>
      <JobDraftStateContext.Provider value={state}>
        <Overlay css={{ $$bgColor: 'white' }}>
          <TopBar
            completedStep={state.completedStep}
            currentStep={currentStep}
            setStep={setCurrentStep}
            onClose={handleClose}
          />
          <Steps
            step={currentStep}
            setStep={setCurrentStep}
            onClose={handleClose}
          />
        </Overlay>
      </JobDraftStateContext.Provider>
    </JobDraftActionsContext.Provider>
  )
}

export default JobEditor
