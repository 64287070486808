import useModal from '@area2k/use-modal'

import { GetCustomerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import { Body } from '@/components/Typography'

import PaymentSection from './PaymentSection'
import RenameCustomerModal from './RenameCustomerModal'
import SchedulingSection from './SchedulingSection'

type Props = {
  customer: GetCustomerQuery['customer']
}

const SettingsCard = ({ customer }: Props) => {
  const [showRenameModal, hideRenameModal] = useModal(() => (
    <RenameCustomerModal customer={customer} hideModal={hideRenameModal} />
  ), [customer])

  return (
    <Card
      actions={[{
        a11yLabel: 'Rename this customer',
        label: 'Rename',
        onAction: showRenameModal
      }]}
      title='Settings'
    >
      <Card.Section>
        <Body>
          {customer.name}
        </Body>
      </Card.Section>
      <PaymentSection customer={customer} />
      <SchedulingSection customer={customer} />
    </Card>
  )
}

export default SettingsCard
