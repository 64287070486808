import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import { render } from 'react-dom'

import App from './App'

import comet from '@/util/comet'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import '@/styles'

comet.init()

const root = document.querySelector('#root')
if (!root) throw new Error('no root element!')

render(<App />, root)
