import { useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'

import { CandidateFilterSetInput, ListCandidatesQuery } from '@/types/graphql'

import useFilters from '@/hooks/useFilters'

import Avatar from '@/components/Avatar'
import Badge from '@/components/Badge'
import Card from '@/components/Card'
import Page from '@/components/Page'
import Paginator from '@/components/Paginator/Paginator'
import QueryEmptyState from '@/components/QueryEmptyState'
import ResourceList from '@/components/ResourceList'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import CandidateFilters from './CandidateFilters'

import { useListCandidatesQuery } from '@/graphql'

import { currentAgencyVar } from '@/util/apollo/cache'

type Item = ListCandidatesQuery['agency']['candidates']['items'][0]

const showCreateModal = () => {}

const initialFilters: CandidateFilterSetInput = {}

const Candidates = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<CandidateFilterSetInput>(initialFilters)

  const finalFilters = useMemo<CandidateFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const currentAgency = useReactiveVar(currentAgencyVar)
  const query = useListCandidatesQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    console.log(`changed to ${currentPage}`)
  }

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item
        key={item.id}
        media={<Avatar size="sm" src={item.avatarUrl} />}
        to={item.id}
      >
        <Stack justify="apart">
          <TextStack inline>
            <Body weight="medium">
              {item.user.firstName} {item.user.lastName}
            </Body>
            <Small>{item.user.email}</Small>
          </TextStack>
          <Body>{item.user.phoneNumber}</Body>
          <Badge label={item.candidateStatus} />
        </Stack>
      </ResourceList.Item>
    ),
    []
  )

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a candidate',
        label: 'New candidate',
        onAction: showCreateModal,
      }}
      size="md"
      title="Candidates"
    >
      <Card>
        <Card.Section>
          <CandidateFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <ResourceList
          emptyState={
            <QueryEmptyState
              query={query}
              title="No candidates"
              text="No candidates were found, create on above."
            />
          }
          isLoading={query.loading}
          items={query.data ? query.data.agency.candidates.items : []}
          renderItem={renderItem}
          resourceName={{ singular: 'candidate', plural: 'candidates' }}
        />
      </Card>
      {query.data && query.data.agency.candidates.items.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={query.data.agency.candidates.pageInfo.totalItems}
        />
      )}
    </Page>
  )
}

export default Candidates
