import useModal from '@area2k/use-modal'
import { useCallback } from 'react'

import { GetJobQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import NewOfferModal from './NewOfferModal'

import { JobStatus } from '../util'
import Badge from '@/components/Badge'

type JobOfferItem = GetJobQuery['job']['jobOffers'][0]

type Props = {
  job: GetJobQuery['job']
  jobStatus: JobStatus
}

const JobOffersList = ({ job, jobStatus }: Props) => {
  const [showOfferModal, hideOfferModal] = useModal(
    () => <NewOfferModal jobId={job.id} hideModal={hideOfferModal} />,
    [job.id]
  )

  const isJobActive =
    jobStatus === JobStatus.UPCOMING || jobStatus === JobStatus.IN_PROGRESS

  const renderItem = useCallback(
    ({ acceptedAt, id, rejectedAt, worker }: JobOfferItem) => (
      <ResourceList.Item
        key={id}
        media={<Avatar size="sm" src={worker.avatarUrl} />}
        to={`../../../../workers/${worker.id}`}
      >
        <Stack>
          <TextStack spacing="tight">
            <Body weight="medium">
              {worker.user.firstName} {worker.user.lastName}
            </Body>
            <Small>{worker.user.email}</Small>
          </TextStack>
          {acceptedAt ? (
            <Badge label="Accepted" status="theme" />
          ) : rejectedAt ? (
            <Badge label="Rejected" status="danger" />
          ) : (
            <Badge label="Pending" />
          )}
        </Stack>
      </ResourceList.Item>
    ),
    [isJobActive]
  )

  if (!isJobActive && job.jobOffers.length === 0) {
    // NOTE: hide this card if the job is complete and there were no offers
    return null
  }

  return (
    <Card
      actions={
        isJobActive
          ? [
              {
                a11yLabel: 'Offer this job to a worker',
                label: 'New offer',
                onAction: showOfferModal,
              },
            ]
          : undefined
      }
      title="Job offers"
    >
      <ResourceList
        emptyState={
          <EmptyState
            title="No job offers"
            text="If there were any, they would be here"
          />
        }
        items={job.jobOffers}
        renderItem={renderItem}
      />
    </Card>
  )
}
export default JobOffersList
