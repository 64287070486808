import { useCallback } from 'react'

import { GetJobQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import { JobStatus } from '../util'

type TimesheetItem = GetJobQuery['job']['timesheets'][0]

type Props = {
  job: GetJobQuery['job']
  jobStatus: JobStatus
}

const TimesheetList = ({ job, jobStatus }: Props) => {
  const isJobActive =
    jobStatus === JobStatus.UPCOMING || jobStatus === JobStatus.IN_PROGRESS

  const renderItem = useCallback(
    ({ id, jobWorker: { worker } }: TimesheetItem) => (
      <ResourceList.Item key={id} media={<Avatar size="sm" />} to=".">
        <TextStack>
          <Body weight="medium">
            {worker.user.firstName} {worker.user.lastName}
          </Body>
          <Small>{worker.user.email}</Small>
        </TextStack>
      </ResourceList.Item>
    ),
    [isJobActive]
  )

  return (
    <Card title="Timesheets">
      <ResourceList
        emptyState={
          <EmptyState
            title="No timesheets"
            text="Timesheets will appear when submitted by workers."
          />
        }
        items={job.timesheets}
        renderItem={renderItem}
        totalItemCount={job.timesheets.length}
      />
    </Card>
  )
}
export default TimesheetList
