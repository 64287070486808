import { SubmitHelpers } from '@area2k/use-form'
import { useCallback, useState } from 'react'
import PlacesAutocomplete from 'react-google-places-autocomplete'

import { GetWorkerQuery, PointInput } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import TextField from '@/form/TextField'

import { handleMutationFormError } from '@/util/error'

import { useChangeWorkerAddressMutation } from '@/graphql'

export type Props = {
  worker: GetWorkerQuery['worker']
  hideModal: () => void
}

type FormValues = {
  addressLine1: GetWorkerQuery['worker']['addressLine1']
  addressLine2: GetWorkerQuery['worker']['addressLine2']
  city: GetWorkerQuery['worker']['city']
  state: GetWorkerQuery['worker']['state']
  zip: GetWorkerQuery['worker']['zip']
}

const ChangeAddressModal = ({ worker, hideModal }: Props) => {
  const initialValues: FormValues = {
    addressLine1: worker.addressLine1,
    addressLine2: worker.addressLine2,
    city: worker.city,
    state: worker.state,
    zip: worker.zip
  }

  const [coords, setCoords] = useState<PointInput>({
    latitude: 0,
    longitude: 0
  })

  const [value, setValue] = useState(null)
  const [stretch, setStretch] = useState(false)
  const [stretch2, setStretch2] = useState(false)

  const [
    changeWorkerAddress,
    { loading: isLoading }
  ] = useChangeWorkerAddressMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await changeWorkerAddress({
          variables: { workerId: worker.id, coords, ...values }
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError
          // errorMap: {
          //   all: () => ({
          //     title: 'Unknown service error',
          //     message: ''
          //   })
          // }
        })
      }
    },
    [worker.id, coords]
  )

  return (
    <Modal size='sm' title='Change worker address' onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <PlacesAutocomplete
            autocompletionRequest={{
              componentRestrictions: { country: 'us' },
              types: ['address']
            }}
            minLengthAutocomplete={2}
            selectProps={{
              value,
              onChange: newValue => {
                console.log(newValue)
                setValue(newValue)
              },
              onInputChange: e => {
                if (e.length > 1) {
                  setStretch(true)
                  setStretch2(false)
                }
              },
              onFocus: () => {
                setStretch2(true)
              },
              onMenuClose: () => {
                setStretch(false)
                setStretch2(false)
              }
            }}
          />
          <div style={{ height: 30 }} />
          {stretch2 && <div style={{ height: 50 }} />}
          {stretch && <div style={{ height: 220 }} />}
          <Stack justify='end'>
            <Button
              a11yLabel='Submit form'
              isLoading={isLoading}
              label='Save'
              type='submit'
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default ChangeAddressModal
