import { GetJobQuery } from '@/types/graphql'

import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body, Small, Subheading } from '@/components/Typography'
import { centsToCurrency } from '@/util/number'

type Props = {
  job: GetJobQuery['job']
}

const PaymentCard = ({ job: { costRate, payRate } }: Props) => {
  return (
    <Card
      actions={[
        {
          a11yLabel: 'Update job payment details',
          label: 'Edit',
          onAction: () => undefined,
        },
      ]}
      title="Payment"
    >
      <Card.Section>
        <Stack vertical>
          <Stack justify="apart">
            <Small>Base pay rate</Small>
            <Body>${centsToCurrency(payRate)}</Body>
          </Stack>
          <Stack justify="apart">
            <Small>Base bill rate</Small>
            <Body>${centsToCurrency(costRate)}</Body>
          </Stack>
          <Stack justify="apart">
            <Body>Total estimate</Body>
            <Subheading>${centsToCurrency(130423)}</Subheading>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default PaymentCard
