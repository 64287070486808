import { Outlet } from 'react-router-dom'

import AppFrame from '@/components/AppFrame'
import AppFooter from '@/components/AppFooter'
import AppNavbar from '@/components/AppNavbar'

import Sidebar from './Sidebar'

const TenantLayout = () => {
  return (
    <AppFrame
      Footer={<AppFooter />}
      Navbar={<AppNavbar />}
      Sidebar={<Sidebar />}
    >
      <Outlet />
    </AppFrame>
  )
}

export default TenantLayout
