import useModal from '@area2k/use-modal'
import { useCallback, useEffect } from 'react'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import { Body, Small, Subheading } from '@/components/Typography'

import PostSubmitModal from './PostSubmitModal'

import ScheduleSummary from '../JobEditor/Steps/ScheduleStep/ScheduleSummary'

import debug from '@/util/debug'
import { centsToCurrency, zeroPad } from '@/util/number'

import {
  costOfSchedule,
  jobStateToJobInput,
  scheduleDates,
  useOrderActions,
  useOrderState,
} from '../context'

import { useCreateOrderMutation } from '@/graphql'

type Props = {
  onOpenEditor: () => void
}

const Review = ({ onOpenEditor }: Props) => {
  const { billing, jobs } = useOrderState()
  const { clearJobs, removeJob, setBilling } = useOrderActions()

  const [createOrder, { data, loading }] = useCreateOrderMutation()

  const [showPostModal, hidePostModal] = useModal(
    () => (
      <PostSubmitModal
        order={data!.orderCreate.order}
        hideModal={() => {
          hidePostModal()
          clearJobs()
          setBilling(null)
        }}
      />
    ),
    [data]
  )

  const handleSubmit = useCallback(async () => {
    const result = await createOrder({
      variables: {
        accountId: billing!.account.id,
        jobs: jobs.flatMap((job) => jobStateToJobInput(billing!, job)),
      },
    })

    debug.log('[CreateOrder]', result)
  }, [billing, jobs])

  useEffect(() => {
    if (data) {
      showPostModal()
    }
  }, [data, showPostModal])

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Submit order',
        disabled: jobs.length === 0,
        isLoading: loading,
        label: 'Submit order',
        onAction: handleSubmit,
      }}
      secondaryActions={[
        {
          a11yLabel: 'Add job to order',
          label: 'Add job',
          onAction: onOpenEditor,
        },
      ]}
      size="md"
      subtitle={`Billed to ${billing!.account.name}`}
      title={`Order for ${billing!.customer.name}`}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            {jobs.map((job, jobIndex) => (
              <Card
                key={jobIndex}
                actions={[
                  {
                    a11yLabel: 'Remove job from order',
                    dangerous: true,
                    label: 'Remove',
                    onAction: () => removeJob(jobIndex),
                  },
                ]}
                title={job.skill!.name}
              >
                {job.schedules.map((schedule, scheduleIndex) => (
                  <ScheduleSummary key={scheduleIndex} schedule={schedule} />
                ))}
              </Card>
            ))}
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Card title="Summary">
            {jobs.length > 0 ? (
              <>
                <Card.Section>
                  <Stack vertical gap={12}>
                    <Stack justify="apart">
                      <Small>Total jobs</Small>
                      <Body>{zeroPad(jobs.length, 2)}</Body>
                    </Stack>
                    <Stack justify="apart">
                      <Small>Total shifts</Small>
                      <Body>
                        {zeroPad(
                          jobs.reduce(
                            (acc, el) =>
                              acc +
                              el.schedules.reduce(
                                (sacc, sel) => sacc + scheduleDates(sel).length,
                                0
                              ),
                            0
                          ),
                          2
                        )}
                      </Body>
                    </Stack>
                  </Stack>
                </Card.Section>
                <Card.Section>
                  <Stack justify="apart">
                    <Body>Total estimate</Body>
                    <Subheading>
                      $
                      {centsToCurrency(
                        jobs.reduce(
                          (acc, el) =>
                            acc +
                            el.schedules.reduce(
                              (sacc, sel) => sacc + costOfSchedule(sel),
                              0
                            ),
                          0
                        )
                      )}
                    </Subheading>
                  </Stack>
                </Card.Section>
              </>
            ) : (
              <EmptyState
                title="No jobs in order"
                text='Click "Add job" above to get started.'
              />
            )}
          </Card>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Review
