import { StitchesVariants } from '@stitches/react'

import styled from '@/styles'

const Wrapper = styled('span', {
  $$bgColor: '$colors$neutralA12',
  $$color: '$colors$textDefault',

  padding: '0 8px',

  backgroundColor: '$$bgColor',
  borderRadius: '$round',
  color: '$$color',

  fontSize: '$sm',

  variants: {
    size: {
      sm: {},
      md: {
        fontSize: '$md',
      },
    },
    status: {
      neutral: {},
      theme: {
        $$bgColor: '$colors$themeA16',
        $$color: '$colors$themeDarker',
      },
      warning: {
        $$bgColor: '$colors$warningA32',
        $$color: '$colors$textDefault',
      },
      danger: {
        $$bgColor: '$colors$dangerA12',
        $$color: '$colors$dangerDarker',
      },
    },
  },

  defaultVariants: {
    size: 'sm',
    status: 'neutral',
  },
})

type Props = StitchesVariants<typeof Wrapper> & {
  label: string
}

const Badge = ({ label, ...props }: Props) => {
  return <Wrapper {...props}>{label}</Wrapper>
}

export default Badge
