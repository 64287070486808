import { faTags } from '@fortawesome/free-solid-svg-icons'

import { GetCustomerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import TagList from '@/components/TagList'

type Props = {
  customer: GetCustomerQuery['customer']
}

const Tags = ({ customer }: Props) => {
  return (
    <Card
      actions={[{
        a11yLabel: "Change this customer's tags",
        label: 'Change',
        onAction: () => undefined
      }]}
      title='Tags'
    >
      {customer.tags.length > 0
        ? <Card.Section>
            <TagList tags={customer.tags.map((tag) => tag.name)} />
          </Card.Section>
        : <EmptyState
            icon={faTags}
            title='No tags'
            text='Add tags to this customer above'
          />
      }
    </Card>
  )
}

export default Tags
