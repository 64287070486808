import styled from '@/styles'

const TableCell = styled('td', {
  padding: '16px',

  color: '$textDefault',
  fontSize: '$md',
  lineHeight: 1.5,

  variants: {
    align: {
      center: {
        textAlign: 'center'
      },
      left: {
        textAlign: 'left'
      },
      right: {
        textAlign: 'right'
      }
    }
  },

  '&:first-child': {
    paddingLeft: '20px'
  },

  '&:last-child': {
    paddingRight: '20px'
  }
})

TableCell.displayName = 'stitches(Table.TableCell)'

export default TableCell
