import { format } from 'date-fns'

import { GetWorkerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Avatar from '@/components/Avatar'
import TableHeader from '@/components/TableHeader'
import Table, { TableCell, TableRow } from '@/elements/Table'
import Stack from '@/components/Stack'
import Link from '@/elements/Link'

type Item = GetWorkerQuery['worker']['pendingJobOffers'][0]

type Props = {
  worker: GetWorkerQuery['worker']
}

const PendingOffersList = ({ worker }: Props) => {
  const headerFields = ['Skill', 'Customer', 'Timeframe']

  const TableBody = ({ jobs }) => (
    <tbody>
      {jobs.map((item: Item) => (
        <TableRow key={item.id}>
          <TableCell>
            <Stack gap={12}>
              <Avatar size="xs" src={item.job.skill.imageUrl} />
              <Link
                to={`../../orders/${item.job.order.id}/jobs/${item.job.id}`}
              >
                {item.job.skill.name}
              </Link>
            </Stack>
          </TableCell>
          <TableCell>
            <Stack gap={12}>
              <div>
                <Avatar size="xs" src={item.job.customer.logoUrl} />
              </div>
              <Link to={`../../customers/${item.job.customer.id}`}>
                {item.job.customer.name}
              </Link>
            </Stack>
          </TableCell>
          <TableCell>
            {format(new Date(item.job.firstShiftStartAt), 'PP')} &mdash;{' '}
            {format(new Date(item.job.lastShiftEndAt), 'PP')}
          </TableCell>
        </TableRow>
      ))}
    </tbody>
  )

  const jobs = worker ? worker.pendingJobOffers : []
  return (
    <Card title="Pending offers">
      {jobs.length ? (
        <Table>
          <TableHeader fields={headerFields} />
          <TableBody jobs={jobs} />
        </Table>
      ) : (
        <EmptyState
          title="No pending job offers"
          text="If there were any, they would be here"
        />
      )}
    </Card>
  )
}

export default PendingOffersList
