import { useCallback } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import { GetCustomerQuery } from '@/types/graphql'
import { AdminItemFragmentDoc, useInviteAdminMutation } from '@/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'

import { handleMutationFormError } from '@/util/error'
import TextField from '@/form/TextField'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'

export type Props = {
  customer: GetCustomerQuery['customer']
  hideModal: () => void
}

type FormValues = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}

const InviteAdminModal = ({ customer, hideModal }: Props) => {
  const initialValues: FormValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  }

  const [inviteAdmin, { loading: isLoading }] = useInviteAdminMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `Customer:${customer.id}`,
        fields: {
          admins(existingRefs = [], { readField }) {
            if (!data) return existingRefs
            const { customerAdmin } = data.customerInviteAdmin

            if (
              existingRefs.some(
                (ref) => readField('id', ref) === customerAdmin.id
              )
            ) {
              return existingRefs
            }

            const newAdminRef = cache.writeFragment({
              fragment: AdminItemFragmentDoc,
              data: customerAdmin,
            })

            return [...existingRefs, newAdminRef]
          },
        },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await inviteAdmin({
          variables: {
            customerId: customer.id,
            ...values,
          },
        })
        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          // errorMap: {
          //   all: () => ({
          //     title: 'Unknown service error',
          //     message: ''
          //   })
          // }
        })
      }
    },
    []
  )

  return (
    <Modal
      size="sm"
      title="Invite a new administrator"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First name"
              placeholder="First name"
            />
            <TextField
              required
              fieldId="lastName"
              label="Last name"
              placeholder="Last name"
            />
          </FormColumns>
          <TextField
            required
            autoComplete="email"
            fieldId="email"
            label="New administrator email"
            placeholder="Email address"
            type="email"
          />
          <MaskedInputField
            fieldId="phoneNumber"
            incompleteMessage="Must be a valid phone number"
            label="Phone number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Send Invite"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default InviteAdminModal
