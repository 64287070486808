import Badge from '@/components/Badge'

import { JobStatus } from './util'

const JobBadge = ({ jobStatus }: { jobStatus: JobStatus }) => {
  switch (jobStatus) {
    case JobStatus.CANCELLED:
      return <Badge label="Cancelled" status="danger" />
    case JobStatus.UPCOMING:
      return <Badge label="Upcoming" />
    case JobStatus.COMPLETED:
      return <Badge label="Completed" status="theme" />
    default:
      return <Badge label="In progress" status="warning" />
  }
}

export default JobBadge
