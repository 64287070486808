import { useReactiveVar } from '@apollo/client'
import { currentAgencyVar } from '@/util/apollo/cache'
import { useEffect } from 'react'

import { GetJobQuery, ListJobCustomerAdminsQuery } from '@/types/graphql'

import ItemSelectField from '@/form/ItemSelectField'

import { useListJobCustomerAdminsLazyQuery } from '@/graphql'

export type ContactItem = ListJobCustomerAdminsQuery['job']['customer']['admins'][0]

type Props = {
  fieldId: string
  label: string
  job: GetJobQuery['job']
}

const ContactSelectField = ({ fieldId, label, job: { id } }: Props) => {
  const [fetch, { data, loading }] = useListJobCustomerAdminsLazyQuery()

  useEffect(() => {
    fetch({ variables: { jobId: id } })
  }, [])

  return (
    <ItemSelectField<ContactItem>
      required
      disabled={loading}
      fieldId={fieldId}
      items={data ? data.job.customer.admins : []}
      itemToKey={item => item.id}
      itemToString={item =>
        item ? `${item.user.firstName} ${item.user.lastName}` : ''
      }
      label={label}
    />
  )
}

export default ContactSelectField
