import { useCallback, useState } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import PlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-google-places-autocomplete'

import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'

import Form from '@/form'

import { useUpdateAddressMutation } from '@/graphql'
import { GetCustomerQuery, PointInput } from '@/types/graphql'
import { handleMutationFormError } from '@/util/error'

export type Props = {
  address: GetCustomerQuery['customer']['addresses'][0]
  hideModal: () => void
}

type FormValues = {
  addressLine1: GetCustomerQuery['customer']['addresses'][0]['addressLine1']
  addressLine2: GetCustomerQuery['customer']['addresses'][0]['addressLine2']
  city: GetCustomerQuery['customer']['addresses'][0]['city']
  name: GetCustomerQuery['customer']['addresses'][0]['name']
  state: GetCustomerQuery['customer']['addresses'][0]['state']
  zip: GetCustomerQuery['customer']['addresses'][0]['zip']
}

const UpdateAddressModal = ({ address, hideModal }: Props) => {
  const initialValues: FormValues = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    name: ''
  }
  let coords: PointInput = { latitude: 0, longitude: 0 }
  const [response, setResponse] = useState(null)
  const [stretch, setStretch] = useState(false)
  const [stretch2, setStretch2] = useState(false)

  const [updateAddress, { loading: isLoading }] = useUpdateAddressMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      handleResponse(values)
      geocodeByPlaceId(response.value.place_id)
        .then(results => {
          coords.latitude = results[0].geometry.location.lat()
          coords.longitude = results[0].geometry.location.lng()
          const { long_name: postalCode = '' } =
            results[0].address_components.find(c =>
              c.types.includes('postal_code')
            ) || {}
          values.zip = postalCode
          // console.log({ coords, ...values })
          create(values, coords, setFormError)
        })
        .catch(error => console.error(error))
    },
    [response]
  )

  const create = async (values: FormValues, coords, setFormError) => {
    try {
      await updateAddress({
        variables: { addressId: address.id, coords, ...values }
      })
      hideModal()
    } catch (err) {
      handleMutationFormError(err, {
        setFormError
        // errorMap: {
        //   all: () => ({
        //     title: 'Unknown service error',
        //     message: ''
        //   })
        // }
      })
    }
  }

  const handleResponse = values => {
    const shortResponse = response.value.structured_formatting
    const parsedResponse = shortResponse.secondary_text.split(',')
    console.log(parsedResponse)
    values.addressLine1 = shortResponse.main_text
    values.city = parsedResponse[0].trim()
    values.state = parsedResponse[1].trim()
  }

  return (
    <Modal size='sm' title='Update Address' onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <PlacesAutocomplete
            autocompletionRequest={{
              componentRestrictions: { country: 'us' },
              types: ['address']
            }}
            minLengthAutocomplete={2}
            selectProps={{
              placeholder: 'Type adress...',
              response,
              onChange: newValue => {
                setResponse(newValue)
              },
              onInputChange: e => {
                if (e.length > 1) {
                  setStretch(true)
                  setStretch2(false)
                }
              },
              onFocus: () => {
                setStretch2(true)
              },
              onMenuClose: () => {
                setStretch(false)
                setStretch2(false)
              }
            }}
          />
          <div style={{ height: 30 }} />
          {stretch2 && <div style={{ height: 50 }} />}
          {stretch && <div style={{ height: 220 }} />}
          <Stack justify='end'>
            <Button
              a11yLabel='Submit form'
              isLoading={isLoading}
              label='Save'
              type='submit'
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UpdateAddressModal
