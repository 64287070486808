import { GetJobQuery } from '@/types/graphql'

import Link from '@/elements/Link'

import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'

type Props = {
  job: GetJobQuery['job']
}

const OrderSection = ({ job: { account, order } }: Props) => {
  return (
    <Card.Section>
      <Stack vertical>
        <Stack justify="apart">
          <Small>Order</Small>
          <Body>
            <Link to="../..">#{10000 + Number(order.id)}</Link>
          </Body>
        </Stack>
        <Stack justify="apart">
          <Small>Customer</Small>
          <Body>
            <Link to={`../../../../customers/${account.customer.id}`}>
              {account.customer.name}
            </Link>
          </Body>
        </Stack>
      </Stack>
    </Card.Section>
  )
}

export default OrderSection
