import startOfHour from 'date-fns/startOfHour'
import { useMemo, useState } from 'react'
import { format } from 'date-fns'

import {
  CompareOperatorEnum,
  GetWorkerJobWorkersQuery,
  GetWorkerQuery,
  JobWorkerFilterSetInput,
} from '@/types/graphql'
import { useGetWorkerJobWorkersQuery } from '@/graphql'

import Card from '@/components/Card'
import QueryEmptyState from '@/components/QueryEmptyState'
import Tabs, { TabDefinition } from '@/components/Tabs'
import Avatar from '@/components/Avatar'
import TableHeader from '@/components/TableHeader'
import Table, { TableCell, TableRow } from '@/elements/Table'
import Stack from '@/components/Stack'
import Link from '@/elements/Link'

const UPCOMING = 0
const JOBS_TABS: TabDefinition[] = [
  { a11yLabel: 'View upcoming jobs for this worker', label: 'Upcoming' },
  { a11yLabel: 'View past jobs for this worker', label: 'Past' },
]

const generatePastFilters = (): JobWorkerFilterSetInput => ({
  lastShiftEndAt: { op: CompareOperatorEnum.LTEQ, value: new Date() },
})

const generateUpcomingFilters = (): JobWorkerFilterSetInput => ({
  lastShiftEndAt: { op: CompareOperatorEnum.GT, value: new Date() },
})

type Item = GetWorkerJobWorkersQuery['worker']['jobWorkers']['items'][0]

type Props = {
  worker: GetWorkerQuery['worker']
}

const JobsList = ({ worker }: Props) => {
  const [tab, setTab] = useState(0)

  const pastFilters = useMemo(() => generatePastFilters(), [
    startOfHour(new Date()).getHours(),
  ])
  const upcomingFilters = useMemo(() => generateUpcomingFilters(), [
    startOfHour(new Date()).getHours(),
  ])

  const query = useGetWorkerJobWorkersQuery({
    variables: {
      filters: tab === UPCOMING ? upcomingFilters : pastFilters,
      workerId: worker.id,
    },
  })

  const headerFields = ['Skill', 'Customer', 'Timeframe']

  const TableBody = ({ jobs }) => (
    <tbody>
      {jobs.map((item: Item) => (
        <TableRow key={item.id}>
          <TableCell>
            <Stack gap={12}>
              <Avatar size="xs" src={item.job.skill.imageUrl} />
              <Link to={`../../orders/${item.order.id}/jobs/${item.job.id}`}>
                {item.job.skill.name}
              </Link>
            </Stack>
          </TableCell>
          <TableCell>
            <Stack gap={12}>
              <div>
                <Avatar size="xs" src={item.job.customer.logoUrl} />
              </div>
              <Link to={`../customers/${item.job.customer.id}`}>
                {item.job.customer.name}
              </Link>
            </Stack>
          </TableCell>
          <TableCell>
            {format(new Date(item.job.firstShiftStartAt), 'PP')} &mdash;{' '}
            {format(new Date(item.job.lastShiftEndAt), 'PP')}
          </TableCell>
        </TableRow>
      ))}
    </tbody>
  )

  const jobs = query.data ? query.data.worker.jobWorkers.items : []
  return (
    <Card title="Jobs">
      <Tabs fit selected={tab} tabs={JOBS_TABS} onSelect={setTab} />
      <Card title=" ">
        {jobs.length ? (
          <Table>
            <TableHeader fields={headerFields} />
            <TableBody jobs={jobs} />
          </Table>
        ) : (
          <QueryEmptyState
            query={query}
            title="No jobs available"
            text="If there were any, they would be here."
          />
        )}
      </Card>
    </Card>
  )
}

export default JobsList
