import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { addDays, format, startOfToday } from 'date-fns'
import { useCallback, useState } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'
import IconicButton from '@/components/IconicButton'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

import ShiftGrid from './ShiftGrid'

const JobWeekList = () => {
  const [startDate, setStartDate] = useState(startOfToday())

  const goBack = useCallback(
    () => setStartDate((date) => addDays(date, -7)),
    []
  )

  const goForward = useCallback(
    () => setStartDate((date) => addDays(date, 7)),
    []
  )

  return (
    <Card>
      <Card.Section>
        <Stack justify="apart">
          <Stack gap={16}>
            <IconicButton
              a11yLabel="View previous week"
              appearance="clear"
              icon={faChevronLeft}
              size="xs"
              onClick={goBack}
            />
            <Body>
              {format(startDate, 'MMM do')} &mdash;{' '}
              {format(addDays(startDate, 6), 'MMM do')}
            </Body>
            <IconicButton
              a11yLabel="View next week"
              appearance="clear"
              icon={faChevronRight}
              size="xs"
              onClick={goForward}
            />
          </Stack>
          <Stack justify="end">
            <Button
              hasPopover
              a11yLabel="Change status filter"
              appearance="outline"
              label="Status"
            />
            <Button
              a11yLabel="Change all filters"
              appearance="outline"
              label="More filters"
            />
          </Stack>
        </Stack>
      </Card.Section>
      <ShiftGrid startDate={startDate} />
    </Card>
  )
}

export default JobWeekList
