import { GetJobQuery } from '@/types/graphql'

import Card from '@/components/Card'

import AddressSection from './AddressSection'
import ContactSection from './ContactSection'
import OrderSection from './OrderSection'
import UniformSection from './UniformSection'

type Props = {
  job: GetJobQuery['job']
}

const DetailsCard = ({ job }: Props) => {
  return (
    <Card title='Job details'>
      <OrderSection job={job} />
      <AddressSection job={job} />
      <ContactSection job={job} />
      <UniformSection job={job} />
    </Card>
  )
}

export default DetailsCard
