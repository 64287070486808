import TextAreaField from '@/form/TextAreaField'

const InstructionsSection = () => {
  return (
    <div style={{ width: '100% '}}>
      <TextAreaField
        fieldId='instructions'
        label='Job instructions & additional details'
        placeholder='Provide instructions and details to the workers...'
      />
    </div>
  )
}

export default InstructionsSection
