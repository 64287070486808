import { useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'

import { AutocompleteCustomersQuery } from '@/types/graphql'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import AutocompleteField from '@/form/AutocompleteField'

import { currentAgencyVar } from '@/util/apollo/cache'

import { useAutocompleteCustomersLazyQuery } from '@/graphql'

export type CustomerItem = AutocompleteCustomersQuery['agency']['customers']['items'][0]

export type Props = {
  fieldId: string
}

const CustomerAutocompleteField = ({ fieldId }: Props) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)

  const currentAgency = useReactiveVar(currentAgencyVar)
  const [fetch, { data, loading }] = useAutocompleteCustomersLazyQuery()

  useEffect(() => {
    fetch({ variables: { agencyId: currentAgency!.id, query: debouncedQuery } })
  }, [debouncedQuery])

  return (
    <AutocompleteField<CustomerItem>
      required
      fieldId={fieldId}
      items={data ? data.agency.customers.items : []}
      itemToKey={(item) => item.id}
      itemToString={(item) => item ? item.name: ''}
      label='Customer'
      placeholder='Search...'
      onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
    />
  )
}

export default CustomerAutocompleteField
