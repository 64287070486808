import { useField } from '@area2k/use-form'
import { ChangeEvent, useCallback } from 'react'

import SelectableBox, { Props as SelectableBoxProps } from '@/components/SelectableBox'

type BoxProps = Omit<SelectableBoxProps, 'id' | 'isSelected' | 'name' | 'value' | 'onChange'>
type Option<T> = { a11yLabel?: string, disabled?: boolean, item: T }

type Props<T> = BoxProps & {
  disabled?: boolean
  fieldId: string
  options: Option<T>[]
  required?: boolean
  itemToKey: (item: T) => string
  renderItem: (item: T) => JSX.Element
}

const SingleSelectableField = <T extends any>({ disabled = false, fieldId, itemToKey, options, required = false, renderItem }: Props<T>) => {
  const { value, setValue } = useField<T | null>(fieldId)

  const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValue(options[Number(ev.currentTarget.value)].item)
  }, [options])

  return (
    <>
      {options.map((option, index) => (
        <SelectableBox
          key={`${fieldId}.${index}`}
          disabled={disabled || option.disabled}
          id={`${fieldId}.${index}`}
          isSelected={value ? itemToKey(option.item) === itemToKey(value) : false}
          name={fieldId}
          required={required}
          type='radio'
          value={String(index)}
          onChange={handleChange}
        >
          {renderItem(option.item)}
        </SelectableBox>
      ))}
    </>
  )
}

export default SingleSelectableField
