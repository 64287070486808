import { faChartBar, faChartPie, faCog, faDatabase, faFileInvoiceDollar, faFunnelDollar } from '@fortawesome/free-solid-svg-icons'

import NavigationMenu from '@/components/NavigationMenu'

const Sidebar = () => {
  return (
    <NavigationMenu
      groups={[
        {
          items: [
            { icon: faDatabase, a11yLabel: 'Agencies', to: '/', end: true }
          ]
        },
        {
          title: 'Reports',
          items: [
            { icon: faFunnelDollar, a11yLabel: 'Cash Flow', to: '/reports/cash-flow' },
            { icon: faFileInvoiceDollar, a11yLabel: 'Payouts', to: '/reports/payouts' },
            { icon: faChartBar, a11yLabel: 'Job Statistics', to: '/reports/job-stats' },
            { icon: faChartPie, a11yLabel: 'More reports', to: '/reports', end: true }
          ]
        },
        {
          items: [
            { icon: faCog, a11yLabel: 'Settings', to: '/settings' }
          ]
        }
      ]}
    />
  )
}

export default Sidebar
