import { ApolloCache } from '@apollo/client'
import useModal from '@area2k/use-modal'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { useCallback } from 'react'

import { GetOrderQuery, SimpleTagFragment, TaggableEnum } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import TagList from '@/components/TagList'

import ChangeTagsModal from '@/modals/ChangeTagsModal'

import { SimpleTagFragmentDoc } from '@/graphql'

type Props = {
  order: GetOrderQuery['order']
}

const Tags = ({ order }: Props) => {
  const updateTagsCache = useCallback(
    (cache: ApolloCache<any>, tags: SimpleTagFragment[]) => {
      cache.modify({
        id: cache.identify(order),
        fields: {
          tags() {
            const tagRefs = tags.map((tag) =>
              cache.writeFragment({
                data: tag,
                fragment: SimpleTagFragmentDoc,
              })
            )

            return tagRefs
          },
        },
      })
    },
    []
  )

  const [showChangeModal, hideChangeModal] = useModal(
    () => (
      <ChangeTagsModal
        taggableId={order.id}
        taggableType={TaggableEnum.ORDER}
        tags={order.tags}
        title="Change order's tags"
        hideModal={hideChangeModal}
        updateCache={updateTagsCache}
      />
    ),
    [order.tags]
  )

  return (
    <Card
      actions={[
        {
          a11yLabel: "Change this order's tags",
          label: 'Change',
          onAction: showChangeModal,
        },
      ]}
      title="Tags"
    >
      {order.tags.length > 0 ? (
        <Card.Section>
          <TagList tags={order.tags.map((tag) => tag.name)} />
        </Card.Section>
      ) : (
        <EmptyState
          icon={faTags}
          title="No tags"
          text="Add tags to this order above"
        />
      )}
    </Card>
  )
}

export default Tags
