import { useCallback } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'

import Form from '@/form'
import TextField from '@/form/TextField'

import { AccountItemFragmentDoc, useCreateAccountMutation } from '@/graphql'
import { GetCustomerQuery } from '@/types/graphql'
import { handleMutationFormError } from '@/util/error'
import ItemSelectField from '@/form/ItemSelectField'

type AdminItem = GetCustomerQuery['customer']['admins'][0]

type FormValues = {
  name: string
  defaultContact: GetCustomerQuery['customer']['admins'][0]
}

type Props = {
  customer: GetCustomerQuery['customer']
  hideModal: () => void
}

const CreateAccountModal = ({ customer, hideModal }: Props) => {
  const initialValues: FormValues = {
    name: '',
    defaultContact: customer.admins[0],
  }

  const [createAccount, { loading: isLoading }] = useCreateAccountMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `Customer:${customer.id}`,
        fields: {
          accounts(existingRefs = [], { readField }) {
            if (!data) return existingRefs
            const { account } = data.accountCreate

            if (
              existingRefs.some((ref) => readField('id', ref) === account.id)
            ) {
              return existingRefs
            }

            const newAccountRef = cache.writeFragment({
              fragment: AccountItemFragmentDoc,
              data: account,
            })

            return [...existingRefs, newAccountRef]
          },
        },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const { data } = await createAccount({
          variables: {
            customerId: customer.id,
            defaultContactId: values.defaultContact.id,
            name: values.name,
          },
        })
        console.log({ data })
        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          // errorMap: {
          //   all: () => ({
          //     title: 'Unknown service error',
          //     message: ''
          //   })
          // }
        })
      }
    },
    []
  )

  return (
    <Modal size="sm" title="New customer" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            fieldId="name"
            label="Name"
            placeholder="Account name"
          />
          <ItemSelectField<AdminItem>
            required
            fieldId="defaultContact"
            items={customer.admins}
            itemToKey={(item) => item.id}
            itemToString={(item) =>
              typeof item != 'string'
                ? `${item?.user.firstName} ${item?.user.lastName}`
                : ''
            }
            label="Default contact"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Create"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default CreateAccountModal
