import { CreateOrderMutation } from '@/types/graphql'

import Action from '@/components/Action'
import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'
import { useReactiveVar } from '@apollo/client'
import { currentAgencyVar } from '@/util/apollo/cache'

type Props = {
  order: CreateOrderMutation['orderCreate']['order']
  hideModal: () => void
}

const PostSubmitModal = ({ order, hideModal }: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar)

  return (
    <Modal
      size="xs"
      title={`Order #${10000 + Number(order.id)} placed!`}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>
            Your order #{10000 + Number(order.id)} was successfully placed.
          </Body>
          <Stack justify="end">
            <Button
              a11yLabel="Start a new order"
              appearance="outline"
              label="Place another"
              onClick={hideModal}
            />
            <Action.Button
              action={{
                a11yLabel: 'Navigate to order page',
                label: 'View order',
                to: `/a/${currentAgency!.key}/orders/${order.id}`,
              }}
              appearance="primary"
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default PostSubmitModal
