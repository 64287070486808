import { ApolloCache } from '@apollo/client'
import useModal from '@area2k/use-modal'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { useCallback } from 'react'

import { GetWorkerQuery, SimpleTagFragment, TaggableEnum } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import TagList from '@/components/TagList'

import ChangeTagsModal from '@/modals/ChangeTagsModal'

import { SimpleTagFragmentDoc } from '@/graphql'

type Props = {
  worker: GetWorkerQuery['worker']
}

const Tags = ({ worker }: Props) => {
  const updateTagsCache = useCallback((cache: ApolloCache<any>, tags: SimpleTagFragment[]) => {
    cache.modify({
      id: cache.identify(worker),
      fields: {
        tags() {
          const tagRefs = tags.map((tag) => (
            cache.writeFragment({
              data: tag,
              fragment: SimpleTagFragmentDoc
            })
          ))

          return tagRefs
        }
      }
    })
  }, [])

  const [showChangeModal, hideChangeModal] = useModal(() => (
    <ChangeTagsModal
      taggableId={worker.id}
      taggableType={TaggableEnum.WORKER}
      tags={worker.tags}
      title="Change worker's tags"
      hideModal={hideChangeModal}
      updateCache={updateTagsCache}
    />
  ), [worker.tags])

  return (
    <Card
      actions={[{
        a11yLabel: "Change this worker's tags",
        label: 'Change',
        onAction: showChangeModal
      }]}
      title='Tags'
    >
      {worker.tags.length > 0
        ? <Card.Section>
            <TagList tags={worker.tags.map((tag) => tag.name)} />
          </Card.Section>
        : <EmptyState
            icon={faTags}
            title='No tags'
            text='Add tags to this worker above'
          />
      }
    </Card>
  )
}

export default Tags
