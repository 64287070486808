import { Navigate } from 'react-router-dom'

import { clearCache } from '@/util/apollo/cache'
import ls from '@/util/localstorage'

const Signout = () => {
  ls.clearAuth()
  clearCache()

  return <Navigate to='/login' />
}

export default Signout
