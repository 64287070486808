import { useParams } from 'react-router'

import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'

import AccountList from './AccountList'
import AdminList from './AdminList'
import PageSkeleton from './PageSkeleton'
import SettingsCard from './SettingsCard'
import Tags from './Tags'

import { useGetCustomerQuery } from '@/graphql'
import AddressList from './AddressList'

const Customer = () => {
  const { customerId } = useParams()

  const query = useGetCustomerQuery({
    variables: { customerId },
  })

  if (!query.data) {
    return <PageSkeleton />
  }

  const customer = query.data.customer

  return (
    <Page title={customer.name}>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <AccountList customer={customer} />
            <AddressList customer={customer} />
            <AdminList customer={customer} />
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <SettingsCard customer={customer} />
            <Tags customer={customer} />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Customer
