import Badge from '@/components/Badge'

import { CandidateStatusEnum } from '@/types/graphql'

type Props = {
  status: CandidateStatusEnum
}

const CandidateBadge = ({ status }: Props) => {
  switch (status) {
    case CandidateStatusEnum.UNVERIFIED:
      return <Badge label="Unverified" status="neutral" />
    case CandidateStatusEnum.VERIFIED:
      return <Badge label="Verified" status="warning" />
    default:
      return <Badge label="In progress" status="warning" />
  }
}

export default CandidateBadge
