import { Maybe } from '@/types'

import styled from '@/styles'

import Text from '@/elements/Text'

import { Step } from '../../context'

const isStepTraversable = (step: Step, completedStep: Maybe<Step>) => (
  completedStep ? step <= completedStep + 1 : step === Step.SKILL
)

const isStepNext = (step: Step, completedStep: Maybe<Step>) => (
  completedStep ? step === completedStep + 1 : step === Step.SKILL
)

const Wrapper = styled('ol', {
  alignItems: 'center',
  display: 'flex',
  margin: 0,

  listStyle: 'none'
})

Wrapper.displayName = 'stitches(Progress.Wrapper)'

const Item = styled('li', {
  $$pathTop: '6px',

  $$beforePathColor: '$colors$neutralLighter',
  $$afterPathColor: '$colors$neutralLighter',

  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  paddingTop: '2px',

  color: '$textLighter',

  '&::after': {
    content: '""',
    height: '1px',
    width: '50%',

    position: 'absolute',
    left: '50%',
    top: '$$pathTop',

    backgroundColor: '$$afterPathColor',

    zIndex: -1
  },

  '&:not(:first-child)': {
    marginLeft: '24px',

    '&::before': {
      content: '""',
      height: '1px',
      width: 'calc(50% + 24px)',

      position: 'absolute',
      left: -24,
      top: '$$pathTop',

      backgroundColor: '$$beforePathColor'
    }
  },

  '&:last-child': {
    '&::after': {
      display: 'none'
    }
  },

  variants: {
    isNext: {
      true: {},
      false: {}
    },
    isTraversable: {
      true: {
        $$pathTop: '10px',

        $$beforePathColor: '$colors$themeDefault',
        $$afterPathColor: '$colors$themeDefault',

        paddingTop: 2,

        color: '$textDefault'
      },
      false: {}
    }
  },

  compoundVariants: [{
    isNext: true,
    isTraversable: true,
    css: {
      $$afterPathColor: '$colors$neutralLighter'
    }
  }]
})

Item.displayName = 'stitches(Progress.Item)'

const Indicator = styled('div', {
  height: 12,
  width: 12,

  backgroundColor: 'white',
  border: '1px solid $colors$neutralLight',
  borderRadius: '$round'
})

Indicator.displayName = 'stitches(Progress.Indicator)'

const StepButton = styled('button', {
  all: 'unset',
  boxSize: 20,

  backgroundColor: '$colors$themeDefault',
  borderRadius: '$round',
  color: 'white',
  cursor: 'pointer',

  fontSize: '$sm',
  textAlign: 'center',

  zIndex: 10,

  focusPseudoElement: {
    element: 'after'
  },

  variants: {
    isCurrent: {
      true: {
        boxShadow: '0 0 0 3px $colors$themeA24'
      },
      false: {}
    }
  }
})

StepButton.displayName = 'stitches(Progress.StepButton)'

export type Props = {
  completedStep: Maybe<Step>
  currentStep: Step
  setStep: (step: Step) => void
}

const Progress = ({ completedStep, currentStep, setStep }: Props) => {
  return (
    <Wrapper>
      <Item
        isTraversable={isStepTraversable(Step.SKILL, completedStep)}
        isNext={isStepNext(Step.SKILL, completedStep)}
      >
        {isStepTraversable(Step.SKILL, completedStep)
          ? <StepButton
              isCurrent={currentStep === Step.SKILL}
              onClick={() => setStep(Step.SKILL)}
            >
              {Step.SKILL}
            </StepButton>
          : <Indicator />
        }
        <Text color='inherit' size='sm'>Job Type</Text>
      </Item>
      <Item
        isTraversable={isStepTraversable(Step.DETAILS, completedStep)}
        isNext={isStepNext(Step.DETAILS, completedStep)}
      >
        {isStepTraversable(Step.DETAILS, completedStep)
          ? <StepButton
              isCurrent={currentStep === Step.DETAILS}
              onClick={() => setStep(Step.DETAILS)}
            >
              {Step.DETAILS}
            </StepButton>
          : <Indicator />
        }
        <Text color='inherit' size='sm'>Instructions</Text>
      </Item>
      <Item
        isTraversable={isStepTraversable(Step.SCHEDULE, completedStep)}
        isNext={isStepNext(Step.SCHEDULE, completedStep)}
      >
        {isStepTraversable(Step.SCHEDULE, completedStep)
          ? <StepButton
              isCurrent={currentStep === Step.SCHEDULE}
              onClick={() => setStep(Step.SCHEDULE)}
            >
              {Step.SCHEDULE}
            </StepButton>
          : <Indicator />
        }
        <Text color='inherit' size='sm'>Schedule</Text>
      </Item>
      <Item
        isTraversable={isStepTraversable(Step.PUBLISHING, completedStep)}
        isNext={isStepNext(Step.PUBLISHING, completedStep)}
      >
        {isStepTraversable(Step.PUBLISHING, completedStep)
          ? <StepButton
              isCurrent={currentStep === Step.PUBLISHING}
              onClick={() => setStep(Step.PUBLISHING)}
            >
              {Step.PUBLISHING}
            </StepButton>
          : <Indicator />
        }
        <Text color='inherit' size='sm'>Publishing</Text>
      </Item>
    </Wrapper>
  )
}

export default Progress
