import {
  faCalendarCheck,
  faCog,
  faDollarSign,
  faIndustry,
  faMapMarked,
  faPencilRuler,
  faShoppingBasket,
  faTachometerAlt,
  faUserFriends,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'
import {
  faAddressBook,
  faAddressCard,
  faBuilding,
  faCommentAlt,
  faIdCard,
} from '@fortawesome/free-regular-svg-icons'

import styled from '@/styles'

import NavigationMenu from '@/components/NavigationMenu'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'
import Icon from '@/elements/Icon'
import { useReactiveVar } from '@apollo/client'
import { currentAgencyVar } from '@/util/apollo/cache'
import Skeleton from '@/components/Skeleton'

// , {
//   title: 'Services',
//   items: [
//     { icon: faPencilRuler, a11yLabel: 'Skills', to: 'skills' },
//     { icon: faUserTie, a11yLabel: 'Uniforms', to: 'uniforms' },
//     { icon: faDollarSign, a11yLabel: 'Rates', to: 'rates' },
//     { icon: faMapMarked, a11yLabel: 'Service areas', to: 'service-areas' }
//   ]
// },

const AgencyWrapper = styled('div', {
  padding: 16,
  width: '100%',

  borderBottom: '1px solid $neutralLightest',
})

const AgencyAvatar = styled('div', {
  alignItems: 'center',
  boxSize: 36,
  display: 'flex',
  justifyContent: 'center',

  backgroundColor: '$neutralLighter',
  borderRadius: '$lg',
})

const Sidebar = () => {
  const currentAgency = useReactiveVar(currentAgencyVar)

  return (
    <Stack vertical>
      <AgencyWrapper>
        <Stack gap={16}>
          <div>
            <AgencyAvatar>
              <Icon fixedWidth icon={faBuilding} />
            </AgencyAvatar>
          </div>
          {currentAgency ? (
            <Stack vertical gap={0}>
              <Body weight="semibold">{currentAgency!.name}</Body>
              <Small>Washington, DC</Small>
            </Stack>
          ) : (
            <Skeleton.Body lines={2} />
          )}
        </Stack>
      </AgencyWrapper>
      <NavigationMenu
        groups={[
          {
            items: [
              {
                icon: faTachometerAlt,
                a11yLabel: 'Dashboard',
                to: '.',
                end: true,
              },
            ],
          },
          {
            title: 'Scheduling',
            items: [
              { icon: faShoppingBasket, a11yLabel: 'Orders', to: 'orders' },
              { icon: faBuilding, a11yLabel: 'Customers', to: 'customers' },
              { icon: faAddressBook, a11yLabel: 'Contacts', to: 'contacts' },
            ],
          },
          {
            title: 'Workforce',
            items: [
              { icon: faUserFriends, a11yLabel: 'Workers', to: 'workers' },
              {
                icon: faAddressCard,
                a11yLabel: 'Candidates',
                to: 'candidates',
              },
              { icon: faCommentAlt, a11yLabel: 'Chat', to: 'chat' },
            ],
          },
          {
            items: [{ icon: faCog, a11yLabel: 'Settings', to: 'settings' }],
          },
        ]}
      />
    </Stack>
  )
}

export default Sidebar
