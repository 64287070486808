import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { useParams } from 'react-router'

import styled from '@/styles'

import { useGetAdminCustomerQuery } from '@/graphql'

import Link from '@/elements/Link'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

const Grid = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto auto',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '24px',
})

const CustomerAdmin = () => {
  const { adminId } = useParams()
  const query = useGetAdminCustomerQuery({
    variables: { customerAdminId: adminId },
  })

  if (!query.data) {
    return null
  }

  const user = query.data.customerAdmin.user
  const customer = query.data.customerAdmin.customer

  return (
    <Page
      media={<Avatar src={query.data.customerAdmin.avatarUrl} />}
      title={`${user.firstName} ${user.lastName}`}
      primaryAction={{
        a11yLabel: 'Open chat with this admin',
        icon: faCommentAlt,
        label: 'Send message',
        to: `../../../../chat/${query.data.customerAdmin.chatUid}`,
      }}
      secondaryActions={[
        {
          a11yLabel: 'Change Password',
          label: 'Change password',
          onAction: () => {},
        },
      ]}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <Card
              title="Information"
              sectioned
              actions={[
                {
                  a11yLabel: 'Edit admin info',
                  label: 'Edit',
                  onAction: () => {},
                },
              ]}
            >
              <Grid>
                <TextStack spacing="loose">
                  <Small>Name</Small>
                  <Body>{`${user.firstName} ${user.lastName}`}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Email</Small>
                  <Body>{`${user.email}`}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Phone</Small>
                  <Body>{`${user.phoneNumber}`}</Body>
                </TextStack>
              </Grid>
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Card sectioned title="Customer">
            <Stack gap={20}>
              <div>
                <Avatar size="sm" src={customer.logoUrl} />
              </div>
              <Link to="../..">
                <Body color="inherit" weight="medium">
                  {customer.name}
                </Body>
              </Link>
            </Stack>
          </Card>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default CustomerAdmin
