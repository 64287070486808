import { useField } from '@area2k/use-form'
import { useCallback } from 'react'

import Stack from '@/components/Stack'
import { Heading } from '@/components/Typography'

import SingleSelectableField from '@/form/SingleSelectableField'

import { Skill, SkillCategory } from '../../context'

type Props = {
  fieldId: string
  skillCategoryFieldId: string
}

const SkillSelectField = ({ skillCategoryFieldId, fieldId }: Props) => {
  const { setValue } = useField<Skill | null>(fieldId)
  const { value: skillCategory } = useField<SkillCategory | null>(skillCategoryFieldId, () => {
    setValue(null)
  })

  const renderItem = useCallback((item: Skill) => (
    <Stack vertical gap={0}>
      <img style={{ width: 128, height: 128 }} src={item.imageUrl || '//placehold.it/128x128'} />
      <div style={{ padding: 16, width: '100%' }}>
        {item.name}
      </div>
    </Stack>
  ), [])

  if (!skillCategory) {
    return null
  }

  return (
    <Stack vertical align='center' gap={24}>
      <Heading>
        Select job skill
      </Heading>
      <Stack gap={16} justify='center'>
        <SingleSelectableField<Skill>
          required
          fieldId={fieldId}
          options={skillCategory.skills.map((item) => ({ item }))}
          itemToKey={(item) => item.id}
          renderItem={renderItem}
        />
      </Stack>
    </Stack>
  )
}

export default SkillSelectField
