import { format } from 'date-fns'

import { enums } from './index'

import Link from '@/elements/Link'

import Card from '@/components/Card'
import Paginator from '@/components/Paginator/Paginator'
import LoadingState from '@/components/LoadingState'
import Table, { TableCell, TableRow } from '@/elements/Table'
import QueryEmptyState from '@/components/QueryEmptyState'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'
import TableHeader from '@/components/TableHeader'

type Props = {
  query: any
  currentPage: number
  itemsPerPage: number
  handlePagination: (string, number) => void
}

const OrderTable = ({
  query,
  currentPage,
  itemsPerPage,
  handlePagination,
}: Props) => {
  const handlePageChange = (pageNumber: number) => {
    handlePagination(enums.PAGE_CHANGE, pageNumber)
  }

  const handleNumberItemsChange = (event) => {
    handlePagination(enums.NUMBER_ITEMS_CHANGE, parseInt(event.target.value))
  }

  const headerFields = ['ID', 'Timeframe', 'Size']

  const items =
    !query.loading && query.data ? query.data.account.orders.items : []
  const pageInfo =
    !query.loading && query.data ? query.data.account.orders.pageInfo : {}

  return (
    <>
      <Card title="Orders">
        <Table>
          <TableHeader fields={headerFields} />
          <tbody>
            {items
              .map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link to={`../../../../orders/${item.id}`}>
                      #{10000 + Number(item.id)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {format(new Date(item.startAt), 'PP')} &mdash;{' '}
                    {format(new Date(item.endAt), 'PP')}
                  </TableCell>
                  <TableCell>
                    {item.jobsCount} {item.jobsCount === 1 ? 'job' : 'jobs'}
                  </TableCell>
                </TableRow>
              ))
              .reverse()}
            {query.loading && (
              <TableRow>
                <TableCell colSpan={headerFields.length} align="center">
                  <LoadingState overlayColor="white" />
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
        {items.length == 0 && (
          <QueryEmptyState
            query={query}
            title="No orders"
            text="No orders were found, create one above."
          />
        )}
      </Card>
      {items.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={pageInfo.totalItems}
        />
      )}
    </>
  )
}

export default OrderTable
