import {
  faChevronDown,
  faCog,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { useReactiveVar } from '@apollo/client'
import { Link } from 'react-router-dom'

import styled from '@/styles'

import Icon from '@/elements/Icon'
import Text from '@/elements/Text'

import Avatar from '@/components/Avatar'
import Stack from '@/components/Stack'

import { currentTenantAdminVar, currentTenantVar } from '@/util/apollo/cache'
import DropdownMenu from './DropdownMenu'
import TextStack from './TextStack'

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  height: 48,

  backgroundColor: '#1a1a1a',
  boxShadow: '0 0 2px 2px $colors$neutralA12',
})

Wrapper.displayName = 'stitches(Navbar.Wrapper)'

const Branding = styled(Link, {
  alignItems: 'center',
  display: 'flex',
  height: 48,
  justifyContent: 'center',
  padding: '0 20px',
  width: 256,

  backgroundColor: 'transparent',
  borderRight: '1px solid $colors$whiteA16',
  color: 'white',
  outline: 'none',

  textDecoration: 'none',

  '&:hover, &:focus': {
    backgroundColor: '$whiteA8',
  },

  '&:focus-visible': {
    boxShadow: 'inset 0 0 1px 3px $colors$whiteA32',
  },
})

Branding.displayName = 'stitches(Navbar.Branding)'

const Profile = styled('button', {
  all: 'unset',
  alignItems: 'center',
  display: 'flex',
  gap: 20,
  height: 48,
  justifyContent: 'space-between',
  padding: '0 20px',

  backgroundColor: 'transparent',
  borderLeft: '1px solid $colors$whiteA16',
  color: 'white',
  cursor: 'pointer',
  outline: 'none',

  textDecoration: 'none',

  '&:hover, &:focus': {
    backgroundColor: '$whiteA8',
  },

  '&:focus-visible': {
    boxShadow: 'inset 0 0 1px 3px $colors$whiteA32',
  },
})

Profile.displayName = 'stitches(Navbar.Profile)'

const ProfileField = styled('div', {
  width: '128px',

  overflow: 'hidden',

  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  variants: {
    light: {
      true: {
        color: '$whiteA76',

        fontSize: '$sm',
        lineHeight: '14px',
      },
      false: {
        color: 'white',

        fontSize: '$md',
        lineHeight: '16px',
      },
    },
  },
})

const AppNavbar = () => {
  const currentTenant = useReactiveVar(currentTenantVar)
  const currentTenantAdmin = useReactiveVar(currentTenantAdminVar)

  return (
    <Wrapper>
      <Branding to="/">
        {currentTenant ? (
          currentTenant.logoUrl ? (
            <img
              style={{ width: '100%', height: 36 }}
              src={currentTenant.logoUrl}
            />
          ) : (
            <Text color="inherit" size="lg" weight="semibold">
              {currentTenant.name}
            </Text>
          )
        ) : null}
      </Branding>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        <DropdownMenu
          actions={[
            [
              {
                a11yLabel: 'Go to account settings',
                icon: faCog,
                label: 'Account settings',
                to: '/settings/account',
              },
            ],
            [
              {
                a11yLabel: 'Sign out',
                icon: faSignOutAlt,
                label: 'Sign out',
                to: '/sign-out',
              },
            ],
          ]}
          justify="right"
          size="md"
        >
          <Profile>
            <Stack gap={20}>
              <div>
                <Avatar size="sm" src={currentTenantAdmin?.avatarUrl} />
              </div>
              <TextStack spacing="tight">
                <ProfileField>
                  {currentTenantAdmin
                    ? `${currentTenantAdmin.user.firstName} ${currentTenantAdmin.user.lastName}`
                    : '...'}
                </ProfileField>
                <ProfileField light>
                  {currentTenantAdmin ? currentTenantAdmin.user.email : '...'}
                </ProfileField>
              </TextStack>
            </Stack>
            <Icon fixedWidth icon={faChevronDown} />
          </Profile>
        </DropdownMenu>
      </div>
    </Wrapper>
  )
}

export default AppNavbar
