export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A properly formatted email address */
  Email: string;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** An object's URL key */
  Key: string;
  /** Non-fractional, signed, whole numeric values representing 1 meter in the range -(2^31) and 2^31 - 1. */
  Meters: any;
  /** Non-fractional, signed, whole numeric values representing 1 minute of time in the range -(2^31) and 2^31 - 1. */
  Minutes: any;
  /** An Int with a value >= 0 */
  NonNegativeInt: number;
  /** An Int with a value > 0 */
  PositiveInt: number;
  /** A valid IANA timezone name */
  Timezone: string;
  /** A nil value */
  Void: void;
};

/** Account */
export type Account = {
  __typename: 'Account';
  addresses: Array<Address>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  default: Scalars['Boolean'];
  defaultContact: CustomerAdmin;
  id: Scalars['ID'];
  name: Scalars['String'];
  orders: OrderPage;
  phoneNumber: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Account */
export type AccountOrdersArgs = {
  filters: Maybe<OrderFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Autogenerated return type of AccountCreate */
export type AccountCreatePayload = {
  __typename: 'AccountCreatePayload';
  account: Account;
};

/** Autogenerated return type of AccountUpdate */
export type AccountUpdatePayload = {
  __typename: 'AccountUpdatePayload';
  account: Account;
};

/** Address */
export type Address = {
  __typename: 'Address';
  account: Maybe<Account>;
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  coords: Point;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  rateQuote: Maybe<Rate>;
  state: Maybe<Scalars['String']>;
  timezone: Scalars['Timezone'];
  updatedAt: Scalars['ISO8601DateTime'];
  zip: Maybe<Scalars['String']>;
};


/** Address */
export type AddressRateQuoteArgs = {
  skillId: Scalars['ID'];
};

/** Autogenerated return type of AddressCreate */
export type AddressCreatePayload = {
  __typename: 'AddressCreatePayload';
  address: Address;
};

/** Pagination container for Address */
export type AddressPage = {
  __typename: 'AddressPage';
  items: Array<Address>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of AddressUpdate */
export type AddressUpdatePayload = {
  __typename: 'AddressUpdatePayload';
  address: Address;
};

/** Agency */
export type Agency = {
  __typename: 'Agency';
  badges: Array<Badge>;
  candidates: WorkerPage;
  chatUid: Scalars['String'];
  /** Length of time before a shift begins that workers are allowed to check in */
  checkinWindow: Scalars['Minutes'];
  createdAt: Scalars['ISO8601DateTime'];
  customerAdmins: CustomerAdminPage;
  customers: CustomerPage;
  id: Scalars['ID'];
  jobs: JobPage;
  key: Scalars['Key'];
  locationDesc: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  /** Distance to the job location a worker must be within to check in */
  maxCheckinDistance: Scalars['Meters'];
  name: Scalars['String'];
  orders: OrderPage;
  orientationSites: Array<OrientationSite>;
  orientations: Array<Orientation>;
  /** Length of lead time that must be given between placing a job and its first shift */
  requestLeadtime: Scalars['Minutes'];
  serviceAreas: Array<ServiceArea>;
  shiftsByWeek: Array<Shift>;
  skillCategories: Array<SkillCategory>;
  tags: TagPage;
  tenant: Tenant;
  uniforms: Array<Uniform>;
  updatedAt: Scalars['ISO8601DateTime'];
  workers: WorkerPage;
};


/** Agency */
export type AgencyCandidatesArgs = {
  filters: Maybe<CandidateFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};


/** Agency */
export type AgencyCustomerAdminsArgs = {
  filters: Maybe<CustomerAdminFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};


/** Agency */
export type AgencyCustomersArgs = {
  filters: Maybe<CustomerFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};


/** Agency */
export type AgencyJobsArgs = {
  filters: Maybe<JobFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};


/** Agency */
export type AgencyOrdersArgs = {
  filters: Maybe<OrderFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
  reverse?: Maybe<Scalars['Boolean']>;
  sortKey?: Maybe<OrderSortKeyEnum>;
};


/** Agency */
export type AgencyShiftsByWeekArgs = {
  startDate: Scalars['ISO8601Date'];
};


/** Agency */
export type AgencyTagsArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
  query: Maybe<Scalars['String']>;
};


/** Agency */
export type AgencyWorkersArgs = {
  filters: Maybe<WorkerFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Autogenerated return type of AgencyCreate */
export type AgencyCreatePayload = {
  __typename: 'AgencyCreatePayload';
  agency: Agency;
};

/** Autogenerated return type of AgencyUpdate */
export type AgencyUpdatePayload = {
  __typename: 'AgencyUpdatePayload';
  agency: Agency;
};

/** Badge */
export type Badge = {
  __typename: 'Badge';
  category: BadgeCategoryEnum;
  createdAt: Scalars['ISO8601DateTime'];
  description: Maybe<Scalars['String']>;
  iconUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  points: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum BadgeCategoryEnum {
  CERTIFICATION = 'CERTIFICATION',
  EVENT = 'EVENT',
  SKILL = 'SKILL'
}

/** Autogenerated return type of BadgeCreate */
export type BadgeCreatePayload = {
  __typename: 'BadgeCreatePayload';
  badge: Badge;
};

/** Autogenerated return type of BadgeableAddBadge */
export type BadgeableAddBadgePayload = {
  __typename: 'BadgeableAddBadgePayload';
  tags: Array<Badge>;
};

/** Autogenerated return type of BadgeableChangeBadges */
export type BadgeableChangeBadgesPayload = {
  __typename: 'BadgeableChangeBadgesPayload';
  badges: Array<Badge>;
};

export enum BadgeableEnum {
  WORKER = 'WORKER'
}

/** Autogenerated return type of BadgeableRemoveBadge */
export type BadgeableRemoveBadgePayload = {
  __typename: 'BadgeableRemoveBadgePayload';
  badges: Array<Badge>;
};

/** Tests field for equality to a given value */
export type BooleanEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: Scalars['Boolean'];
};

/** Candidate */
export type Candidate = {
  __typename: 'Candidate';
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  agency: Maybe<Agency>;
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  backgroundStatus: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  coords: Maybe<Point>;
  createdAt: Scalars['ISO8601DateTime'];
  deniedAt: Maybe<Scalars['ISO8601DateTime']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  gender: Maybe<Scalars['String']>;
  heardFrom: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: Maybe<Scalars['Int']>;
  lastName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  quizCompletedAt: Maybe<Scalars['ISO8601DateTime']>;
  references: Array<CandidateReference>;
  skill: Maybe<Skill>;
  state: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Maybe<Worker>;
  zip: Maybe<Scalars['String']>;
};

/** Available filter operations for a candidate Worker */
export type CandidateFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  ownsCar?: Maybe<BooleanEqualityFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  status?: Maybe<CandidateStatusEqualityFilterInput>;
};

/** Candidate reference */
export type CandidateReference = {
  __typename: 'CandidateReference';
  candidate: Maybe<Candidate>;
  city: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  duration: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  employerName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum CandidateStatusEnum {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED'
}

/** Tests field for equality to a given CandidateStatus */
export type CandidateStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: CandidateStatusEnum;
};

/** Represents a operator to compare two objects of the same type */
export enum CompareOperatorEnum {
  /** Exactly equal */
  EQ = 'EQ',
  /** Greater-than */
  GT = 'GT',
  /** Greater-than or equal */
  GTEQ = 'GTEQ',
  /** Less-than */
  LT = 'LT',
  /** Less-than or equal */
  LTEQ = 'LTEQ',
  /** Not-equal */
  NOT = 'NOT'
}

/** Customer */
export type Customer = {
  __typename: 'Customer';
  accounts: Array<Account>;
  addresses: Array<Address>;
  admins: Array<CustomerAdmin>;
  agency: Agency;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  logoKey: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orders: OrderPage;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Customer */
export type CustomerOrdersArgs = {
  filters: Maybe<OrderFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Customer admin */
export type CustomerAdmin = {
  __typename: 'CustomerAdmin';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  avatarKey: Maybe<Scalars['String']>;
  avatarUrl: Maybe<Scalars['String']>;
  chatUid: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  devices: Array<Device>;
  id: Scalars['ID'];
  role: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated return type of CustomerAdminChangeAvatar */
export type CustomerAdminChangeAvatarPayload = {
  __typename: 'CustomerAdminChangeAvatarPayload';
  customerAdmin: CustomerAdmin;
};

/** Available filter operations for a CustomerAdmin */
export type CustomerAdminFilterSetInput = {
  acceptedAt?: Maybe<DateTimeCompareFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<IdEqualityFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  tags?: Maybe<IdMembershipFilterInput>;
};

/** Autogenerated return type of CustomerAdminLogin */
export type CustomerAdminLoginPayload = {
  __typename: 'CustomerAdminLoginPayload';
  accessToken: Scalars['String'];
  customerAdmin: CustomerAdmin;
  refreshToken: Scalars['String'];
};

/** Pagination container for CustomerAdmin */
export type CustomerAdminPage = {
  __typename: 'CustomerAdminPage';
  items: Array<CustomerAdmin>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of CustomerAdminUpdateProfile */
export type CustomerAdminUpdateProfilePayload = {
  __typename: 'CustomerAdminUpdateProfilePayload';
  customerAdmin: CustomerAdmin;
};

/** Autogenerated return type of CustomerChangeLogo */
export type CustomerChangeLogoPayload = {
  __typename: 'CustomerChangeLogoPayload';
  customer: Customer;
};

/** Autogenerated return type of CustomerCreate */
export type CustomerCreatePayload = {
  __typename: 'CustomerCreatePayload';
  customer: Customer;
};

/** Available filter operations for a Customer */
export type CustomerFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<DateTimeCompareFilterInput>;
  createdAtRange?: Maybe<DateTimeRangeFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  tags?: Maybe<TaggedFilterInput>;
  updatedAt?: Maybe<DateTimeCompareFilterInput>;
  updatedAtRange?: Maybe<DateTimeRangeFilterInput>;
};

/** Autogenerated return type of CustomerInviteAdmin */
export type CustomerInviteAdminPayload = {
  __typename: 'CustomerInviteAdminPayload';
  customerAdmin: CustomerAdmin;
};

/** Pagination container for Customer */
export type CustomerPage = {
  __typename: 'CustomerPage';
  items: Array<Customer>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of CustomerUpdate */
export type CustomerUpdatePayload = {
  __typename: 'CustomerUpdatePayload';
  customer: Customer;
};

/** Tests an operation against a static value */
export type DateTimeCompareFilterInput = {
  op: CompareOperatorEnum;
  value: Scalars['ISO8601DateTime'];
};

/** Tests inclusion between a given inclusive range */
export type DateTimeRangeFilterInput = {
  from: Scalars['ISO8601DateTime'];
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  to: Scalars['ISO8601DateTime'];
};

/** Defined boundry */
export type DefinedBoundry = {
  __typename: 'DefinedBoundry';
  area: MultiPolygon;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  metadata: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Pagination container for DefinedBoundry */
export type DefinedBoundryPage = {
  __typename: 'DefinedBoundryPage';
  items: Array<DefinedBoundry>;
  pageInfo: PageInfo;
};

/** Device */
export type Device = {
  __typename: 'Device';
  client: Maybe<Scalars['String']>;
  clientVersion: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  expiresAt: Scalars['Int'];
  id: Scalars['ID'];
  ip: Maybe<Scalars['String']>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']>;
  lastIssued: Scalars['String'];
  lastIssuedAt: Scalars['ISO8601DateTime'];
  refreshToken: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  userAgent: Maybe<Scalars['String']>;
};

export enum DurationEnum {
  LESS_THAN_1_MONTH = 'LESS_THAN_1_MONTH',
  LESS_THAN_1_YEAR = 'LESS_THAN_1_YEAR',
  LESS_THAN_3_MONTHS = 'LESS_THAN_3_MONTHS',
  LESS_THAN_6_MONTHS = 'LESS_THAN_6_MONTHS',
  OVER_1_YEAR = 'OVER_1_YEAR'
}


export enum FileTypeEnum {
  AVATAR = 'AVATAR',
  ICON = 'ICON',
  LOGO = 'LOGO'
}

export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** Tests field for equality to a given value */
export type IdEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: Scalars['ID'];
};

/** Tests field for inclusion in a list */
export type IdMembershipFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  value: Array<Scalars['ID']>;
};



/** Tests an operation against a static value */
export type IntCompareFilterInput = {
  op: CompareOperatorEnum;
  value: Scalars['Int'];
};

/** Job */
export type Job = {
  __typename: 'Job';
  account: Account;
  activeJobRequests: Array<JobRequest>;
  activeJobWorkers: Array<JobWorker>;
  address: Address;
  addressInstructions: Maybe<Scalars['String']>;
  agency: Agency;
  autoAcceptRequests: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  cancelledAt: Maybe<Scalars['ISO8601DateTime']>;
  chatGid: Maybe<Scalars['String']>;
  contact: CustomerAdmin;
  contactInstructions: Maybe<Scalars['String']>;
  costRate: Scalars['PositiveInt'];
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  filled: Scalars['Boolean'];
  firstShiftStartAt: Scalars['ISO8601DateTime'];
  hiredWorkersCount: Scalars['NonNegativeInt'];
  id: Scalars['ID'];
  inactiveJobWorkers: Array<JobWorker>;
  instructions: Maybe<Scalars['String']>;
  jobOffers: Array<JobOffer>;
  jobRequests: Array<JobRequest>;
  jobWorkers: Array<JobWorker>;
  lastShiftEndAt: Scalars['ISO8601DateTime'];
  order: Order;
  payRate: Scalars['PositiveInt'];
  postedAt: Maybe<Scalars['ISO8601DateTime']>;
  published: Scalars['Boolean'];
  quantity: Scalars['PositiveInt'];
  rate: Rate;
  shifts: Array<Shift>;
  skill: Skill;
  status: JobStatusEnum;
  tags: Array<Tag>;
  timesheets: Array<Timesheet>;
  uniform: Uniform;
  uniformInstructions: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of JobCancel */
export type JobCancelPayload = {
  __typename: 'JobCancelPayload';
  job: Job;
};

/** Available filter operations for a Job */
export type JobFilterSetInput = {
  accounts?: Maybe<IdMembershipFilterInput>;
  addresses?: Maybe<IdMembershipFilterInput>;
  agencies?: Maybe<IdMembershipFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelledAt?: Maybe<DateTimeCompareFilterInput>;
  contacts?: Maybe<IdMembershipFilterInput>;
  costRate?: Maybe<IntCompareFilterInput>;
  customers?: Maybe<IdMembershipFilterInput>;
  description?: Maybe<TextSearchFilterInput>;
  firstShiftStartAt?: Maybe<DateTimeCompareFilterInput>;
  hiredWorkers?: Maybe<IntCompareFilterInput>;
  instructions?: Maybe<TextSearchFilterInput>;
  lastShiftEndAt?: Maybe<DateTimeCompareFilterInput>;
  orders?: Maybe<IdMembershipFilterInput>;
  payRate?: Maybe<IntCompareFilterInput>;
  posted?: Maybe<Scalars['Boolean']>;
  postedAt?: Maybe<DateTimeCompareFilterInput>;
  quantity?: Maybe<IntCompareFilterInput>;
  rates?: Maybe<IdMembershipFilterInput>;
  skills?: Maybe<IdMembershipFilterInput>;
  surge?: Maybe<BooleanEqualityFilterInput>;
  unfilledWorkers?: Maybe<IntCompareFilterInput>;
  uniforms?: Maybe<IdMembershipFilterInput>;
};

/** Autogenerated return type of JobHireWorker */
export type JobHireWorkerPayload = {
  __typename: 'JobHireWorkerPayload';
  job: Job;
  jobWorker: JobWorker;
};

export type JobInput = {
  addressId: Scalars['ID'];
  addressInstructions?: Maybe<Scalars['String']>;
  autoAcceptRequests?: Maybe<Scalars['Boolean']>;
  contactId: Scalars['ID'];
  contactInstructions?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  payRate?: Maybe<Scalars['PositiveInt']>;
  publishIn?: Maybe<Scalars['Minutes']>;
  quantity: Scalars['PositiveInt'];
  shifts: Array<ShiftInput>;
  skillId: Scalars['ID'];
  uniformId: Scalars['ID'];
  uniformInstructions?: Maybe<Scalars['String']>;
};

/** Job offer */
export type JobOffer = {
  __typename: 'JobOffer';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  costRate: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  payRate: Scalars['Int'];
  rejectedAt: Maybe<Scalars['ISO8601DateTime']>;
  source: Maybe<Scalars['String']>;
  surge: Scalars['Boolean'];
  token: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Autogenerated return type of JobOfferCreate */
export type JobOfferCreatePayload = {
  __typename: 'JobOfferCreatePayload';
  jobOffer: JobOffer;
};

/** Pagination container for Job */
export type JobPage = {
  __typename: 'JobPage';
  items: Array<Job>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of JobPublish */
export type JobPublishPayload = {
  __typename: 'JobPublishPayload';
  job: Job;
};

/** Job request */
export type JobRequest = {
  __typename: 'JobRequest';
  acceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  rejectedAt: Maybe<Scalars['ISO8601DateTime']>;
  status: JobRequestStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Autogenerated return type of JobRequestAccept */
export type JobRequestAcceptPayload = {
  __typename: 'JobRequestAcceptPayload';
  job: Job;
  jobWorker: JobWorker;
};

/** Autogenerated return type of JobRequestReject */
export type JobRequestRejectPayload = {
  __typename: 'JobRequestRejectPayload';
  job: Job;
  jobRequest: JobRequest;
};

export enum JobRequestStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum JobStatusEnum {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING'
}

/** Tests field for equality to a given JobStatus */
export type JobStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: JobStatusEnum;
};

/** Autogenerated return type of JobUnpublish */
export type JobUnpublishPayload = {
  __typename: 'JobUnpublishPayload';
  job: Job;
};

/** Autogenerated return type of JobUpdate */
export type JobUpdatePayload = {
  __typename: 'JobUpdatePayload';
  job: Job;
};

/** Job worker */
export type JobWorker = {
  __typename: 'JobWorker';
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  costRate: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  dismissReason: Maybe<Scalars['String']>;
  dismissedAt: Maybe<Scalars['ISO8601DateTime']>;
  dropReason: Maybe<Scalars['String']>;
  droppedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  job: Job;
  order: Order;
  payRate: Scalars['Int'];
  status: JobWorkerStatusEnum;
  timesheets: Array<Timesheet>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Available filter operations for a JobWorker */
export type JobWorkerFilterSetInput = {
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  approved?: Maybe<Scalars['Boolean']>;
  breakMinutes?: Maybe<IntCompareFilterInput>;
  checkinAt?: Maybe<DateTimeCompareFilterInput>;
  checkoutAt?: Maybe<DateTimeCompareFilterInput>;
  costRate?: Maybe<IntCompareFilterInput>;
  dismissed?: Maybe<Scalars['Boolean']>;
  dropped?: Maybe<Scalars['Boolean']>;
  firstShiftStartAt?: Maybe<DateTimeCompareFilterInput>;
  jobStatus?: Maybe<JobStatusEqualityFilterInput>;
  lastShiftEndAt?: Maybe<DateTimeCompareFilterInput>;
  minutes?: Maybe<IntCompareFilterInput>;
  overtimeMinutes?: Maybe<IntCompareFilterInput>;
  payRate?: Maybe<IntCompareFilterInput>;
  rating?: Maybe<IntCompareFilterInput>;
  reportedEndAt?: Maybe<DateTimeCompareFilterInput>;
  reportedStartAt?: Maybe<DateTimeCompareFilterInput>;
  status?: Maybe<JobWorkerStatusEqualityFilterInput>;
  surge?: Maybe<BooleanEqualityFilterInput>;
  tipAmount?: Maybe<IntCompareFilterInput>;
};

/** Pagination container for JobWorker */
export type JobWorkerPage = {
  __typename: 'JobWorkerPage';
  items: Array<JobWorker>;
  pageInfo: PageInfo;
};

export enum JobWorkerStatusEnum {
  DISMISSED = 'DISMISSED',
  DROPPED = 'DROPPED',
  HIRED = 'HIRED'
}

/** Tests field for equality to a given JobStatus */
export type JobWorkerStatusEqualityFilterInput = {
  /** Treats operation as inequality as opposed to equality */
  inverse?: Maybe<Scalars['Boolean']>;
  value: JobWorkerStatusEnum;
};


export enum LanguageEnum {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH'
}



/** A spatial multi-polygon */
export type MultiPolygon = {
  __typename: 'MultiPolygon';
  polygons: Array<Polygon>;
};

export type MultiPolygonInput = {
  polygons: Array<PolygonInput>;
};

/** The mutation root of this schema */
export type Mutation = {
  __typename: 'Mutation';
  /** Create an account */
  accountCreate: AccountCreatePayload;
  /** Update an account */
  accountUpdate: AccountUpdatePayload;
  /** Create an address */
  addressCreate: AddressCreatePayload;
  /** Update an address */
  addressUpdate: AddressUpdatePayload;
  /** Create an agency */
  agencyCreate: AgencyCreatePayload;
  /** Update an agency */
  agencyUpdate: AgencyUpdatePayload;
  /** Create a new badge */
  badgeCreate: BadgeCreatePayload;
  /** Add a badge to a badgeable model */
  badgeableAddBadge: BadgeableAddBadgePayload;
  /** Change the badges on a badgeable model */
  badgeableChangeBadges: BadgeableChangeBadgesPayload;
  /** Remove a badge from a badgeable model */
  badgeableRemoveBadge: BadgeableRemoveBadgePayload;
  /** Create a chat service auth token for agency support */
  chatServiceAuthenticateAgency: Scalars['String'];
  /** Change a customer admin's avatar */
  customerAdminChangeAvatar: CustomerAdminChangeAvatarPayload;
  /** Authenticate a customer admin */
  customerAdminLogin: CustomerAdminLoginPayload;
  /** Update a customer admin's profile */
  customerAdminUpdateProfile: CustomerAdminUpdateProfilePayload;
  /** Change a customer's avatar */
  customerChangeLogo: CustomerChangeLogoPayload;
  /** Create a customer */
  customerCreate: CustomerCreatePayload;
  /** Invite a customer admin */
  customerInviteAdmin: CustomerInviteAdminPayload;
  /** Update a customer */
  customerUpdate: CustomerUpdatePayload;
  /** Log out of an active session */
  deviceLogout: Maybe<Scalars['Void']>;
  /** Cancel job */
  jobCancel: JobCancelPayload;
  /** Dismiss a worker from a job */
  jobDismissWorker: Maybe<Scalars['Void']>;
  /** Hire a worker for a job */
  jobHireWorker: JobHireWorkerPayload;
  /** Create a job offer */
  jobOfferCreate: JobOfferCreatePayload;
  /** Post job to workers */
  jobPublish: JobPublishPayload;
  /** Accept a job request */
  jobRequestAccept: JobRequestAcceptPayload;
  /** Reject a job request */
  jobRequestReject: JobRequestRejectPayload;
  /** Remove job from workers board */
  jobUnpublish: JobUnpublishPayload;
  /** Update job details */
  jobUpdate: JobUpdatePayload;
  /** Create an order */
  orderCreate: OrderCreatePayload;
  /** Create a rate */
  rateCreate: RateCreatePayload;
  /** Create a referral */
  referralCreate: ReferralCreatePayload;
  /** Create a service area */
  serviceAreaCreate: ServiceAreaCreatePayload;
  /** Update a service area */
  serviceAreaUpdate: ServiceAreaUpdatePayload;
  /** Create a skill category */
  skillCategoryCreate: SkillCategoryCreatePayload;
  /** Update a skill category */
  skillCategoryUpdate: SkillCategoryUpdatePayload;
  /** Create a skill */
  skillCreate: SkillCreatePayload;
  /** Accept a skill request */
  skillRequestAccept: SkillRequestAcceptPayload;
  /** Reject a skill request */
  skillRequestReject: SkillRequestRejectPayload;
  /** Update a skill */
  skillUpdate: SkillUpdatePayload;
  /** Create a new tag */
  tagCreate: TagCreatePayload;
  /** Add a tag to a taggable model */
  taggableAddTag: TaggableAddTagPayload;
  /** Change the tags on a taggable model */
  taggableChangeTags: TaggableChangeTagsPayload;
  /** Remove a tag from a taggable model */
  taggableRemoveTag: TaggableRemoveTagPayload;
  /** Change a tenant admin's avatar */
  tenantAdminChangeAvatar: TenantAdminChangeAvatarPayload;
  /** Authenticate a tenant admin */
  tenantAdminLogin: TenantAdminLoginPayload;
  /** Update a tenant */
  tenantUpdate: TenantUpdatePayload;
  /** Change current user password */
  userChangePassword: Maybe<Scalars['Void']>;
  /** Allow a user matching the given email to reset their password */
  userForgotPassword: Maybe<Scalars['Void']>;
  /** Reset password of user matching the given token */
  userResetPassword: Maybe<Scalars['Void']>;
  /** Create a worker reference */
  workerAddReference: WorkerAddReferencePayload;
  /** Add a skill to a worker */
  workerAddSkill: WorkerAddSkillPayload;
  /** Change a worker's address */
  workerChangeAddress: WorkerChangeAddressPayload;
  /** Change a worker's avatar */
  workerChangeAvatar: WorkerChangeAvatarPayload;
  /** Authenticate a worker */
  workerLogin: WorkerLoginPayload;
  /** Remove a worker reference */
  workerRemoveReference: Maybe<Scalars['Void']>;
  /** Remove a skill from a worker */
  workerRemoveSkill: WorkerRemoveSkillPayload;
  /** Worker self signup */
  workerSignup: Maybe<Scalars['Void']>;
  /** Update a worker's profile */
  workerUpdateProfile: WorkerUpdateProfilePayload;
  /** Verify worker mobile code */
  workerVerify: WorkerVerifyPayload;
};


/** The mutation root of this schema */
export type MutationAccountCreateArgs = {
  customerId: Scalars['ID'];
  defaultContactId: Scalars['ID'];
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationAccountUpdateArgs = {
  accountId: Scalars['ID'];
  defaultContactId: Scalars['ID'];
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationAddressCreateArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  coords: PointInput;
  customerId: Scalars['ID'];
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationAddressUpdateArgs = {
  addressId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  coords: PointInput;
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationAgencyCreateArgs = {
  key: Scalars['Key'];
  name: Scalars['String'];
  tenantId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationAgencyUpdateArgs = {
  agencyId: Scalars['ID'];
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationBadgeCreateArgs = {
  agencyId: Scalars['ID'];
  category: Maybe<BadgeCategoryEnum>;
  description: Maybe<Scalars['String']>;
  name: Scalars['String'];
  points: Maybe<Scalars['Int']>;
};


/** The mutation root of this schema */
export type MutationBadgeableAddBadgeArgs = {
  badgeableId: Scalars['ID'];
  badgeableType: BadgeableEnum;
  tagId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationBadgeableChangeBadgesArgs = {
  addBadgeIds: Maybe<Array<Scalars['PositiveInt']>>;
  badgeableId: Scalars['ID'];
  badgeableType: BadgeableEnum;
  removeBadgeIds: Maybe<Array<Scalars['PositiveInt']>>;
};


/** The mutation root of this schema */
export type MutationBadgeableRemoveBadgeArgs = {
  badgeId: Scalars['ID'];
  badgeableId: Scalars['ID'];
  badgeableType: BadgeableEnum;
};


/** The mutation root of this schema */
export type MutationChatServiceAuthenticateAgencyArgs = {
  agencyId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationCustomerAdminChangeAvatarArgs = {
  avatarKey: Scalars['String'];
  customerAdminId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationCustomerAdminLoginArgs = {
  customerId: Scalars['ID'];
  email: Scalars['Email'];
  fcmToken: Maybe<Scalars['String']>;
  password: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationCustomerAdminUpdateProfileArgs = {
  customerAdminId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationCustomerChangeLogoArgs = {
  customerId: Scalars['ID'];
  logoKey: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationCustomerCreateArgs = {
  agencyId: Scalars['ID'];
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationCustomerInviteAdminArgs = {
  customerId: Scalars['ID'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationCustomerUpdateArgs = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationJobCancelArgs = {
  jobId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationJobDismissWorkerArgs = {
  jobWorkerId: Scalars['ID'];
  reason: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationJobHireWorkerArgs = {
  jobId: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationJobOfferCreateArgs = {
  costRate: Maybe<Scalars['PositiveInt']>;
  jobId: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationJobPublishArgs = {
  jobId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationJobRequestAcceptArgs = {
  jobRequestId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationJobRequestRejectArgs = {
  jobRequestId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationJobUnpublishArgs = {
  jobId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationJobUpdateArgs = {
  addressInstructions: Scalars['String'];
  contactId: Scalars['ID'];
  contactInstructions: Scalars['String'];
  instructions: Scalars['String'];
  jobId: Scalars['ID'];
  uniformId: Scalars['ID'];
  uniformInstructions: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationOrderCreateArgs = {
  accountId: Scalars['ID'];
  jobs: Array<JobInput>;
};


/** The mutation root of this schema */
export type MutationRateCreateArgs = {
  agencyId: Scalars['ID'];
  baseCost: Scalars['PositiveInt'];
  basePay: Scalars['PositiveInt'];
  level: Scalars['PositiveInt'];
  serviceAreaId: Scalars['ID'];
  skillId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationReferralCreateArgs = {
  prospectEmail: Scalars['String'];
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationServiceAreaCreateArgs = {
  agencyId: Scalars['ID'];
  geometry: MultiPolygonInput;
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationServiceAreaUpdateArgs = {
  name: Scalars['String'];
  serviceAreaId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationSkillCategoryCreateArgs = {
  agencyId: Scalars['ID'];
  iconUrl: Scalars['String'];
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationSkillCategoryUpdateArgs = {
  name: Scalars['String'];
  skillCategoryId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationSkillCreateArgs = {
  iconUrl: Scalars['String'];
  name: Scalars['String'];
  skillCategoryId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationSkillRequestAcceptArgs = {
  skillRequestId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationSkillRequestRejectArgs = {
  skillRequestId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationSkillUpdateArgs = {
  name: Scalars['String'];
  skillId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationTagCreateArgs = {
  agencyId: Scalars['ID'];
  name: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationTaggableAddTagArgs = {
  tagId: Scalars['ID'];
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
};


/** The mutation root of this schema */
export type MutationTaggableChangeTagsArgs = {
  addTagIds: Maybe<Array<Scalars['PositiveInt']>>;
  removeTagIds: Maybe<Array<Scalars['PositiveInt']>>;
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
};


/** The mutation root of this schema */
export type MutationTaggableRemoveTagArgs = {
  tagId: Scalars['ID'];
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
};


/** The mutation root of this schema */
export type MutationTenantAdminChangeAvatarArgs = {
  avatarKey: Scalars['String'];
  tenantAdminId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationTenantAdminLoginArgs = {
  email: Scalars['Email'];
  fcmToken: Maybe<Scalars['String']>;
  password: Scalars['String'];
  tenantId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationTenantUpdateArgs = {
  name: Scalars['String'];
  tenantId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationUserChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationUserForgotPasswordArgs = {
  email: Scalars['Email'];
};


/** The mutation root of this schema */
export type MutationUserResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationWorkerAddReferenceArgs = {
  city: Scalars['String'];
  duration: DurationEnum;
  email: Maybe<Scalars['Email']>;
  employerName: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  title: Scalars['String'];
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationWorkerAddSkillArgs = {
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationWorkerChangeAddressArgs = {
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  coords: PointInput;
  state: Scalars['String'];
  workerId: Scalars['ID'];
  zip: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationWorkerChangeAvatarArgs = {
  avatarKey: Scalars['String'];
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationWorkerLoginArgs = {
  agencyId: Scalars['ID'];
  email: Scalars['Email'];
  fcmToken: Maybe<Scalars['String']>;
  password: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationWorkerRemoveReferenceArgs = {
  workerReferenceId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationWorkerRemoveSkillArgs = {
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationWorkerSignupArgs = {
  agencyId: Scalars['ID'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  language: LanguageEnum;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};


/** The mutation root of this schema */
export type MutationWorkerUpdateProfileArgs = {
  firstName: Scalars['String'];
  gender: GenderEnum;
  lastName: Scalars['String'];
  workerId: Scalars['ID'];
};


/** The mutation root of this schema */
export type MutationWorkerVerifyArgs = {
  code: Scalars['String'];
  fcmToken: Maybe<Scalars['String']>;
};


/** Order */
export type Order = {
  __typename: 'Order';
  account: Account;
  agency: Agency;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  jobs: Array<Job>;
  jobsCount: Scalars['PositiveInt'];
  startAt: Scalars['ISO8601DateTime'];
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
  uuid: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OrderCreate */
export type OrderCreatePayload = {
  __typename: 'OrderCreatePayload';
  order: Order;
};

/** Available filter operations for a Order */
export type OrderFilterSetInput = {
  account?: Maybe<IdEqualityFilterInput>;
  agency?: Maybe<IdEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<IdEqualityFilterInput>;
  endAt?: Maybe<DateTimeCompareFilterInput>;
  jobsCount?: Maybe<IntCompareFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  startAt?: Maybe<DateTimeCompareFilterInput>;
  tags?: Maybe<IdMembershipFilterInput>;
  uuid?: Maybe<TextSearchFilterInput>;
};

/** Pagination container for Order */
export type OrderPage = {
  __typename: 'OrderPage';
  items: Array<Order>;
  pageInfo: PageInfo;
};

export enum OrderSortKeyEnum {
  CREATED_AT = 'CREATED_AT',
  END_AT = 'END_AT',
  ID = 'ID',
  JOBS_COUNT = 'JOBS_COUNT',
  START_AT = 'START_AT'
}

/** Orientation */
export type Orientation = {
  __typename: 'Orientation';
  agency: Maybe<Agency>;
  candidates: Array<Candidate>;
  createdAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  seats: Scalars['Int'];
  seatsRemaining: Scalars['Int'];
  site: Maybe<OrientationSite>;
  startAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Orientation site */
export type OrientationSite = {
  __typename: 'OrientationSite';
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  agency: Maybe<Agency>;
  city: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  maxSeats: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  orientations: Array<Orientation>;
  state: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  zip: Maybe<Scalars['String']>;
};

/** Page metadata */
export type PageInfo = {
  __typename: 'PageInfo';
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** A lat/long point */
export type Point = {
  __typename: 'Point';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** A spatial polygon */
export type Polygon = {
  __typename: 'Polygon';
  rings: Array<PolygonRing>;
};

export type PolygonInput = {
  rings: Array<PolygonRingInput>;
};

/** A ring of lat/long points */
export type PolygonRing = {
  __typename: 'PolygonRing';
  points: Array<Point>;
};

export type PolygonRingInput = {
  points: Array<PointInput>;
};


/** The query root of this schema */
export type Query = {
  __typename: 'Query';
  /** Get account */
  account: Account;
  /** Get address */
  address: Address;
  /** List addresses */
  addresses: AddressPage;
  /** List agencies */
  agencies: Array<Agency>;
  /** Get agency */
  agency: Agency;
  /** Get agency by key */
  agencyByKey: Agency;
  /** Get candidate */
  candidate: Candidate;
  /** Get candidate by token */
  candidateByToken: Candidate;
  /** Get current device */
  currentDevice: Device;
  /** Get current tenant admin */
  currentTenantAdmin: TenantAdmin;
  /** Get customer */
  customer: Customer;
  /** Get customer admin */
  customerAdmin: CustomerAdmin;
  /** Get customer invite */
  customerInvite: Maybe<CustomerAdmin>;
  /** List pre-defined boundries to build sevice areas */
  definedBoundries: DefinedBoundryPage;
  /** List eligible workers for a job */
  eligibleWorkers: WorkerPage;
  /** Get job */
  job: Job;
  /** Get job worker */
  jobWorker: JobWorker;
  /** Get order */
  order: Order;
  /** Get rate */
  rate: Rate;
  /** Get rate quote */
  rateQuote: Maybe<Rate>;
  /** Get service area */
  serviceArea: ServiceArea;
  /** Get skill */
  skill: Skill;
  /** Get skill category */
  skillCategory: SkillCategory;
  /** Get tenant */
  tenant: Tenant;
  /** Get tenant by key */
  tenantByKey: Maybe<Tenant>;
  /** Get timesheet */
  timesheet: Timesheet;
  /** Get a URL to upload files to */
  uploadUrl: UploadUrl;
  /** Get worker */
  worker: Worker;
  /** List workers */
  workers: WorkerPage;
};


/** The query root of this schema */
export type QueryAccountArgs = {
  accountId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAddressArgs = {
  addressId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAgencyArgs = {
  agencyId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAgencyByKeyArgs = {
  key: Scalars['Key'];
  tenantId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCandidateArgs = {
  candidateId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCandidateByTokenArgs = {
  token: Scalars['String'];
};


/** The query root of this schema */
export type QueryCustomerArgs = {
  customerId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCustomerAdminArgs = {
  customerAdminId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCustomerInviteArgs = {
  inviteCode: Scalars['String'];
};


/** The query root of this schema */
export type QueryDefinedBoundriesArgs = {
  query: Maybe<Scalars['String']>;
  within: Maybe<PolygonInput>;
};


/** The query root of this schema */
export type QueryEligibleWorkersArgs = {
  jobId: Scalars['ID'];
  search: Maybe<Scalars['String']>;
};


/** The query root of this schema */
export type QueryJobArgs = {
  jobId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryJobWorkerArgs = {
  jobWorkerId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryRateArgs = {
  rateId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryRateQuoteArgs = {
  accountId: Scalars['ID'];
  point: PointInput;
  skillId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryServiceAreaArgs = {
  serviceAreaId: Scalars['ID'];
};


/** The query root of this schema */
export type QuerySkillArgs = {
  skillId: Scalars['ID'];
};


/** The query root of this schema */
export type QuerySkillCategoryArgs = {
  skillCategoryId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryTenantArgs = {
  tenantId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryTenantByKeyArgs = {
  key: Scalars['Key'];
};


/** The query root of this schema */
export type QueryTimesheetArgs = {
  timesheetId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryUploadUrlArgs = {
  fileType: FileTypeEnum;
};


/** The query root of this schema */
export type QueryWorkerArgs = {
  workerId: Scalars['ID'];
};


/** The query root of this schema */
export type QueryWorkersArgs = {
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Rate */
export type Rate = {
  __typename: 'Rate';
  account: Maybe<Account>;
  active: Maybe<Scalars['Boolean']>;
  agency: Maybe<Agency>;
  baseCost: Scalars['Int'];
  basePay: Scalars['Int'];
  cost: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  level: Maybe<Scalars['Int']>;
  overtimeFactor: Maybe<Scalars['Int']>;
  pay: Scalars['Int'];
  serviceArea: ServiceArea;
  skill: Maybe<Skill>;
  surgeCost: Maybe<Scalars['Int']>;
  surgePay: Maybe<Scalars['Int']>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Rate */
export type RateCostArgs = {
  overtime: Maybe<Scalars['Boolean']>;
  surge: Maybe<Scalars['Boolean']>;
};


/** Rate */
export type RatePayArgs = {
  overtime: Maybe<Scalars['Boolean']>;
  surge: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RateCreate */
export type RateCreatePayload = {
  __typename: 'RateCreatePayload';
  rate: Rate;
};

/** Referral */
export type Referral = {
  __typename: 'Referral';
  clickedAt: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  onboardedAt: Maybe<Scalars['ISO8601DateTime']>;
  prospectEmail: Scalars['String'];
  sentAt: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Maybe<Worker>;
};

/** Autogenerated return type of ReferralCreate */
export type ReferralCreatePayload = {
  __typename: 'ReferralCreatePayload';
  referral: Referral;
};

/** Service area */
export type ServiceArea = {
  __typename: 'ServiceArea';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  geometry: MultiPolygon;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  rates: Array<Rate>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of ServiceAreaCreate */
export type ServiceAreaCreatePayload = {
  __typename: 'ServiceAreaCreatePayload';
  serviceArea: ServiceArea;
};

/** Autogenerated return type of ServiceAreaUpdate */
export type ServiceAreaUpdatePayload = {
  __typename: 'ServiceAreaUpdatePayload';
  serviceArea: ServiceArea;
};

/** Shift */
export type Shift = {
  __typename: 'Shift';
  createdAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  startAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ShiftInput = {
  endAt: Scalars['ISO8601DateTime'];
  startAt: Scalars['ISO8601DateTime'];
};

/** Skill */
export type Skill = {
  __typename: 'Skill';
  agency: Agency;
  category: Maybe<SkillCategory>;
  createdAt: Scalars['ISO8601DateTime'];
  descriptionEn: Maybe<Scalars['String']>;
  descriptionEs: Maybe<Scalars['String']>;
  displayNameEn: Maybe<Scalars['String']>;
  displayNameEs: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Skill category */
export type SkillCategory = {
  __typename: 'SkillCategory';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  descriptionEn: Maybe<Scalars['String']>;
  descriptionEs: Maybe<Scalars['String']>;
  displayNameEn: Maybe<Scalars['String']>;
  displayNameEs: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  skills: Array<Skill>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of SkillCategoryCreate */
export type SkillCategoryCreatePayload = {
  __typename: 'SkillCategoryCreatePayload';
  skillCategory: SkillCategory;
};

/** Autogenerated return type of SkillCategoryUpdate */
export type SkillCategoryUpdatePayload = {
  __typename: 'SkillCategoryUpdatePayload';
  skillCategory: SkillCategory;
};

/** Autogenerated return type of SkillCreate */
export type SkillCreatePayload = {
  __typename: 'SkillCreatePayload';
  skill: Skill;
};

/** Skill request */
export type SkillRequest = {
  __typename: 'SkillRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  skill: Skill;
  status: SkillRequestStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Worker;
};

/** Autogenerated return type of SkillRequestAccept */
export type SkillRequestAcceptPayload = {
  __typename: 'SkillRequestAcceptPayload';
  skillRequest: SkillRequest;
};

/** Autogenerated return type of SkillRequestReject */
export type SkillRequestRejectPayload = {
  __typename: 'SkillRequestRejectPayload';
  skillRequest: SkillRequest;
};

export enum SkillRequestStatusEnum {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

/** Autogenerated return type of SkillUpdate */
export type SkillUpdatePayload = {
  __typename: 'SkillUpdatePayload';
  skill: Skill;
};

/** Tag */
export type Tag = {
  __typename: 'Tag';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of TagCreate */
export type TagCreatePayload = {
  __typename: 'TagCreatePayload';
  tag: Tag;
};

/** Pagination container for Tag */
export type TagPage = {
  __typename: 'TagPage';
  items: Array<Tag>;
  pageInfo: PageInfo;
};

/** Autogenerated return type of TaggableAddTag */
export type TaggableAddTagPayload = {
  __typename: 'TaggableAddTagPayload';
  tags: Array<Tag>;
};

/** Autogenerated return type of TaggableChangeTags */
export type TaggableChangeTagsPayload = {
  __typename: 'TaggableChangeTagsPayload';
  tags: Array<Tag>;
};

export enum TaggableEnum {
  ACCOUNT = 'ACCOUNT',
  CUSTOMER = 'CUSTOMER',
  JOB = 'JOB',
  ORDER = 'ORDER',
  RATE = 'RATE',
  SERVICE_AREA = 'SERVICE_AREA',
  WORKER = 'WORKER'
}

/** Autogenerated return type of TaggableRemoveTag */
export type TaggableRemoveTagPayload = {
  __typename: 'TaggableRemoveTagPayload';
  tags: Array<Tag>;
};

/** Tests model for inclusion of ALL given tags */
export type TaggedFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  tagIds: Array<Scalars['ID']>;
};

/** A tenant */
export type Tenant = {
  __typename: 'Tenant';
  admins: Array<TenantAdmin>;
  agencies: Array<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  key: Scalars['Key'];
  locationDesc: Maybe<Scalars['String']>;
  logoUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Tenant admin */
export type TenantAdmin = {
  __typename: 'TenantAdmin';
  avatarKey: Maybe<Scalars['String']>;
  avatarUrl: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  role: Scalars['Int'];
  tenant: Tenant;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** Autogenerated return type of TenantAdminChangeAvatar */
export type TenantAdminChangeAvatarPayload = {
  __typename: 'TenantAdminChangeAvatarPayload';
  tenantAdmin: TenantAdmin;
};

/** Autogenerated return type of TenantAdminLogin */
export type TenantAdminLoginPayload = {
  __typename: 'TenantAdminLoginPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  tenantAdmin: TenantAdmin;
};

/** Autogenerated return type of TenantUpdate */
export type TenantUpdatePayload = {
  __typename: 'TenantUpdatePayload';
  tenant: Tenant;
};

/** Tests field for inclusion of a given substring */
export type TextSearchFilterInput = {
  /** Treats operation as exclusion as opposed to inclusion */
  inverse?: Maybe<Scalars['Boolean']>;
  /** Sets the testing mode */
  mode?: Maybe<TextSearchModeEnum>;
  value: Scalars['String'];
};

/** Represents a mode of searching text for a substring */
export enum TextSearchModeEnum {
  /** String contains */
  CONTAINS = 'CONTAINS',
  /** String starts with */
  PREFIX = 'PREFIX',
  /** String ends with */
  SUFFIX = 'SUFFIX'
}

/** Timesheet */
export type Timesheet = {
  __typename: 'Timesheet';
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  breakMinutes: Scalars['Minutes'];
  checkinAt: Maybe<Scalars['ISO8601DateTime']>;
  checkinCoords: Maybe<Point>;
  checkoutAt: Maybe<Scalars['ISO8601DateTime']>;
  checkoutCoords: Maybe<Point>;
  costRate: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  job: Job;
  jobWorker: JobWorker;
  minutes: Scalars['Minutes'];
  payRate: Scalars['Int'];
  rating: Maybe<Scalars['Int']>;
  ratingComment: Maybe<Scalars['String']>;
  reportComment: Maybe<Scalars['String']>;
  reportedCheckinAt: Maybe<Scalars['ISO8601DateTime']>;
  reportedCheckoutAt: Maybe<Scalars['ISO8601DateTime']>;
  shift: Shift;
  tipAmount: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Uniform */
export type Uniform = {
  __typename: 'Uniform';
  agency: Maybe<Agency>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Upload URL */
export type UploadUrl = {
  __typename: 'UploadURL';
  key: Scalars['String'];
  url: Scalars['String'];
};

/** A user */
export type User = {
  __typename: 'User';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  language: LanguageEnum;
  lastName: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  secondaryLanguage: Maybe<LanguageEnum>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Worker */
export type Worker = {
  __typename: 'Worker';
  active: Maybe<Scalars['Boolean']>;
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  agency: Agency;
  approvedAt: Maybe<Scalars['ISO8601DateTime']>;
  avatarKey: Maybe<Scalars['String']>;
  avatarUrl: Maybe<Scalars['String']>;
  backgroundStatus: Maybe<Scalars['String']>;
  badges: Array<Badge>;
  candidateStatus: CandidateStatusEnum;
  candidateStatusUpdatedAt: Maybe<Scalars['ISO8601DateTime']>;
  chatUid: Scalars['String'];
  city: Scalars['String'];
  coords: Point;
  createdAt: Scalars['ISO8601DateTime'];
  deniedAt: Maybe<Scalars['ISO8601DateTime']>;
  gender: GenderEnum;
  id: Scalars['ID'];
  jobWorkers: JobWorkerPage;
  jobsDropped: Scalars['NonNegativeInt'];
  jobsFailed: Scalars['NonNegativeInt'];
  jobsWorked: Scalars['NonNegativeInt'];
  ownsCar: Scalars['Boolean'];
  pendingJobOffers: Array<JobOffer>;
  pendingJobRequests: Array<JobRequest>;
  references: Array<WorkerReference>;
  referrals: Array<Referral>;
  skillRequests: Array<SkillRequest>;
  skills: Array<Skill>;
  state: Scalars['String'];
  status: WorkerStatusEnum;
  tags: Array<Tag>;
  tcAcceptedAt: Maybe<Scalars['ISO8601DateTime']>;
  timezone: Scalars['Timezone'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  verifiedAt: Maybe<Scalars['ISO8601DateTime']>;
  zip: Scalars['String'];
};


/** Worker */
export type WorkerJobWorkersArgs = {
  filters: Maybe<JobWorkerFilterSetInput>;
  page?: Maybe<Scalars['PositiveInt']>;
  perPage?: Maybe<Scalars['PositiveInt']>;
};

/** Autogenerated return type of WorkerAddReference */
export type WorkerAddReferencePayload = {
  __typename: 'WorkerAddReferencePayload';
  reference: WorkerReference;
};

/** Autogenerated return type of WorkerAddSkill */
export type WorkerAddSkillPayload = {
  __typename: 'WorkerAddSkillPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeAddress */
export type WorkerChangeAddressPayload = {
  __typename: 'WorkerChangeAddressPayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerChangeAvatar */
export type WorkerChangeAvatarPayload = {
  __typename: 'WorkerChangeAvatarPayload';
  worker: Worker;
};

/** Available filter operations for a hired Worker */
export type WorkerFilterSetInput = {
  active?: Maybe<BooleanEqualityFilterInput>;
  /** Combines all filters disjunctively (logical OR) */
  applyDisjunctively?: Maybe<Scalars['Boolean']>;
  chatUid?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  jobsDropped?: Maybe<IntCompareFilterInput>;
  jobsFailed?: Maybe<IntCompareFilterInput>;
  jobsWorked?: Maybe<IntCompareFilterInput>;
  ownsCar?: Maybe<BooleanEqualityFilterInput>;
  query?: Maybe<TextSearchFilterInput>;
  tcAcceptedAt?: Maybe<DateTimeCompareFilterInput>;
};

/** Autogenerated return type of WorkerLogin */
export type WorkerLoginPayload = {
  __typename: 'WorkerLoginPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  worker: Worker;
};

/** Pagination container for Worker */
export type WorkerPage = {
  __typename: 'WorkerPage';
  items: Array<Worker>;
  pageInfo: PageInfo;
};

/** Worker reference */
export type WorkerReference = {
  __typename: 'WorkerReference';
  city: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  duration: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  employerName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  worker: Maybe<Worker>;
};

/** Autogenerated return type of WorkerRemoveSkill */
export type WorkerRemoveSkillPayload = {
  __typename: 'WorkerRemoveSkillPayload';
  worker: Worker;
};

export enum WorkerStatusEnum {
  CANDIDATE = 'CANDIDATE',
  HIRED = 'HIRED'
}

/** Autogenerated return type of WorkerUpdateProfile */
export type WorkerUpdateProfilePayload = {
  __typename: 'WorkerUpdateProfilePayload';
  worker: Worker;
};

/** Autogenerated return type of WorkerVerify */
export type WorkerVerifyPayload = {
  __typename: 'WorkerVerifyPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  worker: Worker;
};

export type CurrentAgencyFragment = (
  { __typename: 'Agency' }
  & Pick<Agency, 'id' | 'key' | 'name' | 'checkinWindow' | 'maxCheckinDistance'>
);

export type CurrentTenantAdminFragment = (
  { __typename: 'TenantAdmin' }
  & Pick<TenantAdmin, 'id' | 'avatarUrl'>
  & { tenant: (
    { __typename: 'Tenant' }
    & Pick<Tenant, 'id' | 'key' | 'name' | 'logoUrl'>
  ), user: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'language' | 'lastName'>
  ) }
);

export type CurrentTenantFragment = (
  { __typename: 'Tenant' }
  & Pick<Tenant, 'id' | 'key' | 'name' | 'logoUrl'>
);

export type ListWorkersForAutocompleteQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<WorkerFilterSetInput>;
}>;


export type ListWorkersForAutocompleteQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { workers: (
      { __typename: 'WorkerPage' }
      & { items: Array<(
        { __typename: 'Worker' }
        & Pick<Worker, 'id' | 'avatarUrl'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        ) }
      )> }
    ) }
  ) }
);

export type SimpleTagFragment = (
  { __typename: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type AddTaggableTagMutationVariables = Exact<{
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
  tagId: Scalars['ID'];
}>;


export type AddTaggableTagMutation = (
  { __typename: 'Mutation' }
  & { taggableAddTag: (
    { __typename: 'TaggableAddTagPayload' }
    & { tags: Array<(
      { __typename: 'Tag' }
      & SimpleTagFragment
    )> }
  ) }
);

export type CreateAgencyTagMutationVariables = Exact<{
  agencyId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateAgencyTagMutation = (
  { __typename: 'Mutation' }
  & { tagCreate: (
    { __typename: 'TagCreatePayload' }
    & { tag: (
      { __typename: 'Tag' }
      & SimpleTagFragment
    ) }
  ) }
);

export type ListAgencyTagsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type ListAgencyTagsQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { tags: (
      { __typename: 'TagPage' }
      & { items: Array<(
        { __typename: 'Tag' }
        & SimpleTagFragment
      )> }
    ) }
  ) }
);

export type RemoveTaggableTagMutationVariables = Exact<{
  taggableId: Scalars['ID'];
  taggableType: TaggableEnum;
  tagId: Scalars['ID'];
}>;


export type RemoveTaggableTagMutation = (
  { __typename: 'Mutation' }
  & { taggableRemoveTag: (
    { __typename: 'TaggableRemoveTagPayload' }
    & { tags: Array<(
      { __typename: 'Tag' }
      & SimpleTagFragment
    )> }
  ) }
);

export type ListAgenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAgenciesQuery = (
  { __typename: 'Query' }
  & { agencies: Array<(
    { __typename: 'Agency' }
    & CurrentAgencyFragment
  )> }
);

export type AuthenticateChatServiceMutationVariables = Exact<{
  agencyId: Scalars['ID'];
}>;


export type AuthenticateChatServiceMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'chatServiceAuthenticateAgency'>
);

export type WorkerSkillFragment = (
  { __typename: 'Skill' }
  & Pick<Skill, 'id' | 'imageUrl' | 'name'>
);

export type WorkerSkillRequestFragment = (
  { __typename: 'SkillRequest' }
  & Pick<SkillRequest, 'id' | 'status'>
  & { skill: (
    { __typename: 'Skill' }
    & WorkerSkillFragment
  ) }
);

export type GetCandidateQueryVariables = Exact<{
  workerId: Scalars['ID'];
}>;


export type GetCandidateQuery = (
  { __typename: 'Query' }
  & { worker: (
    { __typename: 'Worker' }
    & Pick<Worker, 'id' | 'active' | 'addressLine1' | 'addressLine2' | 'avatarUrl' | 'candidateStatus' | 'city' | 'gender' | 'state' | 'status' | 'zip'>
    & { coords: (
      { __typename: 'Point' }
      & Pick<Point, 'latitude' | 'longitude'>
    ), skillRequests: Array<(
      { __typename: 'SkillRequest' }
      & WorkerSkillRequestFragment
    )>, skills: Array<(
      { __typename: 'Skill' }
      & WorkerSkillFragment
    )>, tags: Array<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>, user: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'language' | 'lastName' | 'phoneNumber'>
    ) }
  ) }
);

export type ListCandidatesQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<CandidateFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;


export type ListCandidatesQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { candidates: (
      { __typename: 'WorkerPage' }
      & { items: Array<(
        { __typename: 'Worker' }
        & Pick<Worker, 'id' | 'avatarUrl' | 'candidateStatus'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'language' | 'phoneNumber'>
        ) }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'totalItems' | 'totalPages'>
      ) }
    ) }
  ) }
);

export type ListContactsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<CustomerAdminFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;


export type ListContactsQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { customerAdmins: (
      { __typename: 'CustomerAdminPage' }
      & { items: Array<(
        { __typename: 'CustomerAdmin' }
        & Pick<CustomerAdmin, 'id' | 'avatarUrl'>
        & { customer: (
          { __typename: 'Customer' }
          & Pick<Customer, 'id' | 'logoUrl' | 'name'>
        ), user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
        ) }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'totalItems' | 'totalPages'>
      ) }
    ) }
  ) }
);

export type ListCustomerAccountsQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type ListCustomerAccountsQuery = (
  { __typename: 'Query' }
  & { customer: (
    { __typename: 'Customer' }
    & Pick<Customer, 'id'>
    & { accounts: Array<(
      { __typename: 'Account' }
      & Pick<Account, 'id' | 'default' | 'name'>
      & { defaultContact: (
        { __typename: 'CustomerAdmin' }
        & Pick<CustomerAdmin, 'id'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        ) }
      ) }
    )> }
  ) }
);

export type AutocompleteCustomersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type AutocompleteCustomersQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { customers: (
      { __typename: 'CustomerPage' }
      & { items: Array<(
        { __typename: 'Customer' }
        & Pick<Customer, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type ListAddressesQueryVariables = Exact<{
  customerId: Scalars['ID'];
  skillId: Scalars['ID'];
}>;


export type ListAddressesQuery = (
  { __typename: 'Query' }
  & { customer: (
    { __typename: 'Customer' }
    & Pick<Customer, 'id'>
    & { addresses: Array<(
      { __typename: 'Address' }
      & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'name' | 'state' | 'zip'>
      & { coords: (
        { __typename: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      ), rateQuote: Maybe<(
        { __typename: 'Rate' }
        & Pick<Rate, 'id' | 'pay' | 'cost'>
      )> }
    )> }
  ) }
);

export type ListUniformsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;


export type ListUniformsQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { uniforms: Array<(
      { __typename: 'Uniform' }
      & Pick<Uniform, 'id' | 'name' | 'imageUrl'>
    )> }
  ) }
);

export type ListSkillCategoriesQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;


export type ListSkillCategoriesQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { skillCategories: Array<(
      { __typename: 'SkillCategory' }
      & Pick<SkillCategory, 'id' | 'name' | 'imageUrl'>
      & { skills: Array<(
        { __typename: 'Skill' }
        & Pick<Skill, 'id' | 'name' | 'imageUrl'>
      )> }
    )> }
  ) }
);

export type CreateOrderMutationVariables = Exact<{
  accountId: Scalars['ID'];
  jobs: Array<JobInput> | JobInput;
}>;


export type CreateOrderMutation = (
  { __typename: 'Mutation' }
  & { orderCreate: (
    { __typename: 'OrderCreatePayload' }
    & { order: (
      { __typename: 'Order' }
      & Pick<Order, 'id' | 'uuid'>
    ) }
  ) }
);

export type GetAccountQueryVariables = Exact<{
  accountId: Scalars['ID'];
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;


export type GetAccountQuery = (
  { __typename: 'Query' }
  & { account: (
    { __typename: 'Account' }
    & Pick<Account, 'id' | 'name'>
    & { customer: (
      { __typename: 'Customer' }
      & Pick<Customer, 'id' | 'logoUrl' | 'name'>
    ), defaultContact: (
      { __typename: 'CustomerAdmin' }
      & Pick<CustomerAdmin, 'id' | 'avatarUrl'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
      ) }
    ), orders: (
      { __typename: 'OrderPage' }
      & { items: Array<(
        { __typename: 'Order' }
        & Pick<Order, 'id' | 'uuid' | 'startAt' | 'endAt' | 'jobsCount'>
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'totalItems' | 'totalPages'>
      ) }
    ) }
  ) }
);

export type CreateAccountMutationVariables = Exact<{
  customerId: Scalars['ID'];
  defaultContactId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateAccountMutation = (
  { __typename: 'Mutation' }
  & { accountCreate: (
    { __typename: 'AccountCreatePayload' }
    & { account: (
      { __typename: 'Account' }
      & AccountItemFragment
    ) }
  ) }
);

export type CreateAddressMutationVariables = Exact<{
  customerId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  coords: PointInput;
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
}>;


export type CreateAddressMutation = (
  { __typename: 'Mutation' }
  & { addressCreate: (
    { __typename: 'AddressCreatePayload' }
    & { address: (
      { __typename: 'Address' }
      & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'name' | 'state' | 'zip'>
      & { coords: (
        { __typename: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      ) }
    ) }
  ) }
);

export type UpdateAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  coords: PointInput;
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
}>;


export type UpdateAddressMutation = (
  { __typename: 'Mutation' }
  & { addressUpdate: (
    { __typename: 'AddressUpdatePayload' }
    & { address: (
      { __typename: 'Address' }
      & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'name' | 'state' | 'zip'>
      & { coords: (
        { __typename: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      ) }
    ) }
  ) }
);

export type InviteAdminMutationVariables = Exact<{
  customerId: Scalars['ID'];
  email: Scalars['Email'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;


export type InviteAdminMutation = (
  { __typename: 'Mutation' }
  & { customerInviteAdmin: (
    { __typename: 'CustomerInviteAdminPayload' }
    & { customerAdmin: (
      { __typename: 'CustomerAdmin' }
      & AdminItemFragment
    ) }
  ) }
);

export type AdminItemFragment = (
  { __typename: 'CustomerAdmin' }
  & Pick<CustomerAdmin, 'id' | 'avatarUrl'>
  & { user: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'language' | 'lastName'>
  ) }
);

export type AccountItemFragment = (
  { __typename: 'Account' }
  & Pick<Account, 'id' | 'name'>
);

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type GetCustomerQuery = (
  { __typename: 'Query' }
  & { customer: (
    { __typename: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
    & { addresses: Array<(
      { __typename: 'Address' }
      & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'name' | 'state' | 'zip'>
      & { coords: (
        { __typename: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      ) }
    )>, accounts: Array<(
      { __typename: 'Account' }
      & AccountItemFragment
    )>, admins: Array<(
      { __typename: 'CustomerAdmin' }
      & AdminItemFragment
    )>, tags: Array<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

export type RenameCustomerMutationVariables = Exact<{
  customerId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type RenameCustomerMutation = (
  { __typename: 'Mutation' }
  & { customerUpdate: (
    { __typename: 'CustomerUpdatePayload' }
    & { customer: (
      { __typename: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    ) }
  ) }
);

export type GetAdminCustomerQueryVariables = Exact<{
  customerAdminId: Scalars['ID'];
}>;


export type GetAdminCustomerQuery = (
  { __typename: 'Query' }
  & { customerAdmin: (
    { __typename: 'CustomerAdmin' }
    & Pick<CustomerAdmin, 'id' | 'avatarUrl' | 'chatUid'>
    & { customer: (
      { __typename: 'Customer' }
      & Pick<Customer, 'id' | 'logoUrl' | 'name'>
    ), user: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
    ) }
  ) }
);

export type CreateCustomerMutationVariables = Exact<{
  agencyId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateCustomerMutation = (
  { __typename: 'Mutation' }
  & { customerCreate: (
    { __typename: 'CustomerCreatePayload' }
    & { customer: (
      { __typename: 'Customer' }
      & Pick<Customer, 'id'>
    ) }
  ) }
);

export type ListCustomersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<CustomerFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;


export type ListCustomersQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { customers: (
      { __typename: 'CustomerPage' }
      & { items: Array<(
        { __typename: 'Customer' }
        & Pick<Customer, 'id' | 'logoUrl' | 'name'>
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'totalItems' | 'totalPages'>
      ) }
    ) }
  ) }
);

export type ListShiftsByWeekQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
}>;


export type ListShiftsByWeekQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { shiftsByWeek: Array<(
      { __typename: 'Shift' }
      & Pick<Shift, 'id' | 'endAt' | 'startAt'>
      & { job: (
        { __typename: 'Job' }
        & Pick<Job, 'id' | 'hiredWorkersCount' | 'quantity'>
        & { account: (
          { __typename: 'Account' }
          & Pick<Account, 'id' | 'name'>
          & { customer: (
            { __typename: 'Customer' }
            & Pick<Customer, 'id' | 'name'>
          ) }
        ), address: (
          { __typename: 'Address' }
          & Pick<Address, 'id' | 'city' | 'state' | 'zip'>
        ), order: (
          { __typename: 'Order' }
          & Pick<Order, 'id' | 'uuid'>
        ), skill: (
          { __typename: 'Skill' }
          & Pick<Skill, 'id' | 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type GetAgencyByKeyQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  key: Scalars['Key'];
}>;


export type GetAgencyByKeyQuery = (
  { __typename: 'Query' }
  & { agencyByKey: (
    { __typename: 'Agency' }
    & CurrentAgencyFragment
  ) }
);

export type ListJobCustomerAdminsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type ListJobCustomerAdminsQuery = (
  { __typename: 'Query' }
  & { job: (
    { __typename: 'Job' }
    & Pick<Job, 'id'>
    & { customer: (
      { __typename: 'Customer' }
      & Pick<Customer, 'id'>
      & { admins: Array<(
        { __typename: 'CustomerAdmin' }
        & Pick<CustomerAdmin, 'id' | 'avatarUrl'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
        ) }
      )> }
    ) }
  ) }
);

export type ListAgencyUniformsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;


export type ListAgencyUniformsQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { uniforms: Array<(
      { __typename: 'Uniform' }
      & Pick<Uniform, 'id' | 'name' | 'imageUrl'>
    )> }
  ) }
);

export type JobOfferItemFragment = (
  { __typename: 'JobOffer' }
  & Pick<JobOffer, 'id' | 'acceptedAt' | 'costRate' | 'createdAt' | 'payRate' | 'rejectedAt'>
  & { worker: (
    { __typename: 'Worker' }
    & Pick<Worker, 'id' | 'avatarUrl'>
    & { user: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    ) }
  ) }
);

export type JobWorkerItemFragment = (
  { __typename: 'JobWorker' }
  & Pick<JobWorker, 'id' | 'costRate' | 'payRate'>
  & { worker: (
    { __typename: 'Worker' }
    & Pick<Worker, 'id' | 'avatarUrl' | 'city' | 'state'>
    & { user: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    ) }
  ) }
);

export type TimesheetItemFragment = (
  { __typename: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'approvedAt' | 'minutes' | 'payRate'>
  & { jobWorker: (
    { __typename: 'JobWorker' }
    & Pick<JobWorker, 'id'>
    & { worker: (
      { __typename: 'Worker' }
      & Pick<Worker, 'id'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      ) }
    ) }
  ), shift: (
    { __typename: 'Shift' }
    & Pick<Shift, 'id' | 'endAt' | 'startAt'>
  ) }
);

export type GetJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GetJobQuery = (
  { __typename: 'Query' }
  & { job: (
    { __typename: 'Job' }
    & Pick<Job, 'id' | 'addressInstructions' | 'cancelledAt' | 'contactInstructions' | 'costRate' | 'firstShiftStartAt' | 'hiredWorkersCount' | 'instructions' | 'lastShiftEndAt' | 'payRate' | 'postedAt' | 'published' | 'quantity' | 'uniformInstructions'>
    & { account: (
      { __typename: 'Account' }
      & Pick<Account, 'id' | 'name' | 'phoneNumber'>
      & { customer: (
        { __typename: 'Customer' }
        & Pick<Customer, 'id' | 'name'>
      ) }
    ), address: (
      { __typename: 'Address' }
      & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'>
      & { coords: (
        { __typename: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      ) }
    ), contact: (
      { __typename: 'CustomerAdmin' }
      & Pick<CustomerAdmin, 'id' | 'avatarUrl'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      ) }
    ), jobOffers: Array<(
      { __typename: 'JobOffer' }
      & JobOfferItemFragment
    )>, jobRequests: Array<(
      { __typename: 'JobRequest' }
      & Pick<JobRequest, 'id' | 'createdAt' | 'status'>
      & { worker: (
        { __typename: 'Worker' }
        & Pick<Worker, 'id' | 'avatarUrl'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        ) }
      ) }
    )>, jobWorkers: Array<(
      { __typename: 'JobWorker' }
      & JobWorkerItemFragment
    )>, order: (
      { __typename: 'Order' }
      & Pick<Order, 'id'>
    ), shifts: Array<(
      { __typename: 'Shift' }
      & Pick<Shift, 'id' | 'endAt' | 'startAt'>
    )>, skill: (
      { __typename: 'Skill' }
      & Pick<Skill, 'id' | 'name' | 'imageUrl'>
    ), timesheets: Array<(
      { __typename: 'Timesheet' }
      & TimesheetItemFragment
    )>, uniform: (
      { __typename: 'Uniform' }
      & Pick<Uniform, 'id' | 'name' | 'imageUrl'>
    ) }
  ) }
);

export type CreateJobOfferMutationVariables = Exact<{
  jobId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;


export type CreateJobOfferMutation = (
  { __typename: 'Mutation' }
  & { jobOfferCreate: (
    { __typename: 'JobOfferCreatePayload' }
    & { jobOffer: (
      { __typename: 'JobOffer' }
      & JobOfferItemFragment
    ) }
  ) }
);

export type UpdateJobMutationVariables = Exact<{
  jobId: Scalars['ID'];
  addressInstructions: Scalars['String'];
  contactId: Scalars['ID'];
  contactInstructions: Scalars['String'];
  instructions: Scalars['String'];
  uniformId: Scalars['ID'];
  uniformInstructions: Scalars['String'];
}>;


export type UpdateJobMutation = (
  { __typename: 'Mutation' }
  & { jobUpdate: (
    { __typename: 'JobUpdatePayload' }
    & { job: (
      { __typename: 'Job' }
      & Pick<Job, 'id' | 'addressInstructions' | 'contactInstructions' | 'instructions' | 'uniformInstructions'>
      & { contact: (
        { __typename: 'CustomerAdmin' }
        & Pick<CustomerAdmin, 'id' | 'avatarUrl'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        ) }
      ), uniform: (
        { __typename: 'Uniform' }
        & Pick<Uniform, 'id' | 'imageUrl' | 'name'>
      ) }
    ) }
  ) }
);

export type ListWorkersForJobQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<WorkerFilterSetInput>;
}>;


export type ListWorkersForJobQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { workers: (
      { __typename: 'WorkerPage' }
      & { items: Array<(
        { __typename: 'Worker' }
        & Pick<Worker, 'id'>
        & { user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        ) }
      )> }
    ) }
  ) }
);

export type HireWorkerMutationVariables = Exact<{
  jobId: Scalars['ID'];
  payRate: Maybe<Scalars['PositiveInt']>;
  workerId: Scalars['ID'];
}>;


export type HireWorkerMutation = (
  { __typename: 'Mutation' }
  & { jobHireWorker: (
    { __typename: 'JobHireWorkerPayload' }
    & { job: (
      { __typename: 'Job' }
      & Pick<Job, 'id' | 'hiredWorkersCount'>
      & { jobWorkers: Array<(
        { __typename: 'JobWorker' }
        & JobWorkerItemFragment
      )> }
    ) }
  ) }
);

export type ListJobsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;


export type ListJobsQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { jobs: (
      { __typename: 'JobPage' }
      & { items: Array<(
        { __typename: 'Job' }
        & Pick<Job, 'id' | 'quantity' | 'hiredWorkersCount' | 'firstShiftStartAt' | 'lastShiftEndAt'>
        & { skill: (
          { __typename: 'Skill' }
          & Pick<Skill, 'id' | 'name'>
        ) }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'totalItems' | 'totalPages'>
      ) }
    ) }
  ) }
);

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type GetOrderQuery = (
  { __typename: 'Query' }
  & { order: (
    { __typename: 'Order' }
    & Pick<Order, 'id' | 'createdAt' | 'startAt' | 'endAt' | 'jobsCount' | 'uuid'>
    & { account: (
      { __typename: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { customer: (
        { __typename: 'Customer' }
        & Pick<Customer, 'id' | 'logoUrl' | 'name'>
      ) }
    ), jobs: Array<(
      { __typename: 'Job' }
      & Pick<Job, 'id' | 'quantity' | 'hiredWorkersCount' | 'firstShiftStartAt' | 'lastShiftEndAt'>
      & { skill: (
        { __typename: 'Skill' }
        & Pick<Skill, 'id' | 'name' | 'imageUrl'>
      ) }
    )>, tags: Array<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

export type ListOrdersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<OrderFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;


export type ListOrdersQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { orders: (
      { __typename: 'OrderPage' }
      & { items: Array<(
        { __typename: 'Order' }
        & Pick<Order, 'id' | 'uuid' | 'startAt' | 'endAt' | 'jobsCount'>
        & { account: (
          { __typename: 'Account' }
          & Pick<Account, 'id' | 'name'>
          & { customer: (
            { __typename: 'Customer' }
            & Pick<Customer, 'id' | 'logoUrl' | 'name'>
          ) }
        ) }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'totalItems' | 'totalPages'>
      ) }
    ) }
  ) }
);

export type WorkerJobWorkersFragment = (
  { __typename: 'JobWorkerPage' }
  & { items: Array<(
    { __typename: 'JobWorker' }
    & Pick<JobWorker, 'id' | 'costRate' | 'payRate'>
    & { order: (
      { __typename: 'Order' }
      & Pick<Order, 'id'>
    ), job: (
      { __typename: 'Job' }
      & Pick<Job, 'id' | 'firstShiftStartAt' | 'lastShiftEndAt'>
      & { address: (
        { __typename: 'Address' }
        & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'>
      ), customer: (
        { __typename: 'Customer' }
        & Pick<Customer, 'id' | 'name' | 'logoUrl'>
      ), skill: (
        { __typename: 'Skill' }
        & Pick<Skill, 'id' | 'name' | 'imageUrl'>
      ) }
    ) }
  )>, pageInfo: (
    { __typename: 'PageInfo' }
    & Pick<PageInfo, 'totalItems' | 'totalPages'>
  ) }
);

export type GetWorkerQueryVariables = Exact<{
  filterDate: Scalars['ISO8601DateTime'];
  workerId: Scalars['ID'];
}>;


export type GetWorkerQuery = (
  { __typename: 'Query' }
  & { worker: (
    { __typename: 'Worker' }
    & Pick<Worker, 'id' | 'active' | 'addressLine1' | 'addressLine2' | 'avatarUrl' | 'city' | 'gender' | 'state' | 'status' | 'zip'>
    & { coords: (
      { __typename: 'Point' }
      & Pick<Point, 'latitude' | 'longitude'>
    ), jobWorkers: (
      { __typename: 'JobWorkerPage' }
      & WorkerJobWorkersFragment
    ), pendingJobOffers: Array<(
      { __typename: 'JobOffer' }
      & Pick<JobOffer, 'id' | 'costRate' | 'createdAt' | 'payRate'>
      & { job: (
        { __typename: 'Job' }
        & Pick<Job, 'id' | 'lastShiftEndAt' | 'firstShiftStartAt'>
        & { order: (
          { __typename: 'Order' }
          & Pick<Order, 'id'>
        ), address: (
          { __typename: 'Address' }
          & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'>
        ), customer: (
          { __typename: 'Customer' }
          & Pick<Customer, 'id' | 'name' | 'logoUrl'>
        ), skill: (
          { __typename: 'Skill' }
          & Pick<Skill, 'id' | 'name' | 'imageUrl'>
        ) }
      ) }
    )>, pendingJobRequests: Array<(
      { __typename: 'JobRequest' }
      & Pick<JobRequest, 'id' | 'createdAt'>
      & { job: (
        { __typename: 'Job' }
        & Pick<Job, 'id' | 'lastShiftEndAt' | 'firstShiftStartAt'>
        & { order: (
          { __typename: 'Order' }
          & Pick<Order, 'id'>
        ), address: (
          { __typename: 'Address' }
          & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'>
        ), customer: (
          { __typename: 'Customer' }
          & Pick<Customer, 'id' | 'name' | 'logoUrl'>
        ), skill: (
          { __typename: 'Skill' }
          & Pick<Skill, 'id' | 'name' | 'imageUrl'>
        ) }
      ) }
    )>, skills: Array<(
      { __typename: 'Skill' }
      & WorkerSkillFragment
    )>, tags: Array<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>, user: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'language' | 'lastName' | 'phoneNumber'>
    ) }
  ) }
);

export type GetWorkerJobWorkersQueryVariables = Exact<{
  filters: Maybe<JobWorkerFilterSetInput>;
  workerId: Scalars['ID'];
}>;


export type GetWorkerJobWorkersQuery = (
  { __typename: 'Query' }
  & { worker: (
    { __typename: 'Worker' }
    & Pick<Worker, 'id'>
    & { jobWorkers: (
      { __typename: 'JobWorkerPage' }
      & WorkerJobWorkersFragment
    ) }
  ) }
);

export type ChangeWorkerAddressMutationVariables = Exact<{
  workerId: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  coords: PointInput;
}>;


export type ChangeWorkerAddressMutation = (
  { __typename: 'Mutation' }
  & { workerChangeAddress: (
    { __typename: 'WorkerChangeAddressPayload' }
    & { worker: (
      { __typename: 'Worker' }
      & Pick<Worker, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'>
      & { coords: (
        { __typename: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      ) }
    ) }
  ) }
);

export type AddWorkerSkillMutationVariables = Exact<{
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;


export type AddWorkerSkillMutation = (
  { __typename: 'Mutation' }
  & { workerAddSkill: (
    { __typename: 'WorkerAddSkillPayload' }
    & { worker: (
      { __typename: 'Worker' }
      & Pick<Worker, 'id'>
      & { skills: Array<(
        { __typename: 'Skill' }
        & WorkerSkillFragment
      )> }
    ) }
  ) }
);

export type ListAgencySkillsQueryVariables = Exact<{
  agencyId: Scalars['ID'];
}>;


export type ListAgencySkillsQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { skillCategories: Array<(
      { __typename: 'SkillCategory' }
      & Pick<SkillCategory, 'id' | 'name'>
      & { skills: Array<(
        { __typename: 'Skill' }
        & WorkerSkillFragment
      )> }
    )> }
  ) }
);

export type RemoveWorkerSkillMutationVariables = Exact<{
  skillId: Scalars['ID'];
  workerId: Scalars['ID'];
}>;


export type RemoveWorkerSkillMutation = (
  { __typename: 'Mutation' }
  & { workerRemoveSkill: (
    { __typename: 'WorkerRemoveSkillPayload' }
    & { worker: (
      { __typename: 'Worker' }
      & Pick<Worker, 'id'>
      & { skills: Array<(
        { __typename: 'Skill' }
        & WorkerSkillFragment
      )> }
    ) }
  ) }
);

export type WorkerUpdateProfileMutationVariables = Exact<{
  workerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: GenderEnum;
}>;


export type WorkerUpdateProfileMutation = (
  { __typename: 'Mutation' }
  & { workerUpdateProfile: (
    { __typename: 'WorkerUpdateProfilePayload' }
    & { worker: (
      { __typename: 'Worker' }
      & Pick<Worker, 'id' | 'gender'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    ) }
  ) }
);

export type ListWorkersQueryVariables = Exact<{
  agencyId: Scalars['ID'];
  filters: Maybe<WorkerFilterSetInput>;
  page: Scalars['PositiveInt'];
  perPage: Scalars['PositiveInt'];
}>;


export type ListWorkersQuery = (
  { __typename: 'Query' }
  & { agency: (
    { __typename: 'Agency' }
    & Pick<Agency, 'id'>
    & { workers: (
      { __typename: 'WorkerPage' }
      & { items: Array<(
        { __typename: 'Worker' }
        & Pick<Worker, 'id' | 'avatarUrl' | 'city' | 'gender' | 'state'>
        & { skills: Array<(
          { __typename: 'Skill' }
          & Pick<Skill, 'id' | 'name'>
        )>, user: (
          { __typename: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'language'>
        ) }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'totalItems' | 'totalPages'>
      ) }
    ) }
  ) }
);

export type GetCurrentTenantAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentTenantAdminQuery = (
  { __typename: 'Query' }
  & { currentTenantAdmin: (
    { __typename: 'TenantAdmin' }
    & CurrentTenantAdminFragment
  ) }
);

export type LoginTenantAdminMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  email: Scalars['Email'];
  password: Scalars['String'];
}>;


export type LoginTenantAdminMutation = (
  { __typename: 'Mutation' }
  & { tenantAdminLogin: (
    { __typename: 'TenantAdminLoginPayload' }
    & Pick<TenantAdminLoginPayload, 'accessToken' | 'refreshToken'>
    & { tenantAdmin: (
      { __typename: 'TenantAdmin' }
      & CurrentTenantAdminFragment
    ) }
  ) }
);

export type GetTenantByKeyQueryVariables = Exact<{
  key: Scalars['Key'];
}>;


export type GetTenantByKeyQuery = (
  { __typename: 'Query' }
  & { tenantByKey: Maybe<(
    { __typename: 'Tenant' }
    & CurrentTenantFragment
  )> }
);
