import { GetAccountQuery } from '@/types/graphql'

import Link from '@/elements/Link'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body } from '@/components/Typography'

type Props = {
  defaultContact: GetAccountQuery['account']['defaultContact']
}

const ProfileCard = ({ defaultContact }: Props) => {
  return (
    <Card
      title="Default contact"
      actions={[
        {
          a11yLabel: 'Change default contact',
          label: 'Change',
          onAction: () => undefined,
        },
      ]}
    >
      <Card.Section>
        <Stack gap={20}>
          <div>
            <Avatar src={defaultContact?.avatarUrl} />
          </div>
          <TextStack>
            <Link to={`../../admin/${defaultContact?.id}`}>
              <Body color="inherit" weight="medium">
                {defaultContact?.user.firstName} {defaultContact?.user.lastName}
              </Body>
            </Link>
            <Body color="light">{defaultContact?.user.email}</Body>
            <Body color="light">{defaultContact?.user.phoneNumber}</Body>
          </TextStack>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default ProfileCard
