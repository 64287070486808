import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CandidateStatusEnum, WorkerStatusEnum } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Page, { Props as PageProps } from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import { Title } from '@/components/Typography'

import CandidateBadge from './CandidateBadge'
// import JobsList from './JobList'
// import PageSkeleton from './PageSkeleton'
// import PendingOffersList from './PendingOffersList'
// import PendingRequestsList from './PendingRequestsList'
// import ProfileCard from './ProfileCard'
// import Tags from './Tags'

import { useGetCandidateQuery } from '@/graphql'

const Candidate = () => {
  const { workerId } = useParams()
  const navigate = useNavigate()

  const query = useGetCandidateQuery({
    variables: { workerId },
  })

  const candidateActions = useMemo<
    Pick<PageProps, 'primaryAction' | 'secondaryActions'>
  >(() => {
    if (!query.data) return {}

    switch (query.data.worker.candidateStatus) {
      case CandidateStatusEnum.UNVERIFIED:
        return {}
      case CandidateStatusEnum.VERIFIED:
        return {
          primaryAction: {
            a11yLabel: 'Schedule interview with this candidate',
            label: 'Schedule interview',
            onAction: () => undefined,
          },
          secondaryActions: [
            {
              a11yLabel: 'Skip the interview process for this candidate',
              label: 'Skip interview',
              onAction: () => undefined,
            },
          ],
        }
      default:
        return {}
    }
  }, [query.data])

  useEffect(() => {
    if (query.data && query.data.worker.status === WorkerStatusEnum.HIRED) {
      navigate(`../../workers/${query.data.worker.id}`)
    }
  }, [query.data])

  if (!query.data) {
    return null
  }

  const { worker } = query.data

  return (
    <Page
      media={<Avatar src={worker.avatarUrl} />}
      title={
        <Stack gap={16}>
          <Title>
            {worker.user.firstName} {worker.user.lastName}
          </Title>
          <CandidateBadge status={worker.candidateStatus} />
        </Stack>
      }
      {...candidateActions}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            {/* <JobsList worker={worker} />
            <PendingOffersList worker={worker} />
            <PendingRequestsList worker={worker} /> */}
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            {/* <ProfileCard worker={worker} />
            <Tags worker={worker} /> */}
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Candidate
