import { Maybe } from '@/types'

import Button from '@/components/Button'
import SingleColumnLayout from '@/components/SingleColumnLayout'
import Stack from '@/components/Stack'
import { Heading } from '@/components/Typography'

import Form from '@/form'

import AddressSection from './AddressSection'
import BottomBar from '../BottomBar'
import InstructionsSection from './InstructionsSection'
import Layout from '../Layout'
import UniformSection from './UniformSection'

import { Address, Step, Uniform, useJobDraftActions, useJobDraftState } from '../../context'
import { useCallback, useMemo } from 'react'

type Props = {
  setStep: (step: Step) => void
}

type FormValues = {
  address: Maybe<Address>
  addressInstructions: string
  instructions: string
  uniform: Maybe<Uniform>
  uniformInstructions: string
}

const DetailsStep = ({ setStep }: Props) => {
  const { address, addressInstructions, instructions, skill, uniform, uniformInstructions } = useJobDraftState()
  const { updateDetails } = useJobDraftActions()

  const initialValues = useMemo(() => ({
    address, addressInstructions, instructions, uniform, uniformInstructions
  }), [])

  const handleSubmit = useCallback(async ({ address, addressInstructions, instructions, uniform, uniformInstructions }: FormValues) => {
    updateDetails({
      address: address!, addressInstructions, instructions, uniform: uniform!, uniformInstructions,
      contact: null, contactInstructions: '', rate: null
    })
    setStep(Step.SCHEDULE)
  }, [])

  return (
    <Layout>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <SingleColumnLayout size='sm'>
          <Stack vertical gap={36}>
            <Heading>
              Add some instructions
            </Heading>
            <UniformSection hasInstructions={initialValues.uniformInstructions !== ''} />
            <InstructionsSection />
            <AddressSection
              hasInstructions={initialValues.addressInstructions !== ''}
              skill={skill!}
            />
            <BottomBar>
              <Button
                a11yLabel='Go back to previous step'
                appearance='outline'
                label='Back'
                type='button'
                onClick={() => setStep(Step.SKILL)}
              />
              <Button
                a11yLabel='Submit form'
                label='Continue'
                type='submit'
              />
            </BottomBar>
          </Stack>
        </SingleColumnLayout>
      </Form>
    </Layout>
  )
}

export default DetailsStep
