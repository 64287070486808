import { ChangeEvent, useCallback } from 'react'

import { CompareOperatorEnum, OrderFilterSetInput } from '@/types/graphql'

import { FilterProps } from '@/filters'

import Button from '@/components/Button'
import Option from '@/components/Option'
import Stack from '@/components/Stack'

type Props = Omit<FilterProps<OrderFilterSetInput>, 'filterKey'> & {
  idPrefix: string
}

enum OrderStatus {
  IN_PROGRESS = 'in_progress',
  PAST = 'past',
  UPCOMING = 'upcoming',
}

const ORDER_LABELS = {
  [OrderStatus.IN_PROGRESS]: 'In progress',
  [OrderStatus.PAST]: 'Past',
  [OrderStatus.UPCOMING]: 'Upcoming',
}

const OrderStatusFilter = ({
  filters,
  idPrefix,
  onChangeFilter,
  onClearFilter,
}: Props) => {
  const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    const dateISO = new Date().toISOString()

    switch (ev.currentTarget.value) {
      case OrderStatus.UPCOMING:
        onClearFilter('endAt')
        onChangeFilter('startAt', {
          op: CompareOperatorEnum.GT,
          value: dateISO,
        })
        break
      case OrderStatus.IN_PROGRESS:
        onChangeFilter('startAt', {
          op: CompareOperatorEnum.LT,
          value: dateISO,
        })
        onChangeFilter('endAt', { op: CompareOperatorEnum.GT, value: dateISO })
        break
      case OrderStatus.PAST:
        onClearFilter('startAt')
        onChangeFilter('endAt', { op: CompareOperatorEnum.LT, value: dateISO })
        break
    }
  }, [])

  const clearFilter = useCallback(() => {
    if (filters.endAt) {
      onClearFilter('endAt')
      onChangeFilter('startAt', {
        op: CompareOperatorEnum.GT,
        value: new Date().toISOString(),
      })
    }
  }, [filters.endAt])

  const value = !!filters.startAt
    ? !!filters.endAt
      ? OrderStatus.IN_PROGRESS
      : OrderStatus.UPCOMING
    : OrderStatus.PAST

  return (
    <Stack vertical gap={16}>
      {[OrderStatus.UPCOMING, OrderStatus.IN_PROGRESS, OrderStatus.PAST].map(
        (option) => (
          <Option
            key={option}
            appearance="bullseye"
            checked={value === option}
            id={`${idPrefix}_orderStatus-${option}`}
            label={ORDER_LABELS[option]}
            name={`${idPrefix}_orderStatus`}
            type="radio"
            value={option}
            onChange={handleChange}
          />
        )
      )}
      <Button
        a11yLabel="Clear order status filter"
        appearance="plain"
        label="Clear"
        onClick={clearFilter}
      />
    </Stack>
  )
}

export default OrderStatusFilter
