import { useReactiveVar } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import { Maybe } from '@/types'

import Button from '@/components/Button'
import SingleColumnLayout from '@/components/SingleColumnLayout'
import Stack from '@/components/Stack'
import { Heading } from '@/components/Typography'

import Form from '@/form'
import SingleSelectableField from '@/form/SingleSelectableField'

import BottomBar from '../BottomBar'
import Layout from '../Layout'
import SkillSelectField from './SkillSelectField'

import { currentAgencyVar } from '@/util/apollo/cache'

import { Skill, SkillCategory, Step, useJobDraftActions, useJobDraftState } from '../../context'

import { useListSkillCategoriesQuery } from '@/graphql'

type FormValues = {
  skill: Maybe<Skill>
  skillCategory: Maybe<SkillCategory>
}

type Props = {
  setStep: (step: Step) => void
  onClose: () => void
}

const SkillStep = ({ setStep, onClose }: Props) => {
  const { skill, skillCategory } = useJobDraftState()
  const { updateSkill } = useJobDraftActions()

  const initialValues: FormValues = useMemo(() => ({ skill, skillCategory }), [])

  const currentAgency = useReactiveVar(currentAgencyVar)
  const { data, loading } = useListSkillCategoriesQuery({
    variables: { agencyId: currentAgency!.id }
  })

  const renderCategory = useCallback((item: SkillCategory) => (
    <Stack vertical gap={0}>
      <img style={{ width: 128, height: 128 }} src={item.imageUrl || '//placehold.it/128x128'} />
      <div style={{ padding: 16, width: '100%' }}>
        {item.name}
      </div>
    </Stack>
  ), [])

  const handleSubmit = useCallback(async ({ skill, skillCategory }: FormValues) => {
    updateSkill({ skill: skill!, skillCategory: skillCategory! })
    setStep(Step.DETAILS)
  }, [])

  return (
    <Layout>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <SingleColumnLayout size='sm'>
          <Stack vertical gap={48}>
            <Stack vertical align='center' gap={24}>
              <Heading>
                Select job category
              </Heading>
              <Stack gap={16} justify='center'>
                <SingleSelectableField<SkillCategory>
                  required
                  disabled={loading}
                  fieldId='skillCategory'
                  options={data ? data.agency.skillCategories.map((item) => ({ item })) : []}
                  itemToKey={(item) => item.id}
                  renderItem={renderCategory}
                />
              </Stack>
            </Stack>
            <SkillSelectField
              fieldId='skill'
              skillCategoryFieldId='skillCategory'
            />
            <BottomBar>
              <Button
                a11yLabel='Close job editor'
                appearance='outline'
                label='Cancel'
                type='button'
                onClick={onClose}
              />
              <Button
                a11yLabel='Submit form'
                label='Continue'
                type='submit'
              />
            </BottomBar>
          </Stack>
        </SingleColumnLayout>
      </Form>
    </Layout>
  )
}

export default SkillStep
