import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { WorkerStatusEnum } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'

import JobsList from './JobList'
import PageSkeleton from './PageSkeleton'
import PendingOffersList from './PendingOffersList'
import PendingRequestsList from './PendingRequestsList'
import ProfileCard from './ProfileCard'
import Tags from './Tags'

import { useGetWorkerQuery } from '@/graphql'

const Worker = () => {
  const { workerId } = useParams()
  const navigate = useNavigate()

  const filterDate = useMemo(() => new Date().toISOString(), [])
  const query = useGetWorkerQuery({
    variables: { filterDate, workerId },
  })

  useEffect(() => {
    if (query.data && query.data.worker.status === WorkerStatusEnum.CANDIDATE) {
      navigate(`../../candidates/${query.data.worker.id}`)
    }
  }, [query.data])

  if (!query.data) {
    return <PageSkeleton />
  }

  const worker = query.data.worker

  return (
    <Page
      media={<Avatar src={worker.avatarUrl} />}
      title={`${worker.user.firstName} ${worker.user.lastName}`}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <JobsList worker={worker} />
            <PendingOffersList worker={worker} />
            <PendingRequestsList worker={worker} />
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <ProfileCard worker={worker} />
            <Tags worker={worker} />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Worker
