import { useField } from '@area2k/use-form'

import { ListCustomerAccountsQuery } from '@/types/graphql'
import { CustomerItem } from '../CustomerAutocompleteField'

import FormElement from '@/components/FormElement'
import ItemSelect from '@/components/ItemSelect'

import { useListCustomerAccountsLazyQuery } from '@/graphql'
import { useEffect } from 'react'

export type AccountItem = ListCustomerAccountsQuery['customer']['accounts'][0]

type Props = {
  customerFieldId: string
  fieldId: string,
  label: string
}

const AccountSelectField = ({ customerFieldId, fieldId, label }: Props) => {
  const [fetch, { data, loading }] = useListCustomerAccountsLazyQuery()

  const { value, setValue } = useField<AccountItem | null>(fieldId)
  const { value: customer } = useField<CustomerItem | null>(customerFieldId, ({ value: nextCustomer }) => {
    setValue(null)

    if (nextCustomer) {
      fetch({ variables: { customerId: nextCustomer.id } })
    }
  })

  useEffect(() => {
    if (data && customer && data.customer.id === customer.id) {
      setValue(data.customer.accounts[0])
    }
  }, [data, customer])

  return (
    <FormElement htmlFor={`${fieldId}-toggle-button`} label={label}>
      <ItemSelect
        required
        disabled={loading || !customer}
        id={fieldId}
        items={customer && data ? data.customer.accounts : []}
        selectedItem={value}
        itemToKey={(item) => item.id}
        itemToString={(item) => item ? item.name : ''}
        onSelectedItemChange={({ selectedItem }) => setValue(selectedItem || null)}
      />
    </FormElement>
  )
}

export default AccountSelectField
