import { useReactiveVar } from '@apollo/client'
import { useCallback, useState } from 'react'

import Button from '@/components/Button'
import FormElement from '@/components/FormElement'
import Stack from '@/components/Stack'

import SingleSelectableField from '@/form/SingleSelectableField'
import TextAreaField from '@/form/TextAreaField'

import { currentAgencyVar } from '@/util/apollo/cache'

import { Uniform } from '../../../context'

import { useListUniformsQuery } from '@/graphql'

type Props = {
  hasInstructions?: boolean
}

const UniformSection = ({ hasInstructions = false }: Props) => {
  const [isOpen, setIsOpen] = useState(hasInstructions)

  const currentAgency = useReactiveVar(currentAgencyVar)
  const { data, loading } = useListUniformsQuery({
    variables: { agencyId: currentAgency!.id }
  })

  const renderItem = useCallback((item: Uniform) => (
    <Stack vertical gap={0}>
      <img style={{ width: 180, height: 220 }} src={item.imageUrl || '//placehold.it/180x220'} />
      <div style={{ padding: 16, width: '100%' }}>
        {item.name}
      </div>
    </Stack>
  ), [])

  return (
    <Stack vertical gap={0}>
      <FormElement label='Select dress code'>
        <Stack gap={16}>
          <SingleSelectableField<Uniform>
            required
            disabled={loading}
            fieldId='uniform'
            options={data ? data.agency.uniforms.map((item) => ({ item })) : []}
            itemToKey={(item) => item.id}
            renderItem={renderItem}
          />
        </Stack>
      </FormElement>
      {isOpen
        ? <div style={{ width: '100%' }}>
            <TextAreaField
              fieldId='uniformInstructions'
              label='Dress code instructions'
              placeholder='Provide instructions and details to the workers...'
            />
          </div>
        : <Button
            a11yLabel='Click to add optional dress code instructions'
            appearance='plain'
            label='Add dress code instructions (optional)'
            type='button'
            onClick={() => setIsOpen(true)}
          />
      }
    </Stack>
  )
}

export default UniformSection
