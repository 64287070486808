import {
  addDays,
  differenceInCalendarDays,
  differenceInMinutes,
  isAfter,
  isWeekend,
  parse,
} from 'date-fns'
import { createContext, Reducer, useContext } from 'react'

import { Maybe } from '@/types'
import {
  JobInput,
  ListCustomerAccountsQuery,
  ListCustomersQuery,
  ShiftInput,
} from '@/types/graphql'

import {
  BaseJobDraftState,
  JobDraftState,
  PublishInEnum,
  Schedule,
  ScheduleType,
} from './JobEditor/context'

import { noOp } from '@/util/actions'
import { nTimes, removeAtIndex, replaceAtIndex } from '@/util/array'

export type Account = ListCustomerAccountsQuery['customer']['accounts'][0]
export type Customer = ListCustomersQuery['agency']['customers'][0]

export type Billing = {
  account: Account
  customer: Customer
}

export type OrderState = {
  billing: Maybe<Billing>
  jobs: JobDraftState[]
}

export enum OrderActionType {
  ADD_JOB = 'add_job',
  CLEAR_JOBS = 'clear_jobs',
  REMOVE_JOB = 'remove_job',
  REPLACE_JOB = 'replace_job',
  SET_BILLING = 'set_billing',
}

type SetBillingAction = {
  type: OrderActionType.SET_BILLING
  billing: Maybe<Billing>
}
type AddJobAction = { type: OrderActionType.ADD_JOB; job: JobDraftState }
type ClearJobsAction = { type: OrderActionType.CLEAR_JOBS }
type RemoveJobAction = { type: OrderActionType.REMOVE_JOB; index: number }
type ReplaceJobAction = {
  type: OrderActionType.REPLACE_JOB
  index: number
  job: JobDraftState
}
export type OrderAction =
  | AddJobAction
  | ClearJobsAction
  | RemoveJobAction
  | ReplaceJobAction
  | SetBillingAction

export type OrderActions = {
  addJob: (job: JobDraftState) => void
  clearJobs: () => void
  removeJob: (index: number) => void
  replaceJob: (index: number, job: JobDraftState) => void
  setBilling: (billing: Maybe<Billing>) => void
}

export const OrderStateContext = createContext<OrderState>({
  billing: null,
  jobs: [],
})

export const OrderActionsContext = createContext<OrderActions>({
  addJob: noOp,
  clearJobs: noOp,
  removeJob: noOp,
  replaceJob: noOp,
  setBilling: noOp,
})

export const useOrderState = () => useContext(OrderStateContext)
export const useOrderActions = () => useContext(OrderActionsContext)

export const stateReducer: Reducer<OrderState, OrderAction> = (
  state,
  action
) => {
  switch (action.type) {
    case OrderActionType.ADD_JOB:
      return { ...state, jobs: [...state.jobs, action.job] }
    case OrderActionType.CLEAR_JOBS:
      return { ...state, jobs: [] }
    case OrderActionType.REMOVE_JOB:
      return { ...state, jobs: removeAtIndex(state.jobs, action.index) }
    case OrderActionType.REPLACE_JOB:
      return {
        ...state,
        jobs: replaceAtIndex(state.jobs, action.index, 1, action.job),
      }
    case OrderActionType.SET_BILLING:
      return { ...state, billing: action.billing }
    default:
      return state
  }
}

// === UTILITIES ===

export const costOfSchedule = (schedule: Schedule) => {
  const numberOfShifts = scheduleDates(schedule).length

  const startHour = parse(schedule.startTime, 'HH:mm', new Date())
  const endHour = parse(schedule.endTime, 'HH:mm', new Date())

  const minsPerShift = differenceInMinutes(endHour, startHour)
  const hoursPerShift =
    minsPerShift < 0 ? 24 + minsPerShift / 60 : minsPerShift / 60

  return Math.ceil(
    schedule.payRate * 1.5 * schedule.quantity * numberOfShifts * hoursPerShift
  )
}

const DATE_FITNESS = {
  [ScheduleType.WEEKDAYS_ONLY]: (date: Date) => !isWeekend(date),
  [ScheduleType.WEEKENDS_ONLY]: isWeekend,
  [ScheduleType.ALL_DAYS]: () => true,
}

export const scheduleDates = (schedule: Schedule) => {
  const numberOfShifts =
    differenceInCalendarDays(
      schedule.dateRange.endDate!,
      schedule.dateRange.startDate!
    ) + 1
  const dates: Date[] = []

  nTimes(numberOfShifts, (index) => {
    const date = addDays(schedule.dateRange.startDate!, index)

    if (DATE_FITNESS[schedule.type](date)) {
      dates.push(date)
    }
  })

  return dates
}

export const scheduleToShifts = (schedule: Schedule) => {
  const shifts: ShiftInput[] = []

  scheduleDates(schedule).forEach((date) => {
    const startAt = parse(schedule.startTime, 'HH:mm', date)
    let endAt = parse(schedule.endTime, 'HH:mm', date)

    if (isAfter(startAt, endAt)) {
      endAt = addDays(endAt, 1)
    }

    shifts.push({ endAt: endAt.toISOString(), startAt: startAt.toISOString() })
  })

  return shifts
}

const publishInToMinutes = (publishIn: PublishInEnum) => {
  switch (publishIn) {
    case PublishInEnum.IMMEDIATELY:
      return 0
    case PublishInEnum.SIX_HOURS:
      return 6 * 60
    case PublishInEnum.TWELVE_HOURS:
      return 12 * 60
    case PublishInEnum.TWENTY_FOUR_HOURS:
      return 24 * 60
    case PublishInEnum.FORTY_EIGHT_HOURS:
      return 48 * 60
    case PublishInEnum.SEVENTY_TWO_HOURS:
      return 72 * 60
    default:
      return undefined
  }
}

export const jobStateToJobInput = (
  billing: Billing,
  job: BaseJobDraftState
) => {
  const base = {
    addressId: job.address!.id,
    contactId: billing.account.defaultContact.id,
    skillId: job.skill!.id,
    uniformId: job.uniform!.id,
    instructions: job.instructions,
    addressInstructions: job.addressInstructions,
    contactInstructions: job.contactInstructions,
    uniformInstructions: job.uniformInstructions,
    publishIn: publishInToMinutes(job.publishIn),
  }

  const inputs: JobInput[] = job.schedules.map((schedule) => ({
    ...base,
    quantity: schedule.quantity,
    payRate: schedule.payRate,
    shifts: scheduleToShifts(schedule),
  }))

  return inputs
}
