import useModal from '@area2k/use-modal'

import { GetWorkerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import TextStack from '@/components/TextStack'
import { Body } from '@/components/Typography'

import ChangeAddressModal from './ChangeAddressModal'

type Props = {
  worker: GetWorkerQuery['worker']
}

const AddressSection = ({ worker }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(() => (
    <ChangeAddressModal worker={worker} hideModal={hideChangeModal} />
  ))

  return (
    <Card.Section
      actions={[
        {
          a11yLabel: "Change this worker's address",
          label: 'Change',
          onAction: showChangeModal,
        },
      ]}
      title="Address"
    >
      <TextStack>
        <Body>{worker.addressLine1}</Body>
        {worker.addressLine2 && (
          <Body color="light">{worker.addressLine2}</Body>
        )}
        <Body color="light">
          {worker.city}, {worker.state} {worker.zip}
        </Body>
      </TextStack>
    </Card.Section>
  )
}

export default AddressSection
