import { ApolloProvider } from '@apollo/client'
import { ModalProvider } from '@area2k/use-modal'
import { ModalProvider as ReactModalHookProvider } from 'react-modal-hook'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import RouterDebug from '@/components/RouterDebug'

import Agencies from '@/routes/Agencies'
import Agency from '@/routes/Agency'
import AgencyCandidate from '@/routes/Agency/Candidate'
import AgencyCandidates from '@/routes/Agency/Candidates'
import AgencyContacts from '@/routes/Agency/Contacts'
import AgencyOrder from '@/routes/Agency/Order'
import AgencyOrders from '@/routes/Agency/Orders'
// import AgencyJobs from '@/routes/Agency/Jobs'
import AgencyJob from '@/routes/Agency/Job'
import AgencyCreateOrder from '@/routes/Agency/CreateOrder'
import AgencyAdminCustomer from '@/routes/Agency/CustomerAdmin'
import AgencyCustomer from '@/routes/Agency/Customer'
import AgencyCustomerAccount from '@/routes/Agency/Customer/Account'
import AgencyCustomers from '@/routes/Agency/Customers'
import AgencyDashboard from '@/routes/Agency/Dashboard'
import AgencyWorker from '@/routes/Agency/Worker'
import AgencyWorkers from '@/routes/Agency/Workers'
import AgencyChat from '@/routes/Agency/Chat'
import Authenticated from '@/routes/Authenticated'
import Login from '@/routes/Login'
import Root from '@/routes/Root'
import Signout from '@/routes/Signout'
import TenantLayout from '@/routes/TenantLayout'

import Placeholder from '@/routes/Placeholder'

import client from '@/util/apollo/client'

import Config from '@/config'

Sentry.init({
  dsn: Config.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: Config.ENVIRONMENT,
  release: Config.BUILD_COMMIT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
})

const App = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <RouterDebug />
        <ModalProvider>
          <ReactModalHookProvider>
            <Routes basename={Config.BASEPATH}>
              <Route element={<Root />}>
                <Route element={<Authenticated />}>
                  <Route element={<TenantLayout />}>
                    <Route element={<Agencies />} />
                    {/** Placeholder routes*/}
                    <Route
                      element={<Placeholder title="Cash flow report" />}
                      path="reports/cash-flow"
                    />
                    <Route
                      element={<Placeholder title="Payouts report" />}
                      path="reports/payouts"
                    />
                    <Route
                      element={<Placeholder title="Job statistics report" />}
                      path="reports/job-stats"
                    />
                    <Route
                      element={<Placeholder title="Reports" />}
                      path="reports"
                    />
                    <Route
                      element={<Placeholder title="Settings" />}
                      path="settings"
                    />
                  </Route>
                  <Route element={<Agency />} path="a/:key">
                    <Route element={<AgencyDashboard />} />
                    {/* <Route element={<AgencyJobs />} path='jobs' /> */}
                    <Route element={<AgencyCustomers />} path="customers" />
                    <Route
                      element={<AgencyCustomer />}
                      path="customers/:customerId"
                    />
                    <Route
                      element={<AgencyAdminCustomer />}
                      path="customers/:customerId/admin/:adminId"
                    />
                    <Route
                      element={<AgencyCustomerAccount />}
                      path="customers/:customerId/accounts/:accountId"
                    />
                    <Route element={<AgencyOrders />} path="orders" />
                    <Route element={<AgencyOrder />} path="orders/:orderId" />
                    <Route
                      element={<AgencyJob />}
                      path="orders/:orderId/jobs/:jobId"
                    />
                    <Route
                      element={<AgencyCreateOrder />}
                      path="orders/create"
                    />
                    <Route element={<AgencyWorkers />} path="workers" />
                    <Route
                      element={<AgencyWorker />}
                      path="workers/:workerId"
                    />
                    <Route element={<AgencyContacts />} path="contacts" />
                    <Route element={<AgencyChat />} path="chat" />
                    <Route element={<AgencyChat />} path="chat/:chatUid" />
                    <Route element={<AgencyCandidates />} path="candidates" />
                    <Route
                      element={<AgencyCandidate />}
                      path="candidates/:workerId"
                    />
                    {/** Placeholder routes*/}
                    <Route
                      element={<Placeholder title="Settings" />}
                      path="settings"
                    />
                  </Route>
                </Route>
                <Route element={<Login />} path="login" />
                <Route element={<Signout />} path="sign-out" />
              </Route>
            </Routes>
          </ReactModalHookProvider>
        </ModalProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
