import useModal from '@area2k/use-modal'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { useCallback } from 'react'

import { GetJobQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import AddWorkerModal from './AddWorkerModal'

import { JobStatus } from '../util'

type JobWorkerItem = GetJobQuery['job']['jobWorkers'][0]

type Props = {
  job: GetJobQuery['job']
  jobStatus: JobStatus
}

const WorkerList = ({ job, jobStatus }: Props) => {
  const [showAddModal, hideAddModal] = useModal(
    () => <AddWorkerModal jobId={job.id} hideModal={hideAddModal} />,
    [job.id]
  )

  const isJobActive =
    jobStatus === JobStatus.UPCOMING || jobStatus === JobStatus.IN_PROGRESS

  const renderItem = useCallback(
    ({ id, worker }: JobWorkerItem) => (
      <ResourceList.Item
        key={id}
        actions={
          isJobActive
            ? [
                {
                  a11yLabel: 'Dismiss this worker',
                  dangerous: true,
                  label: 'Dismiss',
                  icon: faBan,
                  onAction: () => undefined,
                },
              ]
            : undefined
        }
        media={<Avatar size="sm" src={worker.avatarUrl} />}
        to={`../../../../workers/${worker.id}`}
      >
        <TextStack>
          <Body weight="medium">
            {worker.user.firstName} {worker.user.lastName}
          </Body>
          <Small>{worker.user.email}</Small>
        </TextStack>
      </ResourceList.Item>
    ),
    [isJobActive]
  )

  return (
    <Card
      actions={
        isJobActive
          ? [
              {
                a11yLabel: 'Add worker to this job',
                label: 'Add worker',
                onAction: showAddModal,
              },
            ]
          : undefined
      }
      title="Hired workers"
    >
      <ResourceList
        emptyState={
          <EmptyState
            title="No hired workers"
            text={
              isJobActive
                ? 'Add a worker to this job above or via invite below.'
                : 'This job was unfilled.'
            }
          />
        }
        items={job.jobWorkers ? job.jobWorkers : []}
        renderItem={renderItem}
        totalItemCount={job.jobWorkers.length}
      />
    </Card>
  )
}
export default WorkerList
