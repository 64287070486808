import { useReactiveVar } from '@apollo/client'
import { useMemo, useState } from 'react'

import { CustomerAdminFilterSetInput } from '@/types/graphql'

import useFilters from '@/hooks/useFilters'

import Link from '@/elements/Link'
import Table, { TableRow, TableCell } from '@/elements/Table'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import LoadingState from '@/components/LoadingState'
import Page from '@/components/Page'
import Paginator from '@/components/Paginator/Paginator'
import QueryEmptyState from '@/components/QueryEmptyState'
import Stack from '@/components/Stack'
import TableHeader from '@/components/TableHeader'
import { Body, Small } from '@/components/Typography'

import ContactFilters from './ContactFilters'

import { currentAgencyVar } from '@/util/apollo/cache'

import { useListContactsQuery } from '@/graphql'
import TextStack from '@/components/TextStack'

const initialFilters: CustomerAdminFilterSetInput = {}

const Contacts = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<CustomerAdminFilterSetInput>(initialFilters)

  const finalFilters = useMemo<CustomerAdminFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const currentAgency = useReactiveVar(currentAgencyVar)
  const query = useListContactsQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }

  const headerFields = ['User', 'Customer', 'Phone number']

  const TableBody = ({ items }) => (
    <tbody>
      {items.map((item) => (
        <TableRow key={item.id}>
          <TableCell>
            <Stack gap={20}>
              <div>
                <Avatar size="sm" src={item.avatarUrl} />
              </div>
              <TextStack spacing="tight">
                <Link to={`../customers/${item.customer.id}/admin/${item.id}`}>
                  <Body color="inherit" weight="medium">
                    {item.user.firstName} {item.user.lastName}
                  </Body>
                </Link>
                <Small>{item.user.email}</Small>
              </TextStack>
            </Stack>
          </TableCell>
          <TableCell>
            <Stack gap={12}>
              <div>
                <Avatar size="xs" src={item.customer.logoUrl} />
              </div>
              <Link to={`../customers/${item.customer.id}`}>
                {item.customer.name}
              </Link>
            </Stack>
          </TableCell>
          <TableCell>{item.user.phoneNumber}</TableCell>
        </TableRow>
      ))}
      {query.loading && (
        <TableRow>
          <TableCell colSpan={headerFields.length} align="center">
            <LoadingState overlayColor="white" />
          </TableCell>
        </TableRow>
      )}
    </tbody>
  )
  const items =
    !query.loading && query.data ? query.data.agency.customerAdmins.items : []
  const pageInfo =
    !query.loading && query.data
      ? query.data.agency.customerAdmins.pageInfo
      : {}
  return (
    <Page size="md" title="Contacts">
      <Card>
        <Card.Section>
          <ContactFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <Table>
          <TableHeader fields={headerFields} />
          <TableBody items={items} />
        </Table>
        {items.length == 0 && (
          <QueryEmptyState
            query={query}
            title="No jobs available"
            text="If there were any, they would be here."
          />
        )}
      </Card>
      {items.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={pageInfo.totalItems}
        />
      )}
    </Page>
  )
}

export default Contacts
