import { useModal } from 'react-modal-hook'

import { CandidateFilterSetInput } from '@/types/graphql'

import ButtonGroup from '@/elements/ButtonGroup'

import Button from '@/components/Button'
import Dropdown from '@/components/Dropdown'
import Stack from '@/components/Stack'
import TextInput from '@/components/TextInput'

// import OrderFiltersModal from './OrderFiltersModal'
// import OrderStatusFilter from './OrderStatusFilter'
// import TagDisplay from './TagDisplay'

type Props = {
  filters: CandidateFilterSetInput
  query: string
  onQueryChange: (query: string) => void
  onChangeFilter: <K extends keyof CandidateFilterSetInput>(
    key: K,
    value: NonNullable<CandidateFilterSetInput[K]>
  ) => void
  onClearFilter: (key: keyof CandidateFilterSetInput) => void
  onClearAll: () => void
}

const CandidateFilters = ({
  filters,
  query,
  onQueryChange,
  onChangeFilter,
  onClearAll,
  onClearFilter,
}: Props) => {
  // const [showFiltersModal, hideFiltersModal] = useModal(
  //   () => (
  //     <OrderFiltersModal
  //       filters={filters}
  //       hideModal={hideFiltersModal}
  //       onChangeFilter={onChangeFilter}
  //       onClearAll={onClearAll}
  //       onClearFilter={onClearFilter}
  //     />
  //   ),
  //   [filters]
  // )

  return (
    <Stack vertical gap={16}>
      <Stack gap={16}>
        <div style={{ flex: 1 }}>
          <TextInput
            placeholder="Search for candidates..."
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          />
        </div>
        {/* <ButtonGroup>
          <Dropdown
            Content={() => (
              <div style={{ padding: 16 }}>
                <OrderStatusFilter
                  filters={filters}
                  idPrefix="dropdown"
                  onChangeFilter={onChangeFilter}
                  onClearFilter={onClearFilter}
                />
              </div>
            )}
            justify="right"
            size="sm"
          >
            <Button
              hasPopover
              a11yLabel="View order status filters"
              appearance="outline"
              label="Order status"
            />
          </Dropdown>
          <Button
            a11yLabel="View all order filters"
            appearance="outline"
            label="More filters"
            onClick={showFiltersModal}
          />
        </ButtonGroup> */}
      </Stack>
      {/* <TagDisplay
        filters={filters}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={onClearFilter}
      /> */}
    </Stack>
  )
}

export default CandidateFilters
