import { useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { format } from 'date-fns'

import { CompareOperatorEnum, OrderFilterSetInput } from '@/types/graphql'

import useFilters from '@/hooks/useFilters'

import Link from '@/elements/Link'
import Table, { TableCell, TableRow } from '@/elements/Table'

import Avatar from '@/components/Avatar'
import Page from '@/components/Page'
import Card from '@/components/Card'
import LoadingState from '@/components/LoadingState'
import Paginator from '@/components/Paginator/Paginator'
import QueryEmptyState from '@/components/QueryEmptyState'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import TableHeader from '@/components/TableHeader'
import { Body, Small } from '@/components/Typography'

import OrderFilters from './OrderFilters'

import { currentAgencyVar } from '@/util/apollo/cache'

import { useListOrdersQuery } from '@/graphql'

const initialFilters: OrderFilterSetInput = {
  startAt: { op: CompareOperatorEnum.GT, value: new Date().toISOString() },
}

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<OrderFilterSetInput>(initialFilters)

  const finalFilters = useMemo<OrderFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const currentAgency = useReactiveVar(currentAgencyVar)
  const query = useListOrdersQuery({
    nextFetchPolicy: 'cache-and-network',
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    console.log(`changed to ${currentPage}`)
  }

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }

  const headerFields = ['ID', 'Customer', 'Timeframe', 'Size']

  const items =
    !query.loading && query.data ? query.data.agency.orders.items : []
  const pageInfo =
    !query.loading && query.data ? query.data.agency.orders.pageInfo : {}
  return (
    <Page
      primaryAction={{
        a11yLabel: 'Place a new order',
        label: 'Place new order',
        to: 'create',
      }}
      size="md"
      title="Orders"
    >
      <Card>
        <Card.Section>
          <OrderFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <Table>
          <TableHeader fields={headerFields} />
          <tbody>
            {items
              .map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Link to={item.id}>#{10000 + Number(item.id)}</Link>
                  </TableCell>
                  <TableCell>
                    <Stack gap={20}>
                      <div>
                        <Avatar size="sm" src={item.account.customer.logoUrl} />
                      </div>
                      <TextStack spacing="tight">
                        <Link to={`../customers/${item.account.customer.id}`}>
                          <Body color="inherit" weight="medium">
                            {item.account.customer.name}
                          </Body>
                        </Link>
                        <Link
                          appearance="subtle"
                          to={`../customers/${item.account.customer.id}/accounts/${item.account.id}`}
                        >
                          <Small>{item.account.name}</Small>
                        </Link>
                      </TextStack>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {format(new Date(item.startAt), 'PP')} &mdash;{' '}
                    {format(new Date(item.endAt), 'PP')}
                  </TableCell>
                  <TableCell>
                    {item.jobsCount} {item.jobsCount === 1 ? 'job' : 'jobs'}
                  </TableCell>
                </TableRow>
              ))
              .reverse()}
            {query.loading && (
              <TableRow>
                <TableCell colSpan={headerFields.length} align="center">
                  <LoadingState overlayColor="white" />
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
        {items.length == 0 && (
          <QueryEmptyState
            query={query}
            title="No orders"
            text="No orders were found, create one above."
          />
        )}
      </Card>
      {items.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={pageInfo.totalItems}
        />
      )}
    </Page>
  )
}

export default Orders
