import { SubmitHelpers } from '@area2k/use-form'
import { useCallback } from 'react'

import { GenderEnum, GetWorkerQuery } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import TextField from '@/form/TextField'

import { useWorkerUpdateProfileMutation } from '@/graphql'
import FormColumns from '@/form/FormColumns'
import TextSelectField from '@/form/TextSelectField'
import { handleMutationFormError } from '@/util/error'

export type Props = {
  worker: GetWorkerQuery['worker']
  hideModal: () => void
}

type FormValues = {
  firstName: Props['worker']['user']['firstName']
  lastName: Props['worker']['user']['lastName']
  gender: Props['worker']['gender']
}

const UpdateProfileModal = ({ worker, hideModal }: Props) => {
  const initialValues: FormValues = {
    firstName: worker.user.firstName, lastName: worker.user.lastName,
    gender: worker.gender
  }

  const [updateProfile, { loading: isLoading }] = useWorkerUpdateProfileMutation()

  const handleSubmit = useCallback(async (values: FormValues, { setFormError }: SubmitHelpers) => {
    try {
      await updateProfile({
        variables: { workerId: worker.id, ...values }
      })

      hideModal()
    } catch (err) {
      handleMutationFormError(err, {
        setFormError,
        // errorMap: {
        //   all: () => ({
        //     title: 'Unknown service error',
        //     message: ''
        //   })
        // }
      })
    }
  }, [])

  return (
    <Modal
      size='sm'
      title='Update profile'
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId='firstName'
              label='First name'
            />
            <TextField
              required
              fieldId='lastName'
              label='Last name'
            />
          </FormColumns>
          <TextSelectField
            required
            fieldId='gender'
            label='Gender'
            options={[{
              label: 'Male', value: GenderEnum.MALE
            }, {
              label: 'Female', value: GenderEnum.FEMALE
            }, {
              label: 'Unspecified', value: GenderEnum.UNSPECIFIED
            }]}
          />
          <Stack justify='end'>
            <Button
              a11yLabel='Submit form'
              isLoading={isLoading}
              label='Save'
              type='submit'
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UpdateProfileModal
