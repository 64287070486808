import { useCallback } from 'react'

import { GetJobQuery, JobRequestStatusEnum } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'

import { JobStatus } from './util'
import Avatar from '@/components/Avatar'
import Badge from '@/components/Badge'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

type JobRequestItem = GetJobQuery['job']['jobRequests'][0]

type Props = {
  job: GetJobQuery['job']
  jobStatus: JobStatus
}

const JobRequestStatus = ({ status }) => {
  switch (status) {
    case JobRequestStatusEnum.ACCEPTED:
      return <Badge label="Accepted" status="theme" />

    case JobRequestStatusEnum.CANCELLED:
      return <Badge label="Cancelled" status="danger" />

    case JobRequestStatusEnum.PENDING:
      return <Badge label="Pending" />

    case JobRequestStatusEnum.REJECTED:
      return <Badge label="Rejected" status="danger" />

    default:
      return <></>
  }
}

const JobRequestList = ({ job, jobStatus }: Props) => {
  const isJobActive =
    jobStatus === JobStatus.UPCOMING || jobStatus === JobStatus.IN_PROGRESS

  const renderItem = useCallback(
    (item: JobRequestItem) => (
      <ResourceList.Item
        key={item.id}
        media={<Avatar size="sm" src={item.worker.avatarUrl} />}
        to={`../../../../workers/${item.worker.id}`}
      >
        <Stack>
          <TextStack spacing="tight">
            <Body weight="medium">
              {item.worker.user.firstName} {item.worker.user.lastName}
            </Body>
            <Small>{item.worker.user.email}</Small>
          </TextStack>
          <JobRequestStatus status={item.status} />
        </Stack>
      </ResourceList.Item>
    ),
    [isJobActive]
  )

  if (!isJobActive && job.jobRequests.length === 0) {
    // NOTE: hide this card if the job is complete and there were no requests
    return null
  }

  return (
    <Card title="Job requests">
      <ResourceList
        emptyState={
          <EmptyState
            title="No job requests"
            text="If there were any, they would be here"
          />
        }
        items={job.jobRequests}
        renderItem={renderItem}
      />
    </Card>
  )
}

export default JobRequestList
