import { useParams } from 'react-router-dom'
import { useState } from 'react'

import { useGetAccountQuery } from '@/graphql'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'

import PageSkeleton from '../PageSkeleton'
import ProfileCard from './ProfileCard'
import OrdersTable from './OrdersTable'

export const enums = {
  PAGE_CHANGE: 'PAGE_CHANGE',
  NUMBER_ITEMS_CHANGE: 'NUMBER_ITEMS_CHANGE',
}

const Account = () => {
  const { accountId } = useParams()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)

  const query = useGetAccountQuery({
    variables: { accountId, page: currentPage, perPage: itemsPerPage },
  })

  const handleOrderPagination = (action: string, value: number) => {
    if (action === enums.PAGE_CHANGE) {
      setCurrentPage(value)
    } else if (action === enums.NUMBER_ITEMS_CHANGE) {
      setCurrentPage(1)
      setItemsPerPage(value)
    }
  }

  if (!query.data) {
    return <PageSkeleton />
  }

  return (
    <Page
      title={query.data.account.name}
      subtitle={query.data.account.customer.name}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical>
            <OrdersTable
              query={query && query}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              handlePagination={handleOrderPagination}
            />
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical>
            <ProfileCard defaultContact={query?.data.account.defaultContact} />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Account
