import { addDays, startOfToday } from 'date-fns'
import { Reducer, useCallback, useEffect, useReducer } from 'react'
import { Range } from 'react-date-range'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body, Heading, Subheading } from '@/components/Typography'

import BottomBar from '../BottomBar'
import ScheduleSection from './ScheduleSection'
import ScheduleSummary from './ScheduleSummary'
import SplitLayout from './SplitLayout'

import { removeAtIndex, replaceAtIndex } from '@/util/array'
import { centsToCurrency } from '@/util/number'

import {
  Address,
  Schedule,
  ScheduleType,
  Step,
  useJobDraftActions,
  useJobDraftState,
} from '../../context'
import { costOfSchedule } from '../../../context'
import { rangeToDisplayRange } from './util'

type AddScheduleAction = { type: 'add' }
type RemoveScheduleAction = { type: 'remove'; index: number }
type ReplaceScheduleAction = {
  type: 'replace'
  index: number
  schedule: Schedule
}
type ScheduleAction =
  | AddScheduleAction
  | RemoveScheduleAction
  | ReplaceScheduleAction

const createReducer = (
  rate: Address['rateQuote']
): Reducer<Schedule[], ScheduleAction> => {
  const freshSchedule = (baseSchedule?: Schedule | null): Schedule => {
    const defaultRange: Range = {
      startDate: startOfToday(),
      endDate: addDays(startOfToday(), 2),
      key: 'selection',
    }

    // TODO: rate
    const payRate = baseSchedule ? baseSchedule.payRate : rate!.pay

    return {
      dateRange: defaultRange,
      displayRanges: rangeToDisplayRange(defaultRange, ScheduleType.ALL_DAYS),
      payRate,
      payRateInput: centsToCurrency(payRate),
      quantity: baseSchedule ? baseSchedule.quantity : 2,
      startTime: baseSchedule ? baseSchedule.startTime : '12:00',
      endTime: baseSchedule ? baseSchedule.endTime : '16:00',
      type: ScheduleType.ALL_DAYS,
    }
  }

  return (state, action) => {
    switch (action.type) {
      case 'add':
        return [...state, freshSchedule(state[state.length - 1])]
      case 'remove':
        const newState = removeAtIndex(state, action.index)
        return newState.length > 0 ? newState : [freshSchedule()]
      case 'replace':
        return replaceAtIndex(state, action.index, 1, action.schedule)
      default:
        return state
    }
  }
}

type Props = {
  setStep: (step: Step) => void
}

const ScheduleStep = ({ setStep }: Props) => {
  const { address, schedules: draftSchedules, skill } = useJobDraftState()
  const { updateSchedules } = useJobDraftActions()

  const [state, dispatch] = useReducer(
    createReducer(address!.rateQuote),
    draftSchedules
  )

  const handleSubmit = useCallback(() => {
    updateSchedules({ schedules: state })
    setStep(Step.PUBLISHING)
  }, [state])

  const handleChange = useCallback((index: number, schedule: Schedule) => {
    dispatch({ type: 'replace', index, schedule })
  }, [])

  const addSchedule = useCallback(() => {
    dispatch({ type: 'add' })
  }, [])

  const removeSchedule = useCallback((index: number) => {
    dispatch({ type: 'remove', index })
  }, [])

  useEffect(() => {
    if (state.length === 0) {
      addSchedule()
    }
  }, [state.length])

  return (
    <>
      <SplitLayout>
        <SplitLayout.Left>
          <div style={{ maxWidth: 580, width: '100%' }}>
            <Stack vertical gap={24}>
              <Heading>Add a schedule</Heading>
              {state.map((schedule, index) => (
                <ScheduleSection
                  key={index}
                  index={index}
                  minPay={address!.rateQuote!.pay}
                  schedule={schedule}
                  onChange={handleChange}
                  onRemove={removeSchedule}
                />
              ))}
              <Button
                a11yLabel="Add a new schedule"
                appearance="outline"
                label="Add new shift"
                onClick={addSchedule}
              />
            </Stack>
          </div>
        </SplitLayout.Left>
        <SplitLayout.Right>
          <div style={{ maxWidth: 400, width: '100%' }}>
            <Card title={skill!.name}>
              {state.map((schedule, index) => (
                <ScheduleSummary key={index} schedule={schedule} />
              ))}
              <Card.Section>
                <Stack justify="apart">
                  <Body>Estimated total</Body>
                  <Subheading>
                    $
                    {centsToCurrency(
                      state.reduce((acc, el) => acc + costOfSchedule(el), 0)
                    )}
                  </Subheading>
                </Stack>
              </Card.Section>
            </Card>
          </div>
        </SplitLayout.Right>
      </SplitLayout>
      <BottomBar>
        <Button
          a11yLabel="Go back to previous step"
          appearance="outline"
          label="Back"
          type="button"
          onClick={() => setStep(Step.DETAILS)}
        />
        <Button
          a11yLabel="Submit form"
          label="Continue"
          onClick={handleSubmit}
        />
      </BottomBar>
    </>
  )
}

export default ScheduleStep
