import { ApolloCache, useReactiveVar } from '@apollo/client'
import { UseComboboxStateChange } from 'downshift'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { SimpleTagFragment, TaggableEnum } from '@/types/graphql'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import Autocomplete from '@/components/Autocomplete'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormElement from '@/components/FormElement'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import TagList from '@/components/TagList'

import { currentAgencyVar } from '@/util/apollo/cache'
import { removeAtIndex } from '@/util/array'

import {
  useListAgencyTagsLazyQuery,
  useAddTaggableTagMutation,
  useCreateAgencyTagMutation,
  useRemoveTaggableTagMutation,
} from '@/graphql'

const CREATE_TAG_ID = '__create'
const MIN_TAG_LENGTH = 3

type CreateItem = { id: '__create'; name: string; query: string }
type Item = SimpleTagFragment | CreateItem

type Props = {
  taggableId: string
  taggableType: TaggableEnum
  tags: SimpleTagFragment[]
  title?: string
  hideModal: () => void
  updateCache: (cache: ApolloCache<any>, tags: SimpleTagFragment[]) => void
}

const ChangeTagsModal = ({
  taggableId,
  taggableType,
  tags,
  title = 'Change tags',
  hideModal,
  updateCache,
}: Props) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)

  const currentAgency = useReactiveVar(currentAgencyVar)

  const [fetch, { data, loading }] = useListAgencyTagsLazyQuery()

  const [createTag] = useCreateAgencyTagMutation()
  const [addTag] = useAddTaggableTagMutation({
    update: (cache, { data }) => {
      if (data) {
        updateCache(cache, data.taggableAddTag.tags)
      }
    },
  })
  const [removeTag] = useRemoveTaggableTagMutation({
    update: (cache, { data }) => {
      if (data) {
        updateCache(cache, data.taggableRemoveTag.tags)
      }
    },
  })

  useEffect(() => {
    fetch({ variables: { agencyId: currentAgency!.id, query: debouncedQuery } })
  }, [debouncedQuery])

  const items: Item[] = useMemo(() => {
    if (!data) return []
    if (loading || query.length < MIN_TAG_LENGTH) return data.agency.tags.items

    return data.agency.tags.items.findIndex((tag) => tag.name === query) === -1
      ? [
          ...data.agency.tags.items,
          { id: CREATE_TAG_ID, name: `New tag "${query}"`, query },
        ]
      : data.agency.tags.items
  }, [loading, query, data])

  const handleRemove = useCallback(
    (index: number) => {
      removeTag({
        variables: { taggableId, taggableType, tagId: tags[index].id },
        optimisticResponse: {
          __typename: 'Mutation',
          taggableRemoveTag: {
            __typename: 'TaggableRemoveTagPayload',
            tags: removeAtIndex(tags, index),
          },
        },
      })
    },
    [tags]
  )

  const handleSelect = useCallback(
    async ({ selectedItem }: UseComboboxStateChange<Item>) => {
      if (!selectedItem) return

      const tagName =
        selectedItem.id === CREATE_TAG_ID
          ? (selectedItem as CreateItem).query
          : selectedItem.name

      const tagId =
        selectedItem.id === CREATE_TAG_ID
          ? (
              await createTag({
                variables: { agencyId: currentAgency!.id, name: tagName },
              })
            ).data!.tagCreate.tag.id
          : selectedItem.id

      addTag({
        variables: { taggableId, taggableType, tagId },
        optimisticResponse: {
          __typename: 'Mutation',
          taggableAddTag: {
            __typename: 'TaggableAddTagPayload',
            tags: [...tags, { __typename: 'Tag', id: tagId, name: tagName }],
          },
        },
      })
    },
    []
  )

  return (
    <Modal size="sm" title={title} onRequestClose={hideModal}>
      <Card.Section>
        <FormElement>
          <TagList tags={tags.map((tag) => tag.name)} onRemove={handleRemove} />
        </FormElement>
        <FormElement>
          <Autocomplete
            autoFocus
            id="tag"
            itemToKey={(item) => item.id}
            items={items}
            itemToString={(item) => (item ? item.name : '')}
            placeholder="Search for tags..."
            selectedItem={null}
            onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
            onSelectedItemChange={handleSelect}
          />
        </FormElement>
        <Stack justify="end">
          <Button a11yLabel="Close modal" label="Finish" onClick={hideModal} />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default ChangeTagsModal
