import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { CompareOperatorEnum, OrderFilterSetInput } from '@/types/graphql'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import { FilterProps } from '@/filters'

import Button from '@/components/Button'
import Stack from '@/components/Stack'
import TextInput from '@/components/TextInput'
import TextSelect from '@/components/TextSelect'

const NumericCompareFilter = ({
  filterKey,
  onChangeFilter,
  onClearFilter,
}: FilterProps<OrderFilterSetInput>) => {
  const [op, setOp] = useState<CompareOperatorEnum | null>(null)
  const [value, setValue] = useState<number>(0)
  const debouncedValue = useDebouncedValue(value)

  const handleOpChange = useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
    setOp(ev.currentTarget.value as CompareOperatorEnum)
  }, [])

  const handleValueChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(ev.currentTarget.value))
  }, [])

  const clearFilter = useCallback(() => {
    setOp(null)
    onClearFilter(filterKey)
  }, [filterKey])

  useEffect(() => {
    if (op) {
      onChangeFilter(filterKey, { op, value: debouncedValue })
    }
  }, [op, debouncedValue, filterKey])

  return (
    <Stack vertical gap={16}>
      <TextSelect value={op ?? ''} onChange={handleOpChange}>
        <option value="" disabled>
          Select...
        </option>
        <option value={CompareOperatorEnum.EQ}>Equal to</option>
        <option value={CompareOperatorEnum.GT}>Greater-than</option>
        <option value={CompareOperatorEnum.GTEQ}>
          Greater-than or equal to
        </option>
        <option value={CompareOperatorEnum.LT}>Less-than</option>
        <option value={CompareOperatorEnum.LTEQ}>Less-than or equal to</option>
        <option value={CompareOperatorEnum.NOT}>Not equal to</option>
      </TextSelect>
      {op && (
        <>
          <TextInput type="number" value={value} onChange={handleValueChange} />
          <Button
            a11yLabel="Clear order status filter"
            appearance="plain"
            label="Clear"
            onClick={clearFilter}
          />
        </>
      )}
    </Stack>
  )
}

export default NumericCompareFilter
