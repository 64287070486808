import { makeVar } from '@apollo/client'

import { CurrentAgencyFragment, CurrentTenantAdminFragment, CurrentTenantFragment } from '@/types/graphql'

import client from '@/util/apollo/client'

export const currentTenantVar = makeVar<CurrentTenantFragment | null>(null)
export const currentTenantAdminVar = makeVar<CurrentTenantAdminFragment | null>(null)
export const currentAgencyVar = makeVar<CurrentAgencyFragment | null>(null)

export const clearCache = async () => {
  currentTenantAdminVar(null)
  currentAgencyVar(null)

  await client.cache.reset()
}
