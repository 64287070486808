import DetailsStep from './DetailsStep'
import PublishingStep from './PublishingStep'
import ScheduleStep from './ScheduleStep'
import SkillStep from './SkillStep'

import { Step } from '../context'

type Props = {
  step: Step
  setStep: (step: Step) => void
  onClose: () => void
}

const Steps = ({ step, setStep, onClose }: Props) => {
  switch (step) {
    case Step.SKILL:
      return <SkillStep setStep={setStep} onClose={onClose} />
    case Step.DETAILS:
      return <DetailsStep setStep={setStep} />
    case Step.SCHEDULE:
      return <ScheduleStep setStep={setStep} />
    case Step.PUBLISHING:
      return <PublishingStep setStep={setStep} />
    default:
      return null
  }
}

export default Steps
