import { SubmitHelpers } from '@area2k/use-form'
import { useCallback } from 'react'

import { GetCustomerQuery } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import TextField from '@/form/TextField'

import { handleMutationFormError } from '@/util/error'

import { useRenameCustomerMutation } from '@/graphql'

export type Props = {
  customer: GetCustomerQuery['customer']
  hideModal: () => void
}

type FormValues = {
  name: string
}

const RenameCustomerModal = ({ customer, hideModal }: Props) => {
  const initialValues: FormValues = { name: customer.name }

  const [renameCustomer, { loading: isLoading }] = useRenameCustomerMutation()

  const handleSubmit = useCallback(async (values: FormValues, { setFormError }: SubmitHelpers) => {
    try {
      await renameCustomer({
        variables: { customerId: customer.id, ...values }
      })

      hideModal()
    } catch (err) {
      handleMutationFormError(err, {
        setFormError,
        // errorMap: {
        //   all: () => ({
        //     title: 'Unknown service error',
        //     message: ''
        //   })
        // }
      })
    }
  }, [customer.id])

  return (
    <Modal
      size='sm'
      title='Rename customer'
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <TextField
            autoFocus
            required
            fieldId='name'
            label='Customer name'
            placeholder='Customer name'
          />
          <Stack justify='end'>
            <Button
              a11yLabel='Submit form'
              isLoading={isLoading}
              label='Save'
              type='submit'
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default RenameCustomerModal
