import { StitchesVariants } from '@stitches/core'
import { useCallback, useState } from 'react'

import styled from '@/styles'

import Shimmer from '@/elements/Shimmer'

const Wrapper = styled(Shimmer, {
  alignItems: 'center',
  boxSize: 36,
  display: 'flex',
  justifyContent: 'center',

  overflow: 'hidden',

  variants: {
    size: {
      xs: {
        boxSize: 24,
      },
      sm: {
        boxSize: 36,
      },
      md: {
        boxSize: 48,
      },
      lg: {
        boxSize: 64,
      },
      xl: {
        boxSize: 128,
      },
    },
    rounded: {
      true: {
        borderRadius: '$round',
      },
      false: {
        borderRadius: '$lg',
      },
    },
    loaded: {
      true: {
        animation: 'none',
        backgroundColor: 'transparent',
      },
      false: {},
    },
  },

  defaultVariants: {
    rounded: false,
    size: 'md',
  },
})

const Image = styled('img', {
  height: '100%',
  width: '100%',

  variants: {
    error: {
      true: {
        display: 'none',
      },
      false: {},
    },
  },
})

export type Variants = Omit<StitchesVariants<typeof Wrapper>, 'loaded'>

export type Props = Variants & {
  a11yLabel?: string
  initials?: string
  src?: string | null
}

const Avatar = ({ a11yLabel, initials, src, ...variants }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleImgLoad = useCallback(() => setIsLoaded(true), [])
  const handleImgError = useCallback(() => setIsError(true), [])

  return (
    <Wrapper {...variants} loaded={isLoaded}>
      {src && (
        <Image
          alt="Avatar"
          aria-label={a11yLabel}
          error={isError}
          src={src}
          onError={handleImgError}
          onLoad={handleImgLoad}
        />
      )}
    </Wrapper>
  )
}

Avatar.Wrapper = Wrapper
Wrapper.displayName = 'stitches(Avatar.Wrapper)'

Avatar.Image = Image
Image.displayName = 'stitches(Avatar.Image)'

export default Avatar
