import { format, parse } from 'date-fns'

import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import { centsToCurrency, zeroPad } from '@/util/number'

import { Schedule } from '../../../context'
import { scheduleDates } from '../../../../context'

type Props = {
  schedule: Schedule
}

const ScheduleSummary = ({ schedule }: Props) => {
  return (
    <Card.Section>
      <Stack vertical gap={24}>
        <TextStack>
          <Small>When</Small>
          <Body>
            {format(schedule.dateRange.startDate!, 'eee, MMM do')}
            &nbsp;&mdash;&nbsp;
            {format(schedule.dateRange.endDate!, 'eee, MMM do')}
          </Body>
        </TextStack>
        <Stack vertical gap={12}>
          <Stack justify="apart">
            <Small>Time slot</Small>
            <Body>
              {format(parse(schedule.startTime, 'HH:mm', new Date()), 'h:mm a')}
              &nbsp;&mdash;&nbsp;
              {format(parse(schedule.endTime, 'HH:mm', new Date()), 'h:mm a')}
            </Body>
          </Stack>
          <Stack justify="apart">
            <Small>Employees</Small>
            <Body>{zeroPad(schedule.quantity, 2)}</Body>
          </Stack>
          <Stack justify="apart">
            <Small>Number of shifts</Small>
            <Body>{zeroPad(scheduleDates(schedule).length, 2)}</Body>
          </Stack>
          <Stack justify="apart">
            <Small>Pay rate</Small>
            <Body>${centsToCurrency(schedule.payRate)} / hr</Body>
          </Stack>
        </Stack>
      </Stack>
    </Card.Section>
  )
}

export default ScheduleSummary
