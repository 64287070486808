import { faClock, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { isAfter, isBefore, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'

import Alert from '@/components/Alert'
import Avatar from '@/components/Avatar'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import { Title } from '@/components/Typography'

import PageSkeleton from '../Customer/PageSkeleton'
import DetailsCard from './DetailsCard'
import JobBadge from './JobBadge'
import JobOfferList from './JobOfferList'
import JobRequestList from './JobRequests'
import PaymentCard from './PaymentCard'
import ShiftsCard from './ShiftsCard'
import TimesheetList from './TimesheetList'
import WorkerList from './WorkerList'

import { JobStatus } from './util'
import { formatISO } from '@/util/date'

import { useGetJobQuery } from '@/graphql'

const Job = () => {
  const { jobId } = useParams()

  const query = useGetJobQuery({
    variables: { jobId },
  })

  if (!query.data) {
    return <PageSkeleton />
  }

  const job = query.data.job
  const startAt = parseISO(job.firstShiftStartAt)
  const endAt = parseISO(job.lastShiftEndAt)
  const postedAt = job.postedAt ? parseISO(job.postedAt) : null

  const jobStatus = job.cancelledAt
    ? JobStatus.CANCELLED
    : isBefore(new Date(), startAt)
    ? JobStatus.UPCOMING
    : isAfter(new Date(), endAt)
    ? JobStatus.COMPLETED
    : JobStatus.IN_PROGRESS

  return (
    <Page
      media={<Avatar src={job.skill.imageUrl} />}
      primaryAction={
        jobStatus === JobStatus.CANCELLED || jobStatus === JobStatus.COMPLETED
          ? undefined
          : {
              a11yLabel: 'Cancel this job',
              label: 'Cancel job',
              dangerous: true,
              onAction: () => undefined,
            }
      }
      secondaryActions={[
        {
          a11yLabel: 'List actions for this job',
          label: 'Actions',
          actions: [
            jobStatus === JobStatus.CANCELLED ||
            jobStatus === JobStatus.COMPLETED
              ? []
              : [
                  postedAt
                    ? {
                        a11yLabel: 'Remove this job from the board',
                        label: 'Remove from board',
                        icon: faEyeSlash,
                        onAction: () => undefined,
                      }
                    : {
                        a11yLabel: 'Add this job to the board',
                        label: 'Post to board',
                        icon: faEye,
                        onAction: () => undefined,
                      },
                ],
          ],
        },
      ]}
      subtitle={`${formatISO(job.firstShiftStartAt, 'PP')} — ${formatISO(
        job.lastShiftEndAt,
        'PP'
      )}`}
      title={
        <Stack gap={16}>
          <Title>{job.skill.name}</Title>
          <JobBadge jobStatus={jobStatus} />
        </Stack>
      }
    >
      <Stack vertical gap={24}>
        {job.postedAt && !job.published && (
          <div style={{ width: '100% ' }}>
            <Alert
              icon={faClock}
              status="neutral"
              title="Job scheduled to be posted"
              description={`This job will be published to workers on ${formatISO(
                job.postedAt,
                "EEE, MMM do 'at' h:mmbbb"
              )}`}
            />
          </div>
        )}
        <div style={{ width: '100%' }}>
          <PrimarySecondaryLayout>
            <PrimarySecondaryLayout.Primary>
              <Stack vertical gap={24}>
                <WorkerList job={job} jobStatus={jobStatus} />
                <TimesheetList job={job} jobStatus={jobStatus} />
                <JobOfferList job={job} jobStatus={jobStatus} />
                <JobRequestList job={job} jobStatus={jobStatus} />
              </Stack>
            </PrimarySecondaryLayout.Primary>
            <PrimarySecondaryLayout.Secondary>
              <Stack vertical gap={24}>
                <DetailsCard job={job} />
                <ShiftsCard job={job} />
                <PaymentCard job={job} />
              </Stack>
            </PrimarySecondaryLayout.Secondary>
          </PrimarySecondaryLayout>
        </div>
      </Stack>
    </Page>
  )
}

export default Job
