import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { useMemo, useState } from 'react'

import Button from '@/components/Button'
import FormElement from '@/components/FormElement'
import Stack from '@/components/Stack'

import ItemSelectField from '@/form/ItemSelectField'
import TextAreaField from '@/form/TextAreaField'

import { Address, Skill } from '../../../context'
import { useOrderState } from '../../../../context'

import { useListAddressesQuery } from '@/graphql'
import Alert from '@/components/Alert'

type Props = {
  hasInstructions?: boolean
  skill: Skill
}

const AddressSection = ({ hasInstructions = false, skill }: Props) => {
  const { billing } = useOrderState()

  const [isOpen, setIsOpen] = useState(hasInstructions)

  const { data, loading } = useListAddressesQuery({
    variables: { customerId: billing!.customer.id, skillId: skill.id }
  })

  const addressesWithRates = useMemo(() => (
    data
      ? data.customer.addresses.filter((address) => !!address.rateQuote)
      : []
  ), [data])

  return (
    <Stack vertical gap={0}>
      {data && addressesWithRates.length === 0 &&
        <div style={{ width: '100% '}}>
          <FormElement>
            <Alert
              description={`There are no addresses available that can provide ${skill.name} services.`}
              icon={faMapMarkerAlt}
              title='No servicable addresses'
              status='danger'
            />
          </FormElement>
        </div>
      }
      <div style={{ width: '100%' }}>
        <ItemSelectField<Address>
          required
          disabled={loading}
          fieldId='address'
          items={addressesWithRates}
          itemToKey={(item) => item.id}
          itemToString={(item) => item ? `${item.name}, ${item.addressLine1}` : ''}
          label='Where is the job?'
          placeholder='Select an address...'
        />
      </div>
      {isOpen
        ? <div style={{ width: '100%' }}>
            <TextAreaField
              fieldId='addressInstructions'
              label='Address instructions'
              placeholder='Provide instructions and details to the workers...'
            />
          </div>
        : <Button
            a11yLabel='Click to add optional address instructions'
            appearance='plain'
            label='Add address instructions (optional)'
            type='button'
            onClick={() => setIsOpen(true)}
          />
      }
    </Stack>
  )
}

export default AddressSection
