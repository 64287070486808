import { useReactiveVar } from '@apollo/client'
import { SubmitHelpers } from '@area2k/use-form'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import TextField from '@/form/TextField'

import { currentAgencyVar } from '@/util/apollo/cache'
import { handleMutationFormError } from '@/util/error'

import { useCreateCustomerMutation } from '@/graphql'

export type Props = {
  hideModal: () => void
}

type FormValues = {
  name: string
}

const CreateCustomerModal = ({ hideModal }: Props) => {
  const initialValues: FormValues = { name: '' }

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const currentAgency = useReactiveVar(currentAgencyVar)
  const [createCustomer, { loading: isLoading }] = useCreateCustomerMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const { data } = await createCustomer({
          variables: { agencyId: currentAgency!.id, ...values },
        })

        const {
          customerCreate: { customer },
        } = data!

        hideModal()
        navigate(`${pathname}/${customer.id}`)
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          // errorMap: {
          //   all: () => ({
          //     title: 'Unknown service error',
          //     message: ''
          //   })
          // }
        })
      }
    },
    []
  )

  return (
    <Modal size="sm" title="New customer" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            fieldId="name"
            label="Customer name"
            placeholder="Customer name"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Create"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default CreateCustomerModal
